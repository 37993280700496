import React, { Component } from 'react';
import { BrowserRouter as Link} from "react-router-dom";
import {Auth} from '../components/Auth';
import Service from '../components/Service';


const API = process.env.REACT_APP_API;

// function images(image){
//   return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

const Info = props => (
  
     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
  
);

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            totalProducts: 0,
            perPage: 50,
            search: '',
            page: 1,
            previousPage: 0,
            nextPage: 0,
            selectedSupplier: '',
            suppliers: [],
            updateProductId: null
        }

    }



  updateProducts(){
      Service.get('/products/get',{
            page: this.props.match.params.page,
          perPage: this.state.perPage,
          filter: this.state.search,
          supplier: this.state.selectedSupplier
            },(res)=>{
                this.setState({
                products: res.data.products,
                totalProducts: res.data.total_products
                },()=>{
                    this.handlePages();
                });
        });
    // fetch(`${API}/products/get`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       page: this.props.match.params.page,
    //       perPage: this.state.perPage,
    //       filter: this.state.search,
    //       supplier: this.state.selectedSupplier
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //       this.setState({
    //         products: result.data.products,
    //         totalProducts: result.data.total_products
    //       },()=>{
    //           this.handlePages();
    //       });
          
    //       console.log(this.state);
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  changeSearch = (event) => {

      this.setState({search: event.target.value});
      this.updateProducts();
  }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.setState({search: event.target.value});
            this.updateProducts();
        }
    }

  changeProductStatus = (event) => {
      
      this.setProductStatus(event.target.id, event.target.value);
 
  }

  deleteProduct = (productId) => {
      if (window.confirm("Izbrisi proizvod?")) { 
            this.setProductStatus(productId, 'delete');
        }
  }

  setProductStatus = (productId, action) => {
      Service.get(`/products/${action}`,{
            productId: productId
            },()=>{
                this.updateProducts();
        });
    //   fetch(`${API}/products/${action}`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       productId: productId
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       this.updateProducts();
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  handlePages=()=>{
      this.previousPage = parseInt(this.state.page) - 1;
      this.nextPage = parseInt(this.state.page) + 1;

      let totalPages = Math.ceil(this.state.totalProducts / this.state.perPage);

      if(this.nextPage >= totalPages){
          this.nextPage = 1;
      }
      if(this.previousPage <= 0){
          this.previousPage = totalPages;
      }

      this.setState({
          previousPage: this.previousPage,
          nextPage: this.nextPage
      })

      console.log('handlePages', this.previousPage);
  }

  componentDidMount() {
    this.updateProducts();
    this.getSuppliers();
    if(this.props.match.params.page){
        this.setState({page: this.props.match.params.page});
        
    }else{

    }
    console.log('page',this.props.match.params.page);
    console.log('filter',this.props.match.params.filter);
  }

    getSuppliers=()=>{
        Service.get('/products/getSuppliers',{},(res)=>{
            this.setState({suppliers: res.data.suppliers});
        })
    }

    selectSupplier=(event)=>{
        console.log(event.target.id);
        this.setState({selectedSupplier: event.target.id},()=>{
            this.updateProducts();
        });
    }

    setProductDiscount=(event)=>{
        // console.log(event.target.id, event.target.value);
        let storeId = event.target.getAttribute('data-store-id');
        Service.get('/products/setDiscount',{productId: event.target.id, discount: event.target.value, storeId},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
    }

    toggleProductStock=(event)=>{
        this.setState({updateProductId: event.target.getAttribute('data-product-id')});
    }

    setProductStock=(event)=>{
        if (event.key === 'Enter') {
            //console.log(event.target.getAttribute('data-product-id'), event.target.value);
            let val = event.target.value;
            Service.get('/products/setStock',{productId: event.target.getAttribute('data-product-id'), stock: val},(res)=>{
                if(res.success){

                    this.updateProducts();
                    this.getSuppliers();
                }else{
                    alert('greska! '+res.info);
                }
            })
            this.setState({updateProductId: null});
        }
        
    }

    resetProductStockInput=(event)=>{
        this.setState({updateProductId: null});
        event.target.value = event.target.getAttribute('data-current-value');
        
    }

    setSticker=(event)=>{
        // console.log(event.target.name, event.target.checked);
        let storeId = event.target.getAttribute('data-store-id');

        Service.get('/products/setSticker',{productId: event.target.name, sticker: event.target.checked, storeId},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
    }

    setFreeShipping=(event)=>{
        console.log(event.target.name);
        let storeId = event.target.getAttribute('data-store-id');

        Service.get('/products/setfreeshipping',{productId: event.target.name, storeId},(res)=>{
            if(res.success){
                this.updateProducts();
            }else{
                alert('greska! '+res.info);
            }
        })
    }

    setNoDiscount=(event)=>{
        console.log(event.target.name, event.target.value);
        Service.get('/products/setNoDiscount',{productId: event.target.name},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
        
    }

    togglePreorder = (event) => {
        console.log(event.target.name, event.target.value);
        Service.get('/products/togglePreorder',{productId: event.target.name},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
    }



  render() {
    return (
      <div>
            <h1>Pregled Proizvoda</h1>
            <div className="products-navigation">
                <a href={`/products/${this.state.previousPage}`} ><button>-</button></a>
                strana {this.state.page} / {Math.ceil(this.state.totalProducts / this.state.perPage)}, Ukupan broj proizvoda: {this.state.totalProducts}
                <a href={`/products/${this.state.nextPage}`} ><button>+</button></a>
                <br />
            <input type='text' value={this.state.search} onKeyPress={this.handleKeyPress} onChange={this.changeSearch} placeholder="Pretrazi..."/> 🔎
            <div className="suppliers">
                {this.state.suppliers.map(function(item, index){
                    return(

                        <button onClick={this.selectSupplier} id={item.name}>{item.name}</button>

                )},this)}
                <button onClick={this.selectSupplier} id={0}>Bez Dobavljaca</button>
                <br />
                <span>Izabrani dobavljac: {this.state.selectedSupplier}</span>

            </div>
            </div>
            {this.state.products.map(function(item, index){

              let statuses = {0: 'deactivate', 1: 'activate', 2: 'pending', 3: 'soldOut', 4: 'onlyWarehouse'}

                return(
                    <div className="products-product-container" key={index} >
                        <div className="quarter" style={{width: '10%'}}>
                            <a href={'/product/' + item.id + '/' + item.slug}><img src={Service.images(item.slug.toLowerCase()) + '.jpg'} /></a>
                        </div>
                        <div className="third">
                            {/* <div className="product-container-info"> */}

                                <div className="half">
                                    <a href={'/product/' + item.id + '/' + item.slug}><span style={{fontSize: '1.2em', fontWeight: 'bold'}}>{item.title}</span></a>
                                    <Info label="id" value={item.id} />
                                    <Info label="cena" value={item.full_price} />
                                    {/* <Info label="bez poreza" value={item.priceNoTax} /> */}
                                    {/* <Info label="autor" value={item.author} /> */}
                                    <Info label="dobavljac" value={item.supplier} />
                                    <Info label="slug" value={item.slug} />
                                    <Info label="SKU" value={item.sku} />
                                    {/* <Info label="dobavljac" value={item.supplier} /> */}
                                    {/* <Info label="ISBN" value={item.isbn} /> */}
                                    {/* <Info label="datum izdanja" value={item.published_at} /> */}
                                    {/* <Info label="bez popusta" value={item.no_discount} /> */}
                                    </div>
                                    <div className="half">
                                    BEZ POPUSTA: <input type="checkbox" name={item.id} value={parseInt(item.no_discount)} onClick={this.setNoDiscount} checked={parseInt(item.no_discount)}></input><br/>
                                    Pretprodaja: <input type="checkbox" name={item.id} value={parseInt(item.preorder)} onClick={this.togglePreorder} checked={parseInt(item.preorder)}></input><br/>
                                    
                                    Stanje: 
                                    
                                    {this.state.updateProductId == item.id ? (
                                            <input autoFocus type="number" className="stock-input products-current-stock" data-current-value={item.stock} data-product-id={item.id} onKeyDown={this.setProductStock} onBlur={this.resetProductStockInput} placeholder={item.stock} />
                                        ) : (
                                            <div data-product-id={item.id} onClick={this.toggleProductStock} className="products-current-stock">{item.stock}</div>
                                        )
                                    } kom. <br/> 
                                    Proizvodnja: <b>{item.production_status}</b> <br/>
                                    <div> 
                                    Naručeno: {item.ordered && (parseInt(item.stock) - parseInt(item.ordered)) < 10 ? <span style={{color: 'red'}}>{item.ordered}</span> : item.ordered ? <span>{item.ordered}</span> : <span>0</span>}<br/>
                                    </div>
                                    <button onClick={()=>{this.deleteProduct(item.id)}}>🗑</button>

                                    <select id={item.id} value={statuses[item.status]} onChange={this.changeProductStatus}>
                                        <option value='activate' name={item.id} >aktivan</option>
                                        <option value='deactivate' name={item.id}>neaktivan</option>
                                        <option value='pending' name={item.id}>u pripremi</option>
                                        <option value='soldOut' name={item.id}>rasprodato</option>
                                        <option value='onlyWarehouse' name={item.id}>samo magacin</option>
                                    </select>

                            </div>
                        </div>
                        {/* <br /> */}
                        <div className="half">
                            {item.stores.map(function(store, index){
                                return(
                                    <div className="product-container-info product-store">
                                        <h3>{store.storeId === 1 ? 'Harmonija knjige' : store.storeId === 2 ? 'Stela' : store.storeId === 3 ? 'Publik praktikum' : null}</h3>
                                        Popust: <input type="number" id={item.id} data-store-id={store.storeId} onBlur={this.setProductDiscount} placeholder={store.discount} />%
                                        <br />
                                        Sticker: <input type="checkbox" name={item.id} value={parseInt(store.sticker)} data-store-id={store.storeId} onClick={this.setSticker} checked={parseInt(store.sticker)}></input>
                                        <br />
                                        Besplatna isporuka: <input type="checkbox" name={item.id} value={parseInt(store.freeShipping)} data-store-id={store.storeId} onClick={this.setFreeShipping} checked={parseInt(store.freeShipping)}></input><br/>
                                    </div>
                            )},this)}
                         </div>
                    </div>
                );
                },this)
            }
      </div>
    );
  }

}

export default Products;