import React, { useState, useEffect } from 'react';


function InfoBox(props) {

    const [color, changeColor] = useState(props.color);

    useEffect(() => {
        console.log('props', props);
        changeColor(props.color);
      }, [props.color]);

    return (
        <div className="info-box" style={{color: color, borderColor: color, background: color + '20'}}>
            {props.message}
        </div>
    );
}

export default InfoBox;