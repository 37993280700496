import React, { Fragment, useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";

import Header from './components/Header';
import UserMenu from './components/UserMenu';
import ProductsImport from './components/ProductsImport';

import Login from './views/Login';
import Forbidden from './views/Forbidden';
import Home from './views/Home';
import Categories from './views/Categories';
import Chat from './views/Chat'
import Discounts from './views/Discounts';
import Discount from './views/Discount';
import Actions from './views/Actions';
import Action from './views/Action';
import Orders from './views/Orders';
import Order from './views/Order'; // OrderDev for development
import Customers from './views/Customers';
import Krcko from './views/Krcko';
import Products from './views/Products';
import Product from './views/Product';
import ProductAdd from './views/ProductAdd';
import Images from './views/Images';
import News from './views/News';
import NewsEdit from './views/NewsEdit';
import ProductLists from './views/ProductLists';
import Comments from './views/Comments';
import Suppliers from './views/Suppliers';
import PrintPreview from './views/PrintPreview';
import Banners from './views/Banners';
import Export from './views/Export';
import Statistics from './views/Statistics';
import TicketsStatistics from './views/TicketsStatistics';
import ProductsCategories from './views/ProductsCategories';
import Barcode from './views/Barcode'
import Warehouse from './views/Warehouse';
import Document from './views/Document';
import Documents from './views/Documents';
import AdminUsers from './views/AdminUsers';
import AdminGroups from './views/AdminGroups';
import AdminUser from './views/AdminUser';
import Fiscalisation from './views/Fiscalisation';
import Book from './views/Book';

import {Auth} from './components/Auth';
import { AuthContext } from './context/AuthContext';

{Auth.checkLoginStatus()}

const PrivateRoute = ({ component: Component, ...rest }) => (
  
  <Route {...rest} render={(props) => (
    console.log(props.history.location.pathname),
    Auth.isAuthenticated === true
      ? Auth.checkPermission(props.history.location.pathname) ? <Component {...props} /> : <Redirect to={{
        pathname: '/forbidden',
        state: { from: props.location }
      }} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)


function App(props) {

  //   constructor(props) {
  //   super(props);
        
  // }

  const {loggedIn, setLoggedIn} = useContext(AuthContext);


    return (

      <Router>
        <div>
        {Auth.isAuthenticated == false ? (
            <Redirect to="/login" />
          ) : (setLoggedIn(true))
          }
          {loggedIn &&
              <Fragment>
              <Header />
              <UserMenu />
              </Fragment>
            }
          {/* <Header />
          <UserMenu /> */}
          <div className="content">
            <Route exact path="/login" component={Login} />
            <Route exact path="/forbidden" component={Forbidden} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/tickets/:id?" component={Home} />
          <PrivateRoute exact path="/csv" component={ProductsImport} />
          <PrivateRoute path="/categories" component={Categories} />
          <PrivateRoute path="/chat/:page?" component={Chat} />
          <PrivateRoute path="/discounts" component={Discounts} />
          <PrivateRoute path="/discount/:id" component={Discount} />
          <PrivateRoute path="/actions" component={Actions} />
          <PrivateRoute path="/action/:id" component={Action} />
          <PrivateRoute path="/products/:page?/:filter?" component={Products} />
          <PrivateRoute path="/product/:id?/:slug?" component={Product} />
          <PrivateRoute path="/productAdd" component={ProductAdd} />
          <PrivateRoute path="/media/:status?" component={Images} />
          <PrivateRoute path="/news" component={News} />
          <PrivateRoute path="/news-edit/:id" component={NewsEdit} />
          <PrivateRoute path="/top-liste" component={ProductLists} />
          <PrivateRoute path="/komentari" component={Comments} />
          <PrivateRoute path="/dobavljaci" component={Suppliers} />
          <PrivateRoute path="/orders/:page?" component={Orders} />
          <PrivateRoute path="/order/:id" component={Order} />
          <PrivateRoute path="/customers/:page?" component={Customers} />
          <PrivateRoute path="/krcko" component={Krcko} />
          <PrivateRoute path="/print-preview" component={PrintPreview} />
          <PrivateRoute path="/banners" component={Banners} />
          <PrivateRoute path="/export" component={Export} />
          <PrivateRoute path="/statistics" component={Statistics} />
          <PrivateRoute path="/tickets-statistics" component={TicketsStatistics} />
          <PrivateRoute path="/products-categories" component={ProductsCategories} />
          <PrivateRoute path="/barcode" component={Barcode} />
          <PrivateRoute path="/warehouse" component={Warehouse} />
          <PrivateRoute path="/document/:id?" component={Document} />
          <PrivateRoute path="/documents/" component={Documents} />
          <PrivateRoute exact path="/admins" component={AdminUsers} />
          <PrivateRoute exact path="/admins/groups" component={AdminGroups} />
          <PrivateRoute exact path="/admins/user/:id" component={AdminUser} />
          <PrivateRoute exact path="/fiscalisation" component={Fiscalisation} />
          <PrivateRoute exact path="/book" component={Book} />
          </div>
        </div>
      </Router>
    );
}

export default App;
