import React, { Component } from 'react';
import Service from '../components/Service';



class Banners extends Component {
    constructor(){
        super();
        this.state={
            banners: [],
            storeId:'3',
            bannerLink:'',
            bannerTitle:'',
            uploadPercent: 0,
            uploaded: 0
        }

    }

    store = {
        1: "Harmonija",
        3: "Publikpraktikum",
        2: "Stela"
    }

componentDidMount() {
    this.updateBanners();
}

changeStore = (event) => {
    this.setState({ storeId: event.target.getAttribute('data-store-id') },()=> {
        this.updateBanners();
    })
}

updateBanners = () => {
    Service.get('/media/getbanners',{
            storeId: this.state.storeId
    },(res)=>{
            this.setState({banners: res.data.banners},()=>{ 
        });
    })
}

deleteBanner=(event)=>{
    Service.get('/media/deletebanner',{bannerId: event.target.id},(res)=>{
        this.updateBanners();
    });
}

statusBanner=(event)=>{
    Service.get('/media/showbanner',{bannerId: event.target.dataset.id, show: event.target.dataset.show},(res)=>{
        this.updateBanners();
    });
}

moveBanner=(event)=>{
    Service.get('/media/bannerPosition',{bannerId: event.target.dataset.id, position: event.target.dataset.move},(res)=>{
        this.updateBanners();
    });

    console.log(event.target.dataset.id, event.target.dataset.move);
}

handleInput = (event) => {
    this.setState({ [event.target.id]: event.target.value });
}

uploadBanner = (event) => {
    if(!this.state.bannerLink){
        alert("Morate da upisete link");
        event.target.value = '';
        return;
    }
    const files = Array.from(event.target.files)
    this.setState({ uploading: true })

    const formData = new FormData()

    files.forEach((file, i) => {
        formData.append(i, file)
    })

    const updateProgress = (l, t) => {
        this.setState({uploaded: l, max: t, uploadPercent: l/t})
        if(l >= t){
            request.upload.removeEventListener("progress", _listener);
            setTimeout(()=>{
                this.updateBanners();
            }, 500);            
        }
    }

    var _listener;

    formData.append('storeId', this.state.storeId);
    formData.append('link', this.state.bannerLink);

    console.log(formData);

    var request = new XMLHttpRequest();
    request.addEventListener("load", ()=>{
        console.log("upload done");
        this.setState({uploaded: 0, bannerLink:'', bannerTitle:''});
    });

    request.open("POST", Service.api+'/media/uploadbanner');

    request.upload.addEventListener("progress", function _listener(evt){
        if (evt.lengthComputable) {
            updateProgress(evt.loaded, evt.total);
            // if(evt.loaded >= evt.total){
            //     // this.updateProducts();
            // }
            // console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
        }
    }, false);

    request.send(formData);
}

setCentralBanner = (event) => {
    Service.get('/media/centralBanner',{bannerId: event.target.dataset.id, storeId: this.state.storeId},(res)=>{
        if(res.success){
            this.updateBanners();
        }
    });
}

    render(){
        let bannersLenght = this.state.banners.length;
        return (
            <div className="banner-container">
                <h2>{this.store[this.state.storeId]}</h2>
                <button id="3" onClick={this.changeStore} data-store-id="3" className="publikpraktikum-button">Publikpraktikum</button>
                <button id="1" onClick={this.changeStore} data-store-id="1" className="harmonija-knjige-button">Harmonija knjige</button>
                <button id="2" onClick={this.changeStore} data-store-id="2" className="stela-button">Stela</button>

                {this.state.storeId == 3 &&
                    <h1>Publik Praktikum</h1>
                }
                {this.state.storeId == 1 &&
                    <h1>Harmonija knjige</h1>
                }
                {this.state.storeId == 2 &&
                    <h1>Stela</h1>
                }
                {this.state.banners.map(function (item, index) {
                    return(
                        <div className="banner-container">
                            {item.visible &&
                                <div className='banner-image' key={index}>
                                    <img src={Service.images("banners/"+item.banner_name)}/>
                                    <p>{item.banner_name}</p>
                                </div> 
                            }
                            {!item.visible &&
                                <div className='banner-image banner-image-hide' key={index}>
                                    <img src={Service.images("banners/"+item.banner_name)}/>
                                    <p>{item.banner_name}</p>
                                </div> 
                            }
                            <div className="banner-actions">
                                {/* First banner */}
                                {(index === 0 && bannersLenght !== index + 1) &&
                                    <div>
                                        <button onClick={this.moveBanner} data-id={item.id} data-move="-1">Move down</button>
                                        {/* Show/hide */}
                                        {(item.visible === 0) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="1" >Show</button>
                                        }
                                        {(item.visible === 1) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="0" >Hide</button>
                                        }
                                        
                                        <button onClick={this.deleteBanner} id={item.id}>Delete</button>
                                    </div>
                                }

                                {/* Middle banners */}
                                {(index !== 0 && bannersLenght !== index + 1) &&
                                    <div>
                                        <button onClick={this.moveBanner} data-id={item.id} data-move="1">Move up</button>
                                        <button onClick={this.moveBanner} data-id={item.id} data-move="-1">Move down</button>
                                        {(item.visible === 0) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="1" >Show</button>
                                        }
                                        {(item.visible === 1) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="0" >Hide</button>
                                        }
                                        <button onClick={this.deleteBanner} id={item.id}>Delete</button>
                                    </div>
                                }

                                {/* Last banner */}
                                {(index !== 0 && bannersLenght === index + 1) &&
                                    <div>
                                        <button onClick={this.moveBanner} data-id={item.id} data-move="1">Move up</button>
                                        {(item.visible === 0) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="1" >Show</button>
                                        }
                                        {(item.visible === 1) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="0" >Hide</button>
                                        }
                                        <button onClick={this.deleteBanner} id={item.id}>Delete</button>
                                    </div>
                                }

                                {/* Only one banner */}
                                {(index === 0 && bannersLenght === index + 1) &&
                                    <div>
                                         {(item.visible === 0) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="1" >Show</button>
                                        }
                                        {(item.visible === 1) &&
                                            <button onClick={this.statusBanner} data-id={item.id} data-show="0" >Hide</button>
                                        }
                                        <button onClick={this.deleteBanner} id={item.id}>Delete</button>
                                    </div>
                                }
                                
                                <input type="checkbox" data-id={item.id} onChange={this.setCentralBanner} checked={item.central == '1'}/>Centralni baner<br />
                            </div>
                        </div>
                    );                     
                },this)}


                <label for="title">Title</label>
                    <input type="text" value={this.state.bannerTitle} id='bannerTitle' onChange={this.handleInput} />
                    <br />
                <label for="bannerLink">Link</label>
                    <input type="text" value={this.state.bannerLink} id='bannerLink' onChange={this.handleInput} />
                    <br />
                <button onClick={()=>{this.uploadInput.click()}} >
                    <span>Dodaj<br/>baner<br/></span>
                    {this.state.uploaded > 0 &&
                        <progress  value={this.state.uploaded} max={this.state.max}></progress> 
                    }
                </button>
                <input ref={(ref) => this.uploadInput = ref} style={{display: 'none'}} type='file' id='multi' name="banerImage[]" onChange={this.uploadBanner} multiple data-buttontext={"Upload File"} />

            </div>
        );
    }

}

export default Banners;