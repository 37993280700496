//import React, { Component } from 'react';
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import Service from '../components/Service';
import Ticket from '../components/Ticket';
import TicketCreate from '../components/TicketCreate';
import { Auth } from '../components/Auth';
import Select from '../components/Select';

const queryString = require('query-string');

export default function Tickets(props){

  const {ticketsNotifications, setTicketsNotifications} = useContext(AuthContext);

  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState();
  const [create, setCreate] = useState();

  const [users, setUsers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [types, setTypes] = useState([]);

  const [priorityFilter, setPriorityFilter] = useState();
  const [userFilter, setUserFilter] = useState();
  const [typeFilter, setTypeFilter] = useState();
  const [titleFilter, setTitleFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();

  const [createType, setCreateType] = useState();
  const [typeName, setTypeName] = useState('');

  useEffect(()=>{
    getTickets();
    console.log('params', window.location.search);
    
  }, []);

  const handleTitleFilter=(event)=>{
    console.log(event)
    if(event.key == 'Enter'){
      getTickets();
    }
  }

  useEffect(()=>{
    let getParams = queryString.parse(window.location.search);
          // if(getParams.tid){
          //   console.log(getParams.tid);
          //   tickets.map(item => {
          //     if(item.id == getParams.tid){
          //       setTicket(item);
          //     }
          //   })
          // }
  }, [tickets]);

  useEffect(()=>{
    getTickets();
  }, [priorityFilter, userFilter, typeFilter, statusFilter])

  const getTickets=(deleted = false)=>{

    if(deleted){
      setTicket(null);
    }
    Service.get('/tickets/get', {priorityFilter, userFilter, typeFilter, titleFilter, statusFilter}, (res)=>{
      setTickets(res.tickets);
      setTicketsNotifications(res.notifications);
    });

    Service.get('/tickets/getOptions', {}, (res)=>{
      setUsers(res.users);
      setPriorities(res.priorities);
      setTypes(res.types)
    });

  }

  const setUrlParams=(ticketId)=>{
    //window.history.push('/?tid='+ticketId);
    window.history.pushState('', 'Ticket', '/?tid='+ticketId);
  }

  const addType=()=>{
    Service.get('/tickets/addType', {typeName}, (res)=>{
      if(res.success){
        alert('Uspesno ste napravili novi tip problema');
        getTickets();
        setTypeName('');
        setCreateType(false);
      }
    })
  }



    return (
      <div className="tickets">
          <h1>Tiketi</h1>
          <button onClick={()=>{setCreate(true)}} >Novi tiket</button>
          <button onClick={()=>{setCreateType(true)}} >Novi tip problema</button>
          {/* {create ? <button onClick={()=>{setCreate(false)}} >Odustani</button> :
           
                 }
          {createType ? <button onClick={()=>{setCreateType(false)}} >Odustani</button> :
           <button onClick={()=>{setCreateType(true)}} >Novi tip problema</button>
                 }
          */}
          {create &&
            <section>
              <TicketCreate onCreate={()=>{getTickets(); setCreate(false)}}/>
              <button onClick={()=>{setCreate(false)}} >Odustani</button>
            </section> 
          }
          {createType &&
            <section>
              <input placeholder="tip problema..." value={typeName} onChange={(event)=>setTypeName(event.target.value)} />
              <button onClick={addType} >Snimi</button>
              <button onClick={()=>{setCreateType(false)}} >Odustani</button>
            </section> 
          }
          <div> 
          <section>
            <div className="tickets-list-filters">
              <div className="half">
                <div className="six">
                  <input placeholder="filter po imenu..." onMouseDown={()=>setTitleFilter('')} onKeyDown={handleTitleFilter} value={titleFilter} onChange={(event)=>setTitleFilter(event.target.value)}/>
                </div>
                <div className="third padding-left">
                  <Select placeholder="status..." id='id' value='name' options={[{id: null, name: 'svi'}, {id: 1, name: 'neuradjeni'}, {id: 2, name: 'uradjeni'}]} selected={statusFilter} onSelect={(res)=>setStatusFilter(res)}/>
                </div>
              </div>
                <div className="half" >
                  <div className="third padding-horizontal">
                    <Select placeholder="tip..." id='id' value='name' options={[{id: null, name: 'svi'}, ...types]} selected={typeFilter} onSelect={(res)=>setTypeFilter(res)}/>
                  </div>
                  <div className="third padding-right">
                  <Select placeholder="prioritet..." id='id' value='name'  options={[{id: null, name: 'svi'}, ...priorities]} selected={priorityFilter} onSelect={(res)=>setPriorityFilter(res)} />
                    
                  </div>
                  <div className="third ">
                  <Select placeholder="admin..." id='id' value='name' options={[{id: null, name: 'svi'}, ...users]} selected={userFilter} onSelect={(res)=>setUserFilter(res)}/>
                  </div>
                </div>
              </div>
            </section>
            <div className="tickets-list">
              <section className="white">
              
              {/* <table className="cms-table"> */}
                {/* <tbody> */}
                <ul>
                {tickets &&
                  tickets.map((item, index) => {
                    return(
                    <li key={`tickets-${index}`} className={`ticket-list-item ${(ticket && item.id == ticket.id) ? 'selected' : null}`} onClick={()=>{setTicket(item.id); setUrlParams(item.id)}} >
                        <div className="half" >
                            <span>{item.title} <span className="tickets-status">{item.status_name}</span> 
                              {item.responsible_name &&
                              <div> 
                                 <span className="tickets-responsible">odgovoran: {item.responsible_name}</span>
                              </div>
                              }
                            </span>
                        </div>
                        <div className="half" >
                          <div className="third padding-horizontal tickets-list-type-name">
                          {item.type_name}
                            
                          </div>
                          <div className="third padding-right">
                            {item.priority &&
                              <span className={item.priority ? `tickets-list-item-priority tickets-priority-${item.priority}` : null}>{item.priority_name}</span>
                            }
                          </div>
                          <div className="third">
                           {item.assigned_id &&
                              <span className={item.assigned_id == Auth.id ? "tickets-list-item-user assigned" : "tickets-list-item-user"}>{item.assigned_name}</span>
                            }
                          </div>
                        </div>
                    </li>
                  )})
                }
                </ul>
                {/* </tbody> */}
              {/* </table> */}
              </section>
            </div>
            <div className="tickets-ticket">
              {ticket ?
                <Ticket ticket={ticket} onUpdate={getTickets}/> : 
                <p className="align-center" style={{color: 'gray', paddingTop: '5em'}}>Nije odabran ni jedan tiket</p>
                //<p>ticket {ticket}</p>
              }
            </div>
          </div>
      </div>
    );

}
