
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    position: 'relative',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  section2: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    flexGrow: 1,
    background: '#99000050'
  }
});

// Create Document Component
const Book = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image src='https://cdn.pixabay.com/photo/2014/06/03/19/38/board-361516__340.jpg'></Image>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section2}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
  
);

// const a = () => (
//   <div>
//     <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
//       {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
//     </PDFDownloadLink>
//   </div>
// )

export default Book;
