import React, { useState, useEffect } from "react";
import Service from '../components/Service'

export const AuthContext = React.createContext();

export const AuthProvider = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [ticketsNotifications, setTicketsNotifications] = useState();

  useEffect(()=>{
    setInterval(()=>{
      Service.get('/tickets/notifications', {}, (res) => {
        setTicketsNotifications(res.tickets);
      })
    }, 30000);
  }, []);

  const contextValue = {
    loggedIn,
    ticketsNotifications,
    setTicketsNotifications,
    setLoggedIn
  };
  return (
    <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
  );
};