import React, { Component } from 'react';

class Dates {

    static getYear (date) { return date.split(' ')[0].split('-')[0]} 
    static getMonth (date) { return date.split(' ')[0].split('-')[1]} 
    static getDay (date) { return date.split(' ')[0].split('-')[2]} 
    static getHour (date) { return date.split(' ')[1].split(':')[0]} 
    static getMinute (date) { return date.split(' ')[1].split(':')[1]} 
    static getSecond (date) { return date.split(' ')[1].split(':')[2]} 

}

const dates = {
    years: [2015,2016,2017,2018,2019,2020,2021,2022,2023, 2024, 2025, 2026],
    months: ['01','02','03','04','05','06','07','08','09','10','11','12']
}

let hours = [];
let minutes = [];
let seconds = [];
//let days = [];
for(var i = 0; i < 60; i++){
    let n = i.toString();
    if(i < 10){
        n = '0'+n;
    }
    if(i < 24){
        hours.push(n);
    }
    minutes.push(n);
    seconds.push(n);
}

function getDaysInMonth (month, year) { 
    let m = parseInt(month);
    let r = new Date(year, m, 0).getDate(); 
    
    var days = [];
    for(var i = 0; i< r; i ++){
        let n = (i + 1).toString();
        if (i < 9){
            n = '0'+n;
        }
        days.push(n);
    }
    console.log(days, m, year)
    return days;
}

function appendLeadingZeroes(n){
  if(n <= 9){
    return "0" + n;
  }
  return n
}



class DateSelector extends Component {

	constructor(props) {
	    super(props);
        this.state = {
            year: '2019',
            month: '01',
            day: '01',
            hour: '00',
            minute: '00',
            second: '00',
            days: []
        }
	}

    updateDate = () =>{
        if(this.props.date){

            this.setState({
                year: Dates.getYear(this.props.date),
                month: Dates.getMonth(this.props.date),
                day: Dates.getDay(this.props.date),
                hour: Dates.getHour(this.props.date),
                minute: Dates.getMinute(this.props.date),
                second: Dates.getSecond(this.props.date),
            }, ()=>{
                console.log(this.state);
            })
        }
    }

    componentDidMount(){
        this.updateDate();
        this.updateDays();
        
        //setTimeout(()=>{this.sendDate()}, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.date !== this.props.date) {
          console.log('dates state has changed.')
          this.updateDate();
        }
      }

    sendDate=()=>{
        // call the parent component to update the dates
        let date = new Date(this.state.year, this.state.month - 1, this.state.day)
        //let formatted_date = date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate()) + ' ' + appendLeadingZeroes(this.state.hour) + ':' + appendLeadingZeroes(this.state.minute) + ':' + appendLeadingZeroes(this.state.second);
        let formatted_date = this.state.year + "-" + this.state.month + "-" + this.state.day + ' ' + this.state.hour + ':' + this.state.minute + ':' + this.state.second;
        this.props.onChange(formatted_date);
    }

    setYear=(y)=>{
        this.setState({
            year: y
        },()=>{
            this.sendDate();
        })
    }

    setMonth=(m)=>{
        this.setState({
            month: m
        },()=>{
            this.sendDate();
        })
    }

    setDay=(d)=>{
        this.setState({
            day: d
        },()=>{
            this.sendDate();
        })
    }
    setHour=(n)=>{
        this.setState({
            hour: n
        },()=>{
            this.sendDate();
        })
    }
    setMinute=(n)=>{
        this.setState({
            hour: n
        },()=>{
            this.sendDate();
        })
    }
    setSecond=(n)=>{
        this.setState({
            hour: n
        },()=>{
            this.sendDate();
        })
    }

    changeYear=(event)=>{
        this.setState({year: event.target.value},()=>{
            this.updateDays();
            this.sendDate();
        });
    }
    changeMonth=(event)=>{
        this.setState({month: event.target.value},()=>{
            console.log('mmm', this.state.month);
            this.updateDays();
            this.sendDate();
        });

        // this.updateDays();
    }
    changeDay=(event)=>{
        this.setState({day: event.target.value},()=>{
            this.sendDate();
        });
    }

    changeHour=(event)=>{
        this.setState({hour: event.target.value},()=>{
            this.sendDate();
        });
    }
    changeMinute=(event)=>{
        this.setState({minute: event.target.value},()=>{
            this.sendDate();
        });
    }
    changeSecond=(event)=>{
        this.setState({second: event.target.value},()=>{
            this.sendDate();
        });
    }

    updateDays=()=>{
        console.log(this.state.month,this.state.year)
        this.setState({days: getDaysInMonth(this.state.month, this.state.year)})
    }

  render() {
    return (
      <div className="date-selector">
        <span style={{marginRight: '1em'}}>{this.props.label} </span> 

        {/* year */}
        god:<select value={this.state.year} onChange={this.changeYear}>
                    {dates.years.map(function(item, index){
                    return(
                        <option value={item} key={index} {...this.state.day == item ? 'selected' : ''}>{item}</option>
                    )},this
                    )};
        </select>
        {/* month */}
        mesec:<select value={this.state.month} onChange={this.changeMonth}>
                    {dates.months.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
        {/* days */}
        dan:<select value={this.state.day} onChange={this.changeDay}>
                    {this.state.days.map(function(item, index){
                    return(
                        <option value={item} key={index} {...this.state.day == item ? 'selected' : ''}>{item}</option>
                    )},this
                    )};
        </select>
        {/* hours */}
        sat:<select value={this.state.hour} onChange={this.changeHour}>
                    {hours.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
        {/* minutes */}
        min:<select value={this.state.minute} onChange={this.changeMinute}>
                    {minutes.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
        {/* seconds */}
        sek:<select value={this.state.second} onChange={this.changeSecond}>
                    {seconds.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
      </div>
      
    )
  }
}

export default DateSelector;