import React, {useState, useEffect} from 'react';
import Service from '../components/Service';

export default function AdminGroups(){

    const [groups, setGroups] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [group, setGroup] = useState();
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [create, setCreate] = useState();
    const [groupName, setGroupName] = useState();
    const [groupDescription, setGroupDescription] = useState();

    useEffect(()=>{
        getGroups();
        Service.get('/users/getPermissions', {}, res => {
            setPermissions(res.permissions);
        });
    },[]);

    const getGroups=()=>{
        Service.get('/users/getAdminGroups', {}, res => {
            setGroups(res.groups);
        });
    }

    useEffect(()=>{
        getGroupPermissions();
    },[group]);

    useEffect(()=>{
        console.log('go', groupPermissions);
        let gp = [];
        permissions.map(item => {
            gp.push(item);
        });
        gp.map(gper => {
            gper.granted = false;
            groupPermissions.map(item => {
                if(gper.id == item.permission_id){
                    gper.granted = true;
                }
            })
        });
        setPermissions(gp);
    },[groupPermissions]);

    useEffect(()=>{
        console.log('per', permissions);
    }, [permissions])

    const getGroupPermissions=()=>{
        if(!group) return;
        Service.get('/users/getGroupPermissions', {groupId: group.id}, res => {
            setGroupPermissions(res.permissions);
        });
    }

    const togglePermission=(event)=>{
        if(!group){
            alert('Niste odabrali grupu'); 
            return;
        }
        let permissionId = event.target.getAttribute('data-id');
        Service.get('/users/togglePermission', {groupId: group.id, permissionId: permissionId}, res => {
            getGroupPermissions();
        });

    }



    const addGroup=()=>{
        console.log(groupName);
        Service.get('/users/createAdminGroup', {groupName: groupName, groupDescription: groupDescription}, res => {
            if(res.success){
                alert('Grupa je uspesno napravljena.');
                setGroup(null);
                setGroupName();
                setGroupDescription();
                setCreate(false);
            }
            getGroups();
        });
    }

    const deleteGroup=()=>{
        if(window.confirm('Da li sete sigurni da zelite da izbrisete grupu "' + group.name + '"?')){
            Service.get('/users/deleteAdminGroup', {groupId: group.id}, (res) => {
                setGroup(null);
                getGroups();
            })
        }
    }

    

    return(
        <div>
            <h1>Admin Grupe</h1>
            <div className='admin-groups-list'>
                <h2>Grupe</h2>
                {groups &&
                <ul>
                    
                    {groups.map(item => {
                        return(
                            <li onClick={()=>{setGroup(item)}} className={group && group.id == item.id ? 'selected' : ''}>{item.name}</li>
                        )
                    })}
                </ul>
                }
                <button onClick={()=>{setCreate(true)}}>Napravi grupu</button>
                {create &&
                    <div className='create-admin-group'>
                        <input placeholder="ime grupe..." autoFocus onChange={(event)=>setGroupName(event.target.value)} value={groupName} />
                        <textarea placeholder="opis grupe..." onChange={(event)=>setGroupDescription(event.target.value)} value={groupDescription} />
                        <button onClick={()=>{setCreate(false)}}>Odustani</button>
                       <button onClick={addGroup}>Snimi</button>
                    </div>
                }
            </div>
            <div className='admin-groups-permissions'>
                <h2>Dozvole</h2>
                {group &&
                    <>
                        <h3>{group.name}</h3>
                        <p>{group.description}</p>
                        <button onClick={deleteGroup}>Obriši grupu</button>
                        <hr />
                        <ul>
                            {permissions.map(item => {
                                return(
                                    <li>
                                        <input data-id={item.id} onClick={togglePermission} id={`permission${item.id}`} checked={item.granted} type="checkbox" />
                                        <label for={`permission${item.id}`} >{item.name}</label>
                                        <p className="permission-description">{item.description}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                }
            </div>
        </div>
    )
}