import React, { Component } from 'react';
import { Fragment } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import {Auth} from './Auth';

class UserMenu extends Component {

  constructor(props) {
    super(props);
        this.state = {
           
        }
    }  
    
    componentDidMount(){
        setInterval(function(){
            this.forceUpdate();
        }.bind(this), 500);
    }

    logout=()=>{
        Auth.logout(()=>{
            window.location.href = '/login';
        });
    }

    login=()=>{
        window.location.href = '/login';
    }

    render() {
        return (
            <div id="user-menu">
                <span className="header-title">Publik Praktikum CMS</span>

                {Auth.isAuthenticated &&
                    <div className="user-menu-right">
                        <p>{Auth.userName}</p>
                        <button onClick={this.logout}>Izloguj se</button>
                    </div>
                }

                {!Auth.isAuthenticated &&
                    <div className="user-menu-right">
                        <button className="user-menu-login" onClick={this.login}>Uloguj se</button>
                    </div>
                }
                
            </div>
        )
    }
}

export default UserMenu;
