import React, {useState, useEffect, useRef} from 'react';
import Service from '../components/Service';

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

export default function ProductsFilter(props) {

    const [filter, setFilter] = useState();
    const [selectedProduct, setSelectedProduct] = useState({label: null, value: null});
    const [products, setProducts] = useState([]);
    const [selectedIndex, setIndex] = useState(0);
    const prevIndex = usePrevious(selectedIndex);
    const [visible, setVisible] = useState(false);

    const getProducts = (event) => {
        if(event.key && event.key == "Enter"){
            if(!visible){
                setIndex(0);
                Service.get('/products/get', {filter: filter}, (res)=>{
                setProducts(res.data.products);
                setVisible(true);
            
                })
            }else{
                let product = products[selectedIndex];
                props.onSelect(product);
                setVisible(false);

            }
        }

    };

    const changeOption = (event) => {

        let list = ref.current;
        if (event.keyCode === 38 && selectedIndex > 0) {
            setIndex(prevIndex => prevIndex - 1);
            
        }
        if (event.keyCode === 40 && selectedIndex < products.length - 1) {
            setIndex(prevIndex => prevIndex + 1);
            
            
        }
        if(event.key && event.key == "Escape"){
            setVisible(false);
        }
        
    }

    useEffect(()=>{
        if(products.length && visible){
            let list = ref.current;
            console.log('scroll', list.scrollTop, list.scrollTop/30);
            console.log(prevIndex, selectedIndex)

            if(prevIndex > selectedIndex){
                if(list.scrollTop/30 > selectedIndex){
                    list.scroll(0, 30 * (selectedIndex), true);
                }
            }else{
                if(list.scrollTop/30 < selectedIndex - 4){
                    list.scroll(0, 30 * (selectedIndex - 4), true);
                }
            }
        }
    }, [selectedIndex])

    

    const ref = useRef(null);

  return (
      <div className="products-filter">
        <input onChange={(event)=>setFilter(event.target.value)} value={filter} onKeyPress={getProducts} onKeyDown={changeOption} placeholder={props.placeholder}/>
        {visible &&
            <ul ref={ref}>
                {products.map((item, index)=>{
                    return(
                        <li className={selectedIndex == index ? 'selected' : ''}>{item.title}</li>
                    )
                })
                
                }
            </ul>
        }
    </div>
  )

}