import React, { Component } from 'react';
import { Fragment } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import TicketNotification from '../components/TicketNotification'


class Header extends Component {

  constructor(props) {
    super(props);
        this.state = {
            visibility: window.innerWidth <= 720 ? 'none' : 'block',
			showProductItems: false,
			loggedIn: false
        }
  }  

// open close menu
handleMenu = () => {
    if(window.innerWidth <= 720){
        let e = document.getElementById('menu');
        if(e.style.display == 'none' || !e.style.display){
            e.style.display = 'block';
        }else{
            e.style.display = 'none';
        }
        console.log(e);
    }
}

getLink=(event)=>{
    console.log(event.target.href)
}

unsubscribe = []

componentDidMount=()=>{

	this.unsubscribe[0] = duix.subscribe('loggedIn', this.updateMenuVisibility);
	//console.log('Auth', Auth.isAuthenticated);
	this.setState({loggedIn: Auth.isAuthenticated});;
    //this.interval = setInterval(() => {this.updateMenuVisibility()}, 200);
	//setTimeout(() => {this.updateMenuVisibility()}, 200);
	// if(Auth.isAuthenticated){
	// 	this.setState({visibility: 'inline-block'});
	// }else{
	// 	this.setState({visibility: 'none'});
	// }
}

componentWillUnmount(){
    //clearInterval(this.interval);
	this.unsubscribe[0]();
	
}

updateMenuVisibility=()=>{
	this.setState({loggedIn: Auth.isAuthenticated})
    // if(window.location.pathname === '/login'){
    //     console.log(1);
    //     this.setState({visibility: 'none'});
    // }else{
    //     console.log(2);
    //     this.setState({visibility: 'inline-block'});
    // }
}

showProducts=()=>{
	if(this.props.location.pathname === '/products'){
		this.setState({showProductItems: true});
	}else{
		this.setState({showProductItems: !this.state.showProductItems});
	}
}

  render() {
    return (
    	<div id="header">
    		<div id="hamburger" onClick={this.handleMenu}>☰</div>
			{this.state.loggedIn &&
	      	<ul id='menu' onClick={this.handleMenu} style={{display: this.state.visibility}}>
		  	{/*<img className='logo-image' src={logo} /> */}

			    {/* <li className={this.props.location.pathname == '/' ? 'header-active': null}>
			      	<Link to="/">Početna</Link>
			    </li> */}
				{/* <li>
			      	<Link to="/login">Login</Link>
			    </li> */}
				<li className={(this.props.location.pathname === '/' || this.props.location.pathname.split('/')[1] === 'ticket') ? 'header-active': null}>
					<Link to="/">Tiketi <TicketNotification /></Link>
				</li>

			   	{Auth.checkPermission('/orders') && 
				   <li className={(this.props.location.pathname === '/orders' || this.props.location.pathname.split('/')[1] === 'order') ? 'header-active': null}>
						<Link to="/orders">Porudžbine</Link>
					</li>
				}
			    
				{Auth.checkPermission('/products') && 
				<li className={this.props.location.pathname === '/products' ? 'header-active': null} onClick={this.showProducts}>
			      	<Link to="/products">Proizvodi</Link>
			    </li>
  				}

				{this.state.showProductItems && Auth.checkPermission('/products') &&
					<div className="header-product-items">
						<li className={this.props.location.pathname === '/csv' ? 'header-active': null}>
							<Link to="/csv">Unos proizvoda</Link>
						</li>

						<li className={this.props.location.pathname === '/categories' ? 'header-active': null}>
							<Link to="/categories">Kategorije</Link>
						</li>

						<li className={this.props.location.pathname === '/media' ? 'header-active': null}>
							<Link to="/media">Slike Proizvoda</Link>
						</li>

						<li className={this.props.location.pathname === '/top-liste' ? 'header-active': null}>
							<Link to="/top-liste">Top Liste</Link>
						</li>

						<li className={this.props.location.pathname === '/komentari' ? 'header-active': null}>
							<Link to="/komentari" onClick={this.getLink}>Komentari</Link>
						</li>

						<li className={this.props.location.pathname === '/dobavljaci' ? 'header-active': null}>
							<Link to="/dobavljaci">Dobavljaci</Link>
						</li>

						<li className={this.props.location.pathname === '/export' ? 'header-active': null}>
							<Link to="/export">Export</Link>
						</li>
					</div>
				}

				{Auth.checkPermission('/products') && 
				<li className={this.props.location.pathname === '/discounts' ? 'header-active': null}>
			      	<Link to="/discounts">Popusti</Link>
			    </li>
  				}
				{Auth.checkPermission('/products') && 
                <li className={this.props.location.pathname === '/actions' ? 'header-active': null}>
			      	<Link to="/actions">Akcije</Link>
			    </li>
  				}

				{Auth.checkPermission('/news') &&
                <li className={this.props.location.pathname === '/news' ? 'header-active': null}>
			      	<Link to="/news">Vesti</Link>
			    </li>
  				}
               
			   {/* {Auth.checkPermission('/customers') &&
                <li className={this.props.location.pathname === '/customers' ? 'header-active': null}>
			      	<Link to="/customers">Kupci</Link>
			    </li>
  				} */}
				{Auth.checkPermission('/krcko') &&
				<li className={this.props.location.pathname === '/krcko' ? 'header-active': null}>
						<Link to="/krcko">Krcko</Link>
					</li>
 				}

				{Auth.checkPermission('/statistics') &&
					<li className={this.props.location.pathname === '/statistics' ? 'header-active': null}>
						<Link to="/statistics">Statistike</Link>
					</li>
				}

				{Auth.checkPermission('/misc') &&
				<>
					
					<li className={this.props.location.pathname === '/banners' ? 'header-active': null}>
						<Link to="/banners">Baneri</Link>
					</li>
					
					<li className={this.props.location.pathname === '/chat' ? 'header-active': null}>
						<Link to="/chat">Chat</Link>
					</li>
					<li className={this.props.location.pathname === '/barcode' ? 'header-active': null}>
						<Link to="/barcode">Barkod</Link>
					</li>
				</>
  				}
				{Auth.checkPermission('/warehouse') && 
				<li className={this.props.location.pathname === '/warehouse' ? 'header-active': null}>
			      	<Link to="/warehouse">Magacin</Link>
			    </li>
  				}
				{Auth.checkPermission('/document') && 
				<li className={this.props.location.pathname === '/document' || this.props.location.pathname === '/documents'  ? 'header-active': null}>
			      	<Link to="/documents">Dokumenti</Link>
			    </li>
  				}
				{Auth.checkPermission('/admins') && 
				<>
					<li className={this.props.location.pathname === '/admins' ? 'header-active': null}>
						<Link to="/admins">Administratori</Link>
					</li>
					<li className={this.props.location.pathname === '/admins/groups' ? 'header-active': null}>
						<Link to="/admins/groups">Admin Grupe</Link>
					</li>
				  </>
  				}
				{Auth.checkPermission('/fiscalisation') && 
				<li className={this.props.location.pathname === '/fiscalisation' || this.props.location.pathname === '/fiscalisation'  ? 'header-active': null}>
			      	<Link to="/fiscalisation">Fiskalizacija</Link>
			    </li>
  				}

			    {/*<li className="header-float-right">
			      	<Link userId={Auth.userId} to="/projects">Projects</Link>
			    </li>
			    <li className="header-float-right" >
			    	<Lhb loggedIn={Auth.isAuthenticated} />
			    </li>
			    <li className="header-float-right">
			      	<Link to="/my-account">My Account</Link>
			    </li>
			    <li className="header-float-right">
			      	<Link to="/register">Register</Link>
			    </li> */}
		 	</ul>
  			}
             
	  	</div>
    )
  }
}

export default withRouter(props => <Header {...props}/>);
