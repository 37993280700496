import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import { Fragment } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import InfoBox from '../components/InfoBox';
import DateTime from '../components/DateTime';
import Select from '../components/Select';

function formatDate(date){
    let r = new Date(date);

    return r.getDay() + '/' + r.getMonth() + '/' + r.getFullYear() + ' ' + r.getHours() + ':' + r.getMinutes() + ':' + r.getSeconds();
}

class Documents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            warehouses: [],
            users: [],
            filters: {
                type: null,
                output: null,
                input: null,
                userId: null,
                createdAt: null,
                updatedAt: null
            },
            products: []
        }
    }

    componentDidMount(){
        this.getDocuments();
    }

    getDocuments=()=>{
        Service.get("/warehouses/getDocuments", {filters: this.state.filters}, (res)=>{
            if(res.success){
                this.setState({documents: res.documents, warehouses: res.warehouses, users: res.users}, ()=>{
                    //console.log(this.state.documents, new Date(this.state.documents[0].document_date));
                });
            }
        })
    }

    handleFilterInput=(event)=>{

        let tag = event.target.tagName;
        let key = event.target.getAttribute('data-key');
        let filters = this.state.filters;
        let value = event.target.value;

        if(event.target.getAttribute('data-type') == 'date'){
            if(value.length == 2){
                value += '/';
            }
            if(value.length == 5){
                value += '/';
            }
        }

        filters[key] = value;
        this.setState({filters}, ()=>{
            //console.log(this.state.filters, tag);
            if(tag == "SELECT"){
                this.getDocuments();
            }
        });
    }

    handleDateFrom=(date)=>{
        let filters = this.state.filters;
        filters['createdAt'] = date;
        this.setState({filters}, ()=>{
                this.getDocuments();
        });
        
    }

    handleDateTo=(date)=>{
        let filters = this.state.filters;
        filters['dateTo'] = date;
        this.setState({filters}, ()=>{
                this.getDocuments();
        });
        
    }

    applyFilters=(event)=>{

        if(event.key && event.key == 'Enter'){
            this.getDocuments();
        }

        
    }

    clearFilters=()=>{

        let filters = {
            type: 0,
            output: 0,
            input: 0,
            userId: 0,
            createdAt: '',
            updatedAt: ''
        }

        console.log(filters);

        this.setState({filters: filters}, ()=>{
            console.log(this.state.filters);
            this.getDocuments();
        });
    }

    setProductFilter=(res)=>{
        let filters = this.state.filters;
        filters['productId'] = res;
        console.log('res', res);
        this.setState({filters}, ()=>{
                this.getDocuments();
        });
    }

    getProducts=(filter)=>{
        console.log('test', filter);
        Service.get('/warehouses/getFilteredProducts',{filter: filter},(res)=>{
            this.setState({products: res.products},()=>{
                //this.handlePages();
                console.log('products', this.state.products)
            });
        });    
    }

  

  render() {
    return (
      <div>
          <h1>Dokumenti</h1>
          <div><Link to='/document'><button>Novi dokument</button></Link></div>
            <div className="mobile-table-containter">
                <div>
                    <Select async id='id' value='title' onFilter={(filter)=>{this.getProducts(filter)}} options={this.state.products}  onSelect={(res)=>{this.setProductFilter(res)}} placeholder="proizvod..." />
                </div>
                <table className="cms-table">
                <thead>
                        <tr>
                            <td>id</td>
                            <td>tip</td>
                            <td>izlaz</td>
                            <td>ulaz</td>
                            <td>odgovorno lice</td>
                            <td>status</td>
                            <td>datumi</td>
                            <td>količina</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <select data-key="type" onChange={this.handleFilterInput} value={this.state.filters.type}>
                                    <option value='0'>svi</option>
                                    <option value='1'>ulaz</option>
                                    <option value='2'>prenos</option>
                                    <option value='3'>izlaz</option>
                                </select>
                            </td>
                            <td>
                                <select data-key="output" onChange={this.handleFilterInput} value={this.state.filters.output}>
                                    <option value="0">svi</option> 
                                    {this.state.warehouses.map(item=>{
                                        return(
                                            <option value={item.id}>{item.name}</option> 
                                        )
                                    })}
                                </select>
                            </td>
                            <td>
                                <select data-key="input" onChange={this.handleFilterInput} value={this.state.filters.input}>
                                    <option value="0">svi</option> 
                                    {this.state.warehouses.map(item=>{
                                        return(
                                            <option value={item.id}>{item.name}</option> 
                                        )
                                    })}
                                </select>
                            </td>
                            
                            <td>
                                <select data-key="userId" onChange={this.handleFilterInput} value={this.state.filters.userId}>
                                    <option value="0">svi</option> 
                                    {this.state.users.map(item=>{
                                        return(
                                            <option value={item.id}>{item.name}</option> 
                                        )
                                    })}
                                </select>
                            </td>
                            <td><select data-key="status" onChange={this.handleFilterInput} value={this.state.filters.status}>
                                <option value="all">svi</option> 
                                <option value="1">odobreni</option>
                                <option value="0">neodobreni</option> 
                                </select></td>
                            <td>
                            <>
                                <DateTime text="Ako odaberes drugi datum dokumenti ce se vratiti od-do" selected={this.state.date} showTimeSelect onChange={this.handleDateFrom}/>
                                
                                <DateTime selected={this.state.date} showTimeSelect onChange={this.handleDateTo}/>
                                
                                </>
                            </td>
                            <td>Ulaz</td>
                            <td>Prenos</td>
                            <td>Stanje</td>
                            <td>Izlaz</td>
                            <td className="align-right" ><button onClick={this.clearFilters}>Obriši filtere</button></td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.documents.map(item=>{ 
                            return(
                                
                                <tr className={item.status ? '' : 'pending-document'}>
                                    
                                    <td>{item.id}</td>
                                    <td>{item.type_name}</td>
                                    <td>{item.wh_from_name}</td>
                                    <td>{item.wh_to_name}</td>
                                    <td>{item.username}</td>
                                    <td>{item.status ? '✓' : 'neodobren'}</td>
                                    <td>{item.document_date}</td>
                                    <td>{item.input_quantity ? '+' : null}{item.input_quantity}</td>
                                    <td style={{color: 'gray'}}>{item.transfer_quantity ? '~' : null}{item.transfer_quantity}</td>
                                    <td><b>{item.current_quantity}</b></td>
                                    <td>{item.output_quantity ? '-' : null}{item.output_quantity}</td>
                                    <td className="align-right" ><Link to={`/document/${item.id}`} ><button>Pogledaj</button></Link></td>
                                </tr>
                                
                            )
                        })}
                    </tbody>
                </table>
            </div>
      </div>
    );
  }

}

export default Documents;
