import React, { Component } from 'react';
import Tickets from '../components/Tickets';

class Home extends Component {

    constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
          <h1>Publik Praktikum - CMS</h1>
          <Tickets />
      </div>
    );
  }

}

export default Home;
