import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';

class ChatView extends Component {

    constructor(props) {
        super(props);
        this.state = {
           chatId: '',
           messages: [],
           inputValue: '',
           storeId:''
        }
    }
 
    componentWillReceiveProps(nextProps){
        this.setState({
            chatId: nextProps.selectedChat,
            storeId: nextProps.storeId
        },()=>{
            this.getMessages();
            if(this.state.chatId){
                document.getElementById("input").focus();
            }
        })
    }

    getMessages(){
        Service.get('/chat/get',{
            chatId: this.state.chatId
        },(res)=>{
            if(res.success){
                this.setState({messages: Object.values(res)},()=>{
                    let element = document.getElementById("scrollbar-bottom");
                    element.scrollTop = element.scrollHeight;
                    setTimeout(()=>{this.getMessages()}, 6000);
                })
            }
        });
    }

    handleInput=(event)=>{
        this.setState({inputValue: event.target.value}); 
    }

    enterPressSendMessage = (e) => {
        if(e.which === 13) {
          this.sendMessage();
        }
    }

    sendMessage=()=>{
        Service.get('/chat/sendMessage',{
            chatId: this.state.chatId,
            message: this.state.inputValue,
            storeId: this.state.storeId
        },(res)=>{
           if(res.success){
                this.getMessages();
                this.setState({inputValue: ''});
           }
       })
    }


  render(){
    return(
        <div>
            {this.state.storeId === '3' ? 
                <div className="right-top-pp">
                    <h3>{this.state.chatId}</h3>
                </div>
            : this.state.storeId === '1' ? 
                <div className="right-top-hk">
                    <h3>{this.state.chatId}</h3>
                </div>
            : null}
            <div className="messages" id="scrollbar-bottom">
                {this.state.messages.map(function (item, index) {
                    return(
                        <div key={index}>
                            {this.state.chatId !== item.msg_from &&
                                <div className="my-message">
                                    <p>{item.message}</p>
                                </div>
                            }
                            {this.state.chatId === item.msg_from &&
                                <div className="from-message">
                                    <p>{item.message}</p>
                                </div>
                            }
                        </div>
                    )
                },this)}
            </div>
            {this.state.chatId &&
                <div className="bottom">
                    <textarea id="input" type="text" value={this.state.inputValue} onChange={this.handleInput} onKeyPress={this.enterPressSendMessage} rows="3"></textarea>
                    <button onClick={this.sendMessage} onKeyPress={this.enterPressSendMessage} >Pošalji</button>
                </div>
            }
            
        </div>
    )
}
}

export default ChatView;