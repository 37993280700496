import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

const API = process.env.REACT_APP_API;

function images(image){
  return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

const Info = props => (
  
     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
  
);

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [{
                id: '',
                title: '',
                price: 0,
                sku: '',
                author: '',
                binding: '',
                dimension: '',
                weight: '',
                slug: '',
                supplier: '',
                isbn: '',
                description: '',
                numberOfPages: 0,
                letter: '',
                translator: ''
            }],
            allCategories: [],
            id: 0,
            title: '',
            price: 0,
            sku: '',
            author: '',
            binding: '',
            dimension: '',
            weight: '',
            slug: '',
            supplier: '',
            isbn: '',
            description: '',
            numberOfPages: 0,
            letter: '',
            translator: '',
            ilustrator: '',
            categories: [],
            stores: [],
            allStores: []
        }
        this.editProductValue = this.editProductValue.bind(this);
    }


  getAllCategories = () => {
      Service.get('/categories/all',{id: this.props.match.params.id},(res)=>{
          this.setState({allCategories: res.data,
          });
      })
      
  }

  getAllStores = () => {
      Service.get('/products/getStores',{id: this.props.match.params.id},(res)=>{
          this.setState({allStores: res.data.stores,
          });
      })
  }

  componentDidMount() {
    //this.updateProducts();
    this.getAllCategories();
    this.getAllStores();
    console.log('products', this.state.products);
    console.log('page',this.props.match.params.page);
    console.log('filter',this.props.match.params.filter);
  }

  editProductValue(event){
    this.setState({[event.target.id]: event.target.value});

  }

  checkCategory = (categoryName) => {
      let check = false;
      this.state.categories.map(function(category){
          if(category == categoryName){
              check = true;
          }
      });

      if(check){
          return true;
      }else{
        return false;
      }
  }

  checkStore = (storeName) => {
      let check = false;
      this.state.stores.map(function(store){
          if(store == storeName){
              check = true;
          }
      });

      if(check){
          return true;
      }else{
        return false;
      }
  }


  expandTopCategory = (event) => {
      let e = event.currentTarget.parentNode.getElementsByClassName('categories-cont')[0];
        console.log('h', e.style.height);
      if(e.style.height == 'auto' || !e.style.height){
        e.style.height = '0';
        event.target.classList.add('arrow-closed');
        event.target.classList.remove('arrow-open');
      }else{
        e.style.height = 'auto';
        event.target.classList.add('arrow-open');
        event.target.classList.remove('arrow-closed');
      }
      console.log(e.style.height);
  }

  handleCategory = (event) => {
      console.log('et', event.target.value);
      if(this.checkCategory(event.target.value)){
          this.removeCategory(event.target.value);
      }else{
          this.addCategory(event.target.value);
      }
  }

  handleStore = (event) => {
      console.log('et', event.target.value);
      if(this.checkStore(event.target.value)){
          this.removeStore(event.target.value);
      }else{
          this.addStore(event.target.value);
      }
  }

  createProducts = () =>{
      Service.get('/products/create',{
          products: [{
            title: this.state.title,
            price: this.state.price,
            sku: this.state.sku,
            author: this.state.author,
            binding: this.state.binding,
            dimension: this.state.dimension,
            weight: this.state.weight,
            slug: this.state.slug,
            supplier: this.state.supplier,
            isbn: this.state.isbn,
            description: this.state.description,
            number_of_pages: this.state.numberOfPages,
            letter: this.state.letter,
            translator: this.state.translator,
            ilustrator: this.state.ilustrator,
            categories: this.state.categories.toString(),
            stores: this.state.stores.toString()
          }]
      },()=>{
          window.location.replace("/products");
          });
    //   })
    // fetch(`${API}/products/create`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       products: [{
    //         title: this.state.title,
    //         price: this.state.price,
    //         sku: this.state.sku,
    //         author: this.state.author,
    //         binding: this.state.binding,
    //         dimension: this.state.dimension,
    //         weight: this.state.weight,
    //         slug: this.state.slug,
    //         supplier: this.state.supplier,
    //         isbn: this.state.isbn,
    //         description: this.state.description,
    //         number_of_pages: this.state.numberOfPages,
    //         letter: this.state.letter,
    //         translator: this.state.translator,
    //         categories: this.state.categories.toString(),
    //         stores: this.state.stores.toString()
    //       }]
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //       window.location.replace("/products");
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  addCategory = (categoryName) => {
      let categories = this.state.categories;
      categories.push(categoryName);
      this.setState({categories: categories});
      console.log('cats',this.state.categories);
  }

  removeCategory = (categoryName) => {
      let categories = this.state.categories;
      for(var i in categories){
          if(categories[i] == categoryName){
              categories.splice(i, 1);
          }
      }
    this.setState({categories: categories});
  }

  addStore = (storeName) => {
      let stores = this.state.stores;
      stores.push(storeName);
      this.setState({stores: stores});
      console.log('cats',this.state.stores);
  }

  removeStore = (storeName) => {
      let stores = this.state.stores;
      for(var i in stores){
          if(stores[i] == storeName){
              stores.splice(i, 1);
          }
      }
    this.setState({stores: stores});
  }

  handleDescription = (value) => {
    //console.log(value);
    this.setState({ description: value })
  }

  modules = {
    toolbar: [
      [{ 'header': [2, 3, false] }],
      ['bold', 'italic', 'underline','strike'],
      ['link', 'image'],
      ['clean']
    ],
  }

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'link', 'image'
  ]

  render() {
    return (
      <div>
            <h1>Novi Proizvod:</h1>
            {this.state.products.map(function(item, index){

              let categories = [];
              let stores = [];
            //   item.categories.map(function(itm){
            //       categories.push(itm.name);
            //   })

            //   item.stores.map(function(itm){
            //       stores.push(itm.name);
            //   })

              

                return(
                    <div className="product-edit" key={index} >
                      <h3>{this.state.title}</h3>
                      {/* <img src={images(item.slug.toLowerCase())} /> */}
                      <div className="product-edit-inputs">
                      <Info label="id" value={item.id} />
                      Naslov: <input type="text" id="title" value={this.state.title} onChange={this.editProductValue} />
                      SKU: <input type="text" id="sku" value={this.state.sku} onChange={this.editProductValue} />
                      Cena: <input type="text" id="price" value={this.state.price} onChange={this.editProductValue} />
                      Autor: <input type="text" id="author" value={this.state.author} onChange={this.editProductValue} />
                      Povez: <input type="text" id="binding" value={this.state.binding} onChange={this.editProductValue} />
                      Dimenzije: <input type="text" id="dimension" value={this.state.dimension} onChange={this.editProductValue} />
                      Tezina: <input type="text" id="weight" value={this.state.weight} onChange={this.editProductValue} />
                      Slug: <input type="text" id="slug" value={this.state.slug} onChange={this.editProductValue} />
                      Dobavljac: <input type="text" id="supplier" value={this.state.supplier} onChange={this.editProductValue} />
                      Broj strana: <input type="text" id="numberOfPages" value={this.state.numberOfPages} onChange={this.editProductValue} />
                      Pismo: <input type="text" id="letter" value={this.state.letter} onChange={this.editProductValue} />
                      Prevodilac: <input type="text" id="translator" value={this.state.translator} onChange={this.editProductValue} />
                      Ilustrator: <input type="text" id="ilustrator" value={this.state.ilustrator} onChange={this.editProductValue} />
                      ISBN: <input type="text" id="isbn" value={this.state.isbn} onChange={this.editProductValue} />
                      {/* Opis: <textarea type="text" id="description" value={this.state.description} onChange={this.editProductValue} /> */}
                      <ReactQuill value={this.state.description}
                        className="text-editor"
                                    modules={this.modules}
                                    formats={this.formats}
                                onChange={this.handleDescription} />
                        
                      </div>
                      <button onClick={this.createProducts}>Dodaj Proizvod</button>
                    </div>
                );
                },this)
            }
                {/* stores */}

                <div className="product-edit-categories">
                          <h2>PRODAVNICE:</h2>
                          <p className="tooltip">klikni na checkbox da dodas ili skines proizvod iz prodavnice.</p>
                    <div>
                      {this.state.allStores.map(function(item){
                            return(
                                <p className='product-edit-subcategory'>
                                <input type="checkbox" name="store" value={item.name} onClick={this.handleStore} checked={this.checkStore(item.name)}></input>
                                {item.name}
                                </p>
                                
                            );
                        },this)
                        }
                        </div>
                {/* </div> */}

                <hr />
                {/* categories */}
                {/* <div className="product-edit-categories"> */}
                          <h2>KATEGORIJE:</h2>
                          <p className="tooltip">klikni na checkbox da dodas ili skines proizvod iz kategorije.</p>
                    <div>
                      {this.state.allCategories.map(function(item){
                            return(
                                
                                <div className='product-edit-categories-cont' >
                                <h4 className="arrow-open" onClick={this.expandTopCategory} >{item.name}</h4>
                                <div className="categories-cont">
                                {item.subcategories.map(function(subcategory){
                                  return(
                                     <p className='product-edit-subcategory'>
                                     <input type="checkbox" name="subcategory" value={subcategory.name} onClick={this.handleCategory} checked={this.checkCategory(subcategory.name)}></input>
                                     {subcategory.name}
                                     </p>
                                        
                                  );
                                },this)
                                }
                                </div>
                                </div>
                                
                            );
                        },this)
                        }
                        </div>
                </div>
      </div>
    );
  }

}

export default Product;