import React, { Component } from 'react';
import { Fragment } from 'react';
import Service from '../components/Service';



class ProductsCategories extends Component {
    constructor(){
        super();
        this.state={
            storeId:'3',
            categories: [],
            selectedCategory: '',
            subcategory: [],
            selectedSubcategory: '',
            products: []
        }

    }

    store = {
        1: "Harmonija knjige",
        3: "Publikpraktikum"
    }

componentDidMount() {
    this.getCategories();
}

changeStore = (event) => {
    this.setState({storeId: event.target.getAttribute('data-store-id')})
}

getCategories=()=>{
    Service.get('/categories/all',{},(res)=>{
        this.setState({categories: res.data},()=>{

        })
    })
}

selectCategory=(event)=>{
    if(event.target.value == "title"){
         return;
    }

    this.setState({selectedCategory: event.target.value, selectedSubcategory: '', subcategory: [], products: []},()=>{
        let categoryId = this.state.selectedCategory;
        this.state.categories.map(function(item, index){
            if(item.id === parseInt(categoryId)){
                if(item.subcategories.length > 0){
                    this.setState({subcategory: item.subcategories})
                }else{
                    this.setState({selectedSubcategory: item.id},()=>{
                        this.getProductsByCategory();
                    })
                }
            }
        },this);
    });
}

selectSubcategory=(event)=>{
    if(event.target.value == "title"){
        return;
    }

    this.setState({selectedSubcategory: event.target.value},()=>{
        this.getProductsByCategory();
    })
}

getProductsByCategory=()=>{
    if(!this.state.selectedSubcategory){
        alert("Izaberite kategoriju/podkategoriju");
        return;
    }

    Service.get('/products/subcategory',{
        categoryId: this.state.selectedSubcategory,
        storeId: this.state.storeId
    },(res)=>{
        this.setState({products: res.data})
    })
}

selectProduct=(event)=>{
    Service.get('/products/setsubcategory',{
        hasCategory: event.target.checked ? 1 : 0,
        categoryId: this.state.selectedSubcategory,
        productId: event.target.id
    },(res)=>{
        if(res.success){
            this.getProductsByCategory();
        }
    })
}

    render(){
        return (
            <div>
                <h2>{this.store[this.state.storeId]}</h2>
                <button id="3" onClick={this.changeStore} data-store-id="3" className="publikpraktikum-button">Publikpraktikum</button>
                <button id="1" onClick={this.changeStore} data-store-id="1" className="harmonija-knjige-button">Harmonija knjige</button>
                <br />
                {this.state.categories.length > 0 &&
                    <select value={this.state.selectedCategory} onChange={this.selectCategory}>
                        <option value="title">Izaberi kategoriju</option>           
                        {this.state.categories.map(function(item, index){
                            return(  
                                <option value={item.id} key={index}>{item.name}</option>           
                            )},this
                        )};
                    </select>
                }
                {this.state.subcategory.length > 0 &&
                    <select value={this.state.selectedSubcategory} onChange={this.selectSubcategory}>
                        <option value="title">Izaberi podkategoriju</option>           
                        {this.state.subcategory.map(function(item, index){
                            return(  
                                <option value={item.id} key={index}>{item.name}</option>           
                            )},this
                        )};
                    </select>
                }

                {this.state.products.length > 0 &&
                <Fragment>
                    <table className='cms-table'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Status</th>
                                <th>Ime</th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.products.map(function(item, index){
                                return(  
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>
                                            <input type="checkbox" checked={item.hasSubCategory} id={item.id} onClick={this.selectProduct}></input>
                                        </td>
                                        <td>{item.title}</td>
                                        <td>{item.price}</td>
                                    </tr>
                                )},this
                            )};
                        </tbody>
                    </table>
                </Fragment>
                }
            </div>
        );
    }

}

export default ProductsCategories;