import React, { Component } from 'react';
import Service from "../components/Service";
import DateTime from '../components/DateTime';
import Select from '../components/Select';
import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'

var dto = new Date();
dto.setHours(0,0,0,0);

var dtf = new Date(2021, 1, 1, 12);
// dtf.setMonth(dtf.getMonth() - 1);
// dtf.setHours(0,0,0,0);

class TicketsStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            from: null,
            to: null,
            filter: null,
            dateTo: dto,
            dateFrom: dtf,
            sortDirection: 1,
            sortFilter: 'average',
            totalAverageDiscount: null,
            selectedStore: 0,
            stats: null,
            options: {
                chart: {
                  type: 'column',
                  plotBorderWidth: 1,
                  zoomType: 'xy'
                },
                xAxis: {
                  categories: ['tip problema'],
                },
                title: {
                  text: "Statistike tiketa"
                },
                series: [{
                    name: 'Installation & Developers',
                    data: [43934, 48656, 65165, 81827, 112143, 142383,
                        171533, 165174, 155157, 161454, 154610]
                }, {
                    name: 'Manufacturing',
                    data: [24916, 37941, 29742, 29851, 32490, 30282,
                        38121, 36885, 33726, 34243, 31050]
                }]
              },

        }
    }

    componentDidMount(){
        //this.updateData();
        this.getTickets();
    }

    sortDisplay =(n)=>{
        if(n == this.state.sortFilter){
            if(this.state.sortDirection){
                return '+';
            }
            return '-';
        }
    }


    getStats=()=>{
        this.setState({loading: 1});
        console.log(this.state);
        Service.get(`/stats/getProducts`,{filter: this.state.filter, 
            dateFrom: this.state.dateFrom, 
            dateTo: this.state.dateTo,
            sortDirection: this.state.sortDirection,
            sortFilter: this.state.sortFilter,
            store: this.state.selectedStore
        
        }, (res)=>{
           if(res.success){
               this.setState({products: res.products, totalAverageDiscount: res.average_discount, stats: res.stats});
           }
        })
    }

    getTickets=()=>{
        Service.get(`/stats/getTickets`,{dateFrom: this.state.dateFrom, dateTo: this.state.dateTo},(res)=>{
            let opt = this.state.options;
            let data = [];
            res.tickets.map(item => {
                data.push({name: item.type_name, data: [item.number_of_tickets]});
            })
                opt.series = data;
                //opt.series[index] = {name: filters.name, data: [55,66,33,44,66,22,55,33,55,44]}
                this.setState({options: opt});
        }); 
    }

    setFilter=(event)=>{
        this.setState({filter: event.target.value}, ()=>{

            setTimeout(() => {
                //this.getStats();
            }, 500);  

            
        });
    }

    applyFilter=(event)=>{
        if(event.key && event.key == 'Enter'){
            this.getStats();
        }
        
    }

    handleDateFrom=(date)=>{
        this.setState({dateFrom: date}, ()=>{
                //this.getStats();
        });
        
    }

    handleDateTo=(date)=>{
        this.setState({dateTo: date}, ()=>{
                //this.getStats();
        });
        
    }

    changeFilter=(filter)=>{

        if(this.state.sortFilter == filter){
            this.setState({sortDirection: !this.state.sortDirection}, ()=>this.getStats())
        }else{
            this.setState({sortFilter: filter}, ()=>this.getStats())
        }
    }

    render() {
        return (
            <div>
                <h1>Statistike Tiketa</h1>
                
                <div className="quarter padding-right" style={{fontSize: '.8em'}}>
                    <input placeholder="pretraga..." type="text" value={this.state.filter} onChange={this.setFilter} onKeyDown={this.applyFilter} />
                </div>
                <div className="half" style={{fontSize: '.8em'}}>
                od: <DateTime selected={this.state.dateFrom} showTimeSelect={false} onChange={this.handleDateFrom}/>
                &nbsp;do: <DateTime selected={this.state.dateTo} showTimeSelect={false} onChange={this.handleDateTo}/>
                {/* <Select id="id" value="name" placeholder="sajt..." selected={this.state.selectedStore} options={[{id: 0, name: 'svi sajtovi'},{id: 1, name: 'Harmonija'},{id: 2, name: 'Stela'},{id: 3, name: 'Publikpraktikum'}]} onSelect={(res)=>this.setState({selectedStore: res, page: 1})} /> */}
                </div>
                <div className="quarter align-right">
                    <button onClick={this.getTickets}>Pretraži</button>
                </div>
                <div className='chart-container-in'>
                    <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                    updateArgs={[true, true, true]}/>
                </div>
               
            </div>
        )
    }

}

export default TicketsStats;
