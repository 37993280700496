import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function pad(n) {
    return (n < 10) ? ("0" + n) : n;
}

function parseDate(date){
    let res = null;
    if(date){
        let r = new Date(date);
        let month = pad(r.getMonth() + 1);
        let day = pad(r.getDate());
        res = r.getFullYear() + '-' + month + '-' + day + ' ' + pad(r.getHours()) + ':' + pad(r.getMinutes()) + ':' + pad(r.getSeconds());
    }
    return res;
}

export default function DateTime(props){

    const [myDate, setMyDate] = useState();
    const handleFromDateChange = (value) => {
        // console.log(parseDate(value));
        setMyDate(value);
        
    }

    useEffect(()=>{
        if(props.selected){
            setMyDate(new Date(props.selected));
        }
    }, [props.selected]);


    useEffect(()=>{
        if(props.onChange){
            props.onChange(parseDate(myDate));
        }
    }, [myDate]);

    return (
        // <div>test</div>
        <DatePicker
            id="fromDate"
            selected={myDate}
            onChange={handleFromDateChange}
            showYearDropdown={true}
            autoComplete="off"
            showTimeSelect={props.showTimeSelect}
            dateFormat="yyyy-MM-dd HH:mm:ss"
            timeFormat="HH:mm"
            placeholder={props.placeholder}
            
                    
        >
            <div tabindex="-1" className={props.textClass}>{props.text}</div>
        </DatePicker>
    )

}

