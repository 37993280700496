import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API = process.env.REACT_APP_API;


// function images(image){
//   return 'http://api.pp.cekaonica.com/media/news/'+ image;
// }

class News extends Component {

    constructor(props) {
    super(props);
    this.state = {
        news: [],
        title: '',
        text: '',
        storeId: 0,
        image: ''
    }

  }

  stores = {
      1: 'harmonija knjige',
      2: 'stela knjige',
      3: 'publik praktikum'
  }

  getNewsSingle=()=>{
    Service.get('/news/get',{id: this.props.match.params.id},(res)=>{
          this.setState({
                    title: res.data[0].title,
                    text: res.data[0].text,
                    storeId: res.data[0].store_id,
                    image: res.data[0].image
                }
            )
      })
  }

  deleteNews = (event) => {
    Service.get('/news/delete',{id: event.target.value},()=>{
      })
  }

  componentDidMount(){
      this.getNewsSingle();
  }

  updateTitle=(event)=>{
      this.setState({title: event.target.value})
  }
  updateText=(event)=>{
      this.setState({text: event.target.value})
  }
  updateStoreId=(event)=>{
      this.setState({storeId: event.target.value})
  }

  updateNews=()=>{
      Service.get('/news/update',{
          title: this.state.title,
          text: this.state.text,
          storeId: this.state.storeId,
          id: this.props.match.params.id,
          image: this.state.image
        },()=>{
            this.getNewsSingle();
        })
      
    
  }

  handleChange = (value) => {
    console.log(value);
    this.setState({ text: value })
  }

  modules = {
    toolbar: [
      [{ 'header': [2, 3, false] }],
      ['bold', 'italic', 'underline','strike'],
      ['link', 'image'],
      ['clean']
    ],
  }

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'link', 'image'
  ]

      onChange = e => {
    const files = Array.from(e.target.files)
    this.setState({ uploading: true })

    const formData = new FormData()

    files.forEach((file, i) => {
      formData.append(i, file)
    })

    console.log('formData:', formData);

    // fetch(`${API_URL}/image-upload`, {
    //   method: 'POST',
    //   body: formData
    // })
    // .then(res => res.json())
    // .then(images => {
    //   this.setState({ 
    //     uploading: false,
    //     images
    //   })
    // })
    // Service.get('/media/uploadNews',{
    //       formData
    //     },(res)=>{
    //         this.setState({image: res.data});
    //     })

    Service.uploadNewsImages(formData,(res)=>{
        this.setState({image: res.data});
        console.log(this.state);
    });

    // fetch(`${API}/media/uploadNews`,
    //   {
    //     method: 'post',
    //     //headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: formData
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);

    //         this.setState({image: result.data});
    //         //this.getNews();
          
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  render() {
    return (
      <div>
          <h1>Vesti</h1>
          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          <h2>Azuriranje vesti</h2>
          <h3>Sajt: {this.stores[this.state.storeId]}</h3>
          <br />
          <br />
          <img style={{height: '200px'}}  src={Service.images(`news/${this.state.image}`)} />
          <br />
          Zameni sliku:<input type='file' id='multi' name="userImage[]" onChange={this.onChange} multiple />
          <br />
          Naslov: <input type='text' value={this.state.title} onChange={this.updateTitle} />
          <br />
          <ReactQuill value={this.state.text}
                    className="text-editor"
                    modules={this.modules}
                    formats={this.formats}
                  onChange={this.handleChange} />
          {/* <textarea value={this.state.text} onChange={this.updateText}> </textarea> */}

          <button onClick={this.updateNews}>Azuriraj vest</button>

      </div>
    );
  }

}

export default News;
