//import React, { Component } from 'react';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import {Auth} from '../components/Auth';

export default function Login(props){

  const [loginName, setLoginName] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const {setLoggedIn} = useContext(AuthContext)

  const login = (event) => {

    if(event.key && event.key != "Enter"){
      return;
    }
        Auth.userName = loginName;
        
        Auth.authenticate(loginPassword, () => {
            // update auth context
            setLoggedIn(true);
            // redirect to home page home page in this case
            props.history.push('/');
        })
  }

    return (
      <div className="align-center login">
          <h1 style={{marginTop: '3em'}}>Publik Praktikum CMS</h1>
          <section className="login-form" >
                <h1>Uloguj se</h1>
                <input autoFocus={true} placeholder="Korisničko ime" value={loginName} onChange={(event)=>setLoginName(event.target.value)} />
                <input type="password" placeholder="Šifra" value={loginPassword} onChange={(event)=>setLoginPassword(event.target.value)} onKeyPress={login}/>
                <br/><button onClick={login} >Uloguj se</button>
            </section>
      </div>
    );

}

// import React, { Component } from 'react';
// import {Auth} from '../components/Auth';
// import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";



// class Login extends Component {

//     constructor(props) {
//     super(props);
//     this.state = {
//         loginName: '',
//         loginPassword: ''
//     }
//   }

//   // login
//     updateLoginName = (event) => {
//         this.setState({loginName: event.target.value});
//     }
//     updateLoginPassword = (event) => {
//         this.setState({loginPassword: event.target.value});
//     }

//   login = () => {
//         Auth.userName = this.state.loginName;
//         // Auth.password = this.state.loginPassword;
//         Auth.authenticate(this.state.loginPassword, () => {
//             // redirect to welcome page home page in this case
//             console.log('a', Auth);
//             this.props.history.push('/');
//         })
//     }

//     signout = () => {
//         Auth.signout(()=>{
//             // redirect to welcome page home page in this case
//             return <Redirect to='/'  />
//             //this.props.history.push('/');
//         });
//     }

//   render() {
//     return (
//       <div>
//           <h1>Publik Praktikum - Login</h1>
//           <div>
//                 <h1>Prijava</h1>
//                 <label>ime:</label><br />
//                 <input value={this.state.loginName} onChange={this.updateLoginName} />
//                 <br />
//                 <label>lozinka:</label><br />
//                 <input type="password" value={this.state.loginPassword} onChange={this.updateLoginPassword} />
//                 <button onClick={this.login}>Uloguj se</button>
//             </div>
//       </div>
//     );
//   }

// }

// export default Login;
