import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
import Selector from '../components/Selector';
const queryString = require('query-string');

const API = process.env.REACT_APP_API;
function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
  }
  
function pad(num, size){
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

// let parsePhone =(n)=>{
//   let cleaned = n.replace(/\D/g, '');
//   let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return match[1] + ' ' + match[2] + ' ' + match[3]
//   }else{
//     return cleaned;
//   }
//   // return null
// }

function parseDiscounts(discounts){
  let a = {};
  discounts.map(item => {

    a[item.discount_id] = true;
  })

  return a;
}

function getStoreName(storeId){
  let stores={
      1: 'harmonija', 
      2: 'stela', 
      3: 'publik'};
  return stores[storeId];
}

class Order extends Component {

    constructor(props) {
    super(props);
    this.state={
        order: [{id:1}],
        products: [],
        cart: {},
        filteredProducts: [],
        actions: [],
        adminAction: null,
        adminDiscounts: [],
        discounts: [],
        storeId: null,
        orderItems: [],
        csvData: [],
        search:'',
        perPage: 50,
        searchProducts:[],
        shippingPrice:'',
        afterCoupon:'',
        productsTotal:'',
        coupons:[],
        name:'',
        country: 'Srbija',
        address:'',
        dStreet:'',
        dStreetNumber:'',
        townCheckbox: false,
        dTown:'',
        rTownId:'',
        dCity:'',
        dZipCode:'',
        dTownLabel:'',
        telephone:'',
        telephoneDisplay: '', 
        email:'',
        note:'',
        shippingName:'',
        shippingAddress:'',
        dShippingStreet:'',
        dShippingStreetNumber:'',
        townShippingCheckbox: false,
        dShippingTown:'',
        shippingRTownId:'',
        dShippingCity:'',
        dShippingZipCode:'',
        dShippingTownLabel:'',
        shippingTelephone:'',
        shippingTelephoneDisplay:'',
        shippingEmail:'',
        nameError: 'none',
        emailError: 'none',
        addressError: 'none',
        cityError: 'none',
        telephoneError: 'none',
        dNameError: 'none',
        dShippingStreet: 'none',
        dTelephoneError: 'none',
        dStreetError: 'none',
        dCityError: 'none',
        dEmailError: 'none',
        currentIndex: null,
        adminDiscountProductId: null,
        adminDiscountProductValue: null,
        saved: false,
        freeShipping: false,
        erpPrepared: false,
        staticOrder: null,
        paymentMethod: 'pp'
    }
  }
  componentDidMount(){
    //this.updateOrder();
    this.getOrder();
    this.updateCustomerInfo();
  }

  getActions=()=>{
    Service.get('/actions/getActions', {storeId: this.state.storeId}, (res)=>{
      this.setState({actions: res.data.actions})
    })
  }
  getDiscounts=()=>{
    Service.get('/orders/getDiscounts', {storeId: this.state.storeId}, (res)=>{
      this.setState({discounts: res.discounts}, ()=>{
        this.updateCart();
      })
    })
  }

  getDiscountNames=()=>{
    let names = [];
    let added = [];
    
    for(var p in this.state.adminDiscounts){
      added.push(parseInt(p));
    }
    console.log('ad', added);
    this.state.discounts.map(item =>{
      if(added.includes(item.id)){
        names.push(item.name);
      }
    })

    return names;
  }

  toggleFreeShipping=()=>{
    this.setState({freeShipping: !this.state.freeShipping}, ()=>{
      this.updateCart();
    });
  }

  toggleDiscount=(event)=>{
    let discounts = this.state.adminDiscounts;
    let id = event.target.getAttribute('data-discount-id');

    if(discounts[id]){
      delete discounts[id];
    }else{
      discounts[id] = true;
    }

    

    this.setState({adminDiscounts: discounts}, ()=>{
      this.updateCart();
    })
  }

  // checkDiscount=(id)=>{

  //   this.state.adminDiscounts.map(item => {
  //     console.log('ad', id, item);
  //     if(item == id){
  //       return true;
  //     }
  //   })
  //   return false;
  // }

  changeAction=(event)=>{
    this.setState({adminAction: event.target.value}, ()=>{
      this.updateCart();
    });
  }

  updateCart(cb = null) {
    let r = [];
    let products = this.state.products;
    for (var p in products) {
      r.push({ id: products[p].id, quantity: products[p].quantity });
    }
    Service.get('/orders/getCart', { products: r, coupons: this.state.coupons, storeId: this.state.storeId, adminAction: this.state.adminAction, discounts: this.getDiscountNames(), adminDiscounts: this.state.adminProductDiscounts, freeShipping: this.state.freeShipping, paymentMethod: this.state.paymentMethod }, (res) => {

        console.log(res.data);
        this.setState({cart: res.data}, ()=>{
          let getParams = queryString.parse(this.props.location.search);
          if(getParams.save && !this.state.saved){
            // save order
            this.saveOrder();
            this.setState({saved: true});
          }
        });
      if (cb) {
        cb('cart callback');
      }
    });

  }


  getOrder=()=>{
    Service.get("/orders/single", {id: this.props.match.params.id}, (res)=>{
      this.setState({
        staticOrder: res.data.orderStatic,
        order:res.data.order, 
        products:res.data.order.products,
        orderItems: res.data.order.orderItems,
        coupons: res.data.appliedCoupons,
        storeId: res.data.order.store_id,
        adminAction: res.data.appliedAction,
        freeShipping: res.data.order.free_shipping,
        adminDiscounts: parseDiscounts(res.data.appliedDiscounts),
        adminProductDiscounts: res.data.appliedAdminDiscounts,
        shippingTelephoneDisplay: this.formatTelephone(res.data.order.shipping_phone),
        telephoneDisplay: this.formatTelephone(res.data.order.phone),
        paymentMethod: res.data.order.payment_method
      },()=>{
          console.log("order", this.state.order)
          this.getActions();
          this.getDiscounts();
          this.updateCart();
          

        })
    });
  }

  updateOrder=()=>{
    Service.get("/orders/single", {id: this.props.match.params.id}, (res)=>{
      this.setState({
        order:res.data.order, 
        products:res.data.order.products,
        orderItems: res.data.order.orderItems,
        coupons: res.data.appliedCoupons,
        shippingTelephoneDisplay: this.formatTelephone(res.data.order.shipping_phone),
        telephoneDisplay: this.formatTelephone(res.data.order.phone),
      },()=>{
          console.log("order", this.state.order)
        })
    });

  }

  updateCustomerInfo=()=>{
    Service.get("/orders/single", {id: this.props.match.params.id}, (res)=>{
      this.setState({
        order:res.data.order, 
        // products:res.data.order.products,
        coupons: res.data.appliedCoupons,
        name: res.data.order.name,
        address: res.data.order.billing_address,
        dTown: res.data.order.city,
        dCity: res.data.order.municipality,
        dZipCode: res.data.order.zip_code,
        dTownLabel: res.data.order.city+" "+res.data.order.municipality+" "+res.data.order.zip_code,
        telephone: res.data.order.phone,
        email: res.data.order.email,
        note: res.data.order.note,
        shippingName: res.data.order.shipping_name,
        shippingAddress: res.data.order.shipping_address,
        dShippingTown: res.data.order.shipping_city,
        dShippingCity: res.data.order.shipping_municipality,
        dShippingZipCode: res.data.order.shipping_zip_code,
        dShippingTownLabel: res.data.order.shipping_city+" "+res.data.order.shipping_municipality+" "+res.data.order.shipping_zip_code,
        shippingTelephone: res.data.order.shipping_phone,
        shippingEmail: res.data.order.shipping_email,
        rTownId: res.data.order.r_town_id,
        shippingRTownId: res.data.order.shipping_r_town_id
      },()=>{
        this.setStreet();
        this.setShippingStreet();
        })
    });

  }

  pad = (num, size) => {
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

changeQuantity = (event) => {
    let productId = event.target.getAttribute('data-order-product-id');
    let direction = event.target.getAttribute('data-direction');
    let p = this.state.products;
    for(var i in p){
      if(p[i].id == productId){
        if(direction == 'add'){
          p[i].quantity ++;
          break;
        }
        if(direction == 'remove'){
          if(p[i].quantity > 1){
            p[i].quantity --;
            break;
          }
        }
      }
    }

    this.setState({products: p}, ()=>{
      this.updateCart();
    })
    // Service.get("/orders/changeProductQuantity", {productId: productId, orderId: this.state.order.id, direction: direction},(res)=>{
    //     if(res.success){
    //         this.updateOrder();
    //     }
    // });
}

changeProductDiscount=(event)=>{

  let amount = event.target.value.replace(/[^\d.-]/g, '');
  if(event.key != 'Enter'){
    console.log(amount);
    this.setState({adminDiscountProductValue: amount});
  }else{
    console.log(event.target.value, event.target.getAttribute('data-product-id'))
    let productId = event.target.getAttribute('data-product-id');
    
    let a = this.state.adminProductDiscounts;
    let found = false;
    a.map(item => {
      if(item.product_id == productId){
        item.amount = this.state.adminDiscountProductValue;
        found = true;
      }
    });

    if(!found){
      console.log('t', productId, )
      a.push({product_id: this.state.adminDiscountProductId, amount: this.state.adminDiscountProductValue});
      found = true;
    }
    this.setState({adminProductDiscounts: a, adminDiscountProductId: null, adminDiscountProductValue: null}, ()=>{
      this.updateCart();
    })
    event.target.blur();
  } 
}

removeProduct = (event) => {
  let productId = event.target.getAttribute('data-order-product-id');
  let p = this.state.products.filter(item => item.id != productId);
    this.setState({products: p}, ()=>{
      this.updateCart();
    })
}

addProduct=(productId)=>{
    let p = this.state.products;
    for(var i in p){
      if(p[i].id == productId){
        alert("Proizvod je vec unet.");
        return;
      }
    }
    p.push({id: productId, quantity: 1});
    this.setState({products: p, filteredProducts: [], search: null}, ()=>{
      this.updateCart();
    })
}

saveOrder=()=>{
  let productData=[];
  let couponsId=[];
  this.state.products.map((item)=>{
    productData.push({productId: item.id, quantity: item.quantity})
  })

  if(this.state.coupons){
    this.state.coupons.map((item)=>{
      couponsId.push(item.id)
    })
  }

  let checked = true;
  // Billing data error message
    if(!this.state.name){
        this.setState({nameError: 'block'});
        checked = false;
    }else{
        this.setState({nameError: 'none'});
    }

    if(!this.state.dStreet){
        this.setState({addressError: 'block'});
        checked = false;
    }else{
        this.setState({addressError: 'none'});
    }

    if(!this.state.dTown){
        this.setState({cityError: 'block'});
        checked = false;
    }else{
        this.setState({cityError: 'none'});
    }

    if(!this.state.email){
        this.setState({emailError: 'block'});
        checked = false;
    }else{
        this.setState({emailError: 'none'});
    }

    if(!this.state.telephone || this.state.telephone.length < 8){
        this.setState({telephoneError: 'block'});
        checked = false;
    }else{
        this.setState({telephoneError: 'none'});
    }

    // Shipping data error message
    if(!this.state.shippingName){
      this.setState({dNameError: 'block'});
      checked = false;
    }else{
      this.setState({dNameError: 'none'});
    }
    if(!this.state.dShippingStreet){
      this.setState({dStreetError: 'block'});
      checked = false;
    }else{
      this.setState({dStreetError: 'none'});
    }
    if(!this.state.dShippingTown){
      this.setState({dCityError: 'block'});
      checked = false;
    }else{
      this.setState({dCityError: 'none'});
    }
    if(!this.state.shippingTelephone || this.state.shippingTelephone.length < 8){
      this.setState({dTelephoneError: 'block'});
      checked = false;
    }else{
      this.setState({dTelephoneError: 'none'});
    }
    if(!this.state.shippingEmail){
      this.setState({dEmailError: 'block'});
      checked = false;
    }else{
      this.setState({dEmailError: 'none'});
    }
  if(!checked){
    return; 
  }

  Service.get("/orders/update", {
    orderId: this.state.order.id,
    email: this.state.email,
    name: this.state.name,
    city: this.state.dTown,
    zipCode: this.state.dZipCode,
    billingAddress: this.state.dStreet + " " + this.state.dStreetNumber,
    street: this.state.dStreet,
    streetNumber: this.state.dStreetNumber,
    shippingAddress: this.state.dShippingStreet + " " + this.state.dShippingStreetNumber,
    shippingStreet: this.state.dShippingStreet,
    shippingStreetNumber: this.state.dShippingStreetNumber,
    shippingName: this.state.shippingName,
    shippingCountry: this.state.country,
    shippingCity: this.state.dShippingTown,
    shippingMunicipality: this.state.dShippingCity,
    shippingPhone: this.state.shippingTelephone,
    shippingZipCode: this.state.dShippingZipCode,
    country: this.state.country,
    municipality: this.state.dCity,
    phone: this.state.telephone,
    status: this.state.order.status,
    deliveryStatus: this.state.order.delivery_status,
    paymentMethod: this.state.order.payment_method,
    shippingMethod: this.state.order.shipping_method,
    source: this.state.order.source,
    note: this.state.note,
    products: this.state.cart.products,
    coupons: couponsId,
    rTownId: this.state.rTownId,
    shippingRTownId: this.state.shippingRTownId,
    total: this.state.cart.total,
    shippingPrice: this.state.cart.shippingPrice,
    subtotal: this.state.cart.subtotal,
    freeShipping: this.state.freeShipping

    
  }, (res)=>{
      if(res.success){
        alert("Uspešno ste uneli podatke");
        this.updateCustomerInfo();
      }else{
        alert("Neuspešan unos podataka, molimo pokušajte ponovo");
      }
  });
}


changeSearch = (event) => {

  //this.setState({search: event.target.value});
  //if(event.key === 'Enter'){
    this.setState({search: event.target.value});
    if(!event.target.value){
      this.setState({filteredProducts: [], currentIndex: null});
      return;
    }
    Service.get('/products/getByStore', {filter: event.target.value, storeId: this.state.storeId}, (res)=>{
      if(res.success){
        this.setState({filteredProducts: res.products, currentIndex: null});
      }
      
    })
//}
}

searchProducts = (event) => {
    if(event.key === 'Enter'){
        this.setState({search: event.target.value});
        Service.get('/products/getByStore', {filter: event.target.value, storeId: 1}, (res)=>{
          if(res.success){
            this.setState({filteredProducts: res.products});
          }
          
        })
    }
}

// Edit customer info
setName=(event)=>{
  let dataType = event.target.getAttribute('data-type');
  if(dataType === 'shipping'){
    this.setState({shippingName: event.target.value.replace(/[^A-Za-zŠĐŽĆČŽšđčćž\s!?]/g,'')})
  }else{
    this.setState({name: event.target.value.replace(/[^A-Za-zŠĐŽĆČŽšđčćž\s!?]/g,'')})
  }
}

setAddress=(data)=>{
  this.setState({dStreet: data[0].value.replace(/[^0-9a-zA-Z.ŠĐŽĆČŽšđčćž\s]/g,'')},()=>{
  })
}

setShippingAddress=(data)=>{
  this.setState({dShippingStreet: data[0].value.replace(/[^0-9a-zA-Z.ŠĐŽĆČŽšđčćž\s]/g,'')},()=>{
  })
}

setStreet=()=>{
  let street = this.state.address.substring(0, this.state.address.lastIndexOf(" ") + 1);
  let sv = street.replace(/\s+$/, '');
  let streetNo = this.state.address.substring(this.state.address.lastIndexOf(" ") + 1, this.state.address.length);
  this.setState({dStreet: sv})
  this.setState({dStreetNumber: streetNo})
}

setShippingStreet=()=>{
  let street = this.state.shippingAddress.substring(0, this.state.shippingAddress.lastIndexOf(" ") + 1);
  let sv = street.replace(/\s+$/, '');
  let streetNo = this.state.shippingAddress.substring(this.state.shippingAddress.lastIndexOf(" ") + 1, this.state.shippingAddress.length);
  this.setState({dShippingStreet: sv})
  this.setState({dShippingStreetNumber: streetNo})
}

setStreetNumber=(event)=>{
  let dataType = event.target.getAttribute('data-type');
  if(dataType === 'shipping'){
    this.setState({dShippingStreetNumber: event.target.value.replace(/[^0-9a-zA-Z\/ ŠĐŽĆČŽšđčćž\s]/g,'')})
  }else{
    this.setState({dStreetNumber: event.target.value.replace(/[^0-9a-zA-Z\/ ŠĐŽĆČŽšđčćž\s]/g,'')})
  }
  
}

handleTownCheckbox = event => {
  let dataType = event.target.getAttribute('data-type');
  if(dataType === 'shipping'){
    this.setState({ townShippingCheckbox: event.target.checked })
  }else{
    this.setState({ townCheckbox: event.target.checked })
  }
}

handlePhoneNumber=(event)=>{
  let dataType = event.target.getAttribute('data-type');
  if(event.target.value === "0"){
    if(dataType === 'shipping'){
      this.setState({shippingTelephone: '', shippingTelephoneDisplay: ''})
    }else{
      this.setState({telephone: '', telephoneDisplay: ''})
    } 
  }else if(event.target.value.length < 14){
      let p = event.target.value.replace(/[^0-9.]/g, '');
      if(dataType === 'shipping'){
        this.setState({shippingTelephone: p, shippingTelephoneDisplay: this.formatTelephone(p)})
      }else{
        this.setState({telephone: p, telephoneDisplay: this.formatTelephone(p)})
      } 
  }
}

formatTelephone(phone){
  let p = phone.toString();
  let rest = p.substring(6);
  let second = p.substring(2,6);
  let first = p.substring(0,2);
    
  // console.log('tel', second, rest, phone)
  if(p.length < 3){
      return first;
  }else if(p.length < 7){
      return '(' + first + ') ' + second;
  }else{
      return '(' + first + ') ' + second + ' ' + rest;
  }
}

handleInput=(event)=>{
  this.setState({[event.target.id]: event.target.value})
}

handleNoteChange = (event, type) => {
  let alphaNumNote = event.target.value.replace(/[^0-9a-zA-Z\-ŠĐŽĆČŽšđčćž\s]/g,'');
  this.setState({note: alphaNumNote});
}

setTown=(data)=>{
  this.setState({
      dTown: data[0].town_name,
      dCity: data[0].city_name,
      dZipCode: data[0].ptt_no,
      dTownLabel: data[0].label,
      rTownId: data[0].town_id
  },()=>{
  })
}

setShippingTown=(data)=>{
  this.setState({
      dShippingTown: data[0].town_name,
      dShippingCity: data[0].city_name,
      dShippingZipCode: data[0].ptt_no,
      dShippingTownLabel: data[0].label,
      shippingRTownId: data[0].town_id
  },()=>{
  })
}

changePaymentMethod=()=>{
    if (window.confirm('Ova akcija se ne može poništiti! Da li si siguran/na da želis da promeniš način plaćanja?')) {
        console.log(this.state.order.id);
        Service.get('/orders/changePaymentMethod', {orderId: this.state.order.id}, (res)=>{
            if(res.success){
                this.updateOrder();
                alert('Način plaćanja je uspešno promenjen.');
            }else{
                alert('Došlo je do greške!');
            }
        })
    } else {
        //canceled, do nothing
    
    }
}

selectProductArrow=(event)=>{
        
  let index = this.state.currentIndex;
  if(event.keyCode == 38){
      console.log('arrow', index);
      if(index > 0 && index != null){
          index --;
      }
      if(!index){
          index = 0;
      }
      this.setState({currentIndex: index})
  }
  if(event.keyCode == 40){
      if(index < this.state.filteredProducts.length && index != null){
          index ++;
      }
      if(!index){
          index = 0;
      }
      this.setState({currentIndex: index})
  }

  if(event.keyCode == 13){
      let product = this.state.filteredProducts[index];
      if(product){
        this.addProduct(product.id);
        this.setState({search: ''})
      }
  } 
}

setAdminDiscountProductId=(event)=>{
  
  this.setState({adminDiscountProductId: event.target.getAttribute('data-product-id')})

}

sendOrderToErp=(event)=>{
  let orderId = event.target.getAttribute('data-order-id');
  if (window.confirm("Da li ste OBRISALI porudzbinu u ERP-u? Ova akcija ce vratiti gresku ako postoji porudzbenica pod istim brojem u ERP-u.")) {
    Service.syncErpOrders(orderId, (res)=>{
      console.log(res);
      if(res.success){
          alert(`porudzbina ${orderId} je uspesno poslata u ERP`);
          this.setState({erpPrepared: false});
      }else{
          alert(res.error);
      }
    });
  }
}

prepareErpSend=()=>{
  // var r = confirm("Press a button!");
  if (window.confirm("Da li ste sigurni da zelite da vratite ERP status porudzbine na NEPOSLATO?")) {
    Service.get('/orders/prepareOrderErpResend', {orderId: this.props.match.params.id}, (res)=>{
      if(res.success){
        this.setState({erpPrepared: true});
      } 
    })
  }
}


  render() {
    return (
      <div className="edit-order">
          <h1>Porudžbina <span style={{fontSize: '.8em'}}>#{this.state.order.id}</span></h1>
            <h2>{getStoreName(this.state.order.store_id)}</h2>
            <p>Kod: {this.state.order.code}</p>

            <p>Nacin Plaćanja: {this.state.order.payment_method == 'cc' ? 'Kreditna kartica' : 'Pouzećem'} </p>
            {this.state.order.payment_method == 'cc' &&
                <div>
                <button onClick={this.changePaymentMethod}>Promeni način plaćanja</button>
                </div>
            }

          <div className="billing-order">
            <div className="order-inputs">
              <h3>Ime i prezime</h3>
              <input type="text" id='name' value={this.state.name} onChange={this.setName} />
              <p className="error" style={{display: this.state.nameError}}>* morate da unesete ime i prezime.</p>

              <div className="cart-street-box">
                  <h3>Adresa</h3>
                  <Selector onChange={this.setAddress} route='/orders/getStreets' inputValue={this.state.dStreet} forced={true} type="single"/>
                  <p className="error" style={{display: this.state.addressError}}>* morate da unesete adresu.</p>
              </div>

              <div className="cart-streetNum-box">
                  <h3>Broj</h3>
                  <input type="text" id='dStreetNumber' value={this.state.dStreetNumber} onChange={this.setStreetNumber} />
              </div>
            
              {!this.state.townCheckbox &&
                <Fragment>
                  <h3>Grad</h3>
                  <Selector onChange={this.setTown} route='/orders/getTowns' filters={{street: this.state.dStreet}} inputValue={this.state.dTownLabel} type="single"/>
                </Fragment>
              }

              {this.state.townCheckbox &&
                <Fragment>
                  <h3>Drugo mesto</h3>
                  <Selector onChange={this.setTown} route='/orders/getAllTowns' inputValue={this.state.dTownLabel} type="single"/>
                </Fragment>
              }
              <div className="town-checkbox">
                  <input id='tcb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle', width:'5%'}} value={this.state.townCheckbox} onChange={this.handleTownCheckbox} />
                  <label htmlFor="tcb" style={{display: 'inline-block', verticalAlign: 'middle'}}> Izaberite drugo mesto</label>
              </div>
              <p className="error" style={{display: this.state.cityError}}>* morate da odaberete drugo mesto.</p>
            </div>

            <div className="order-inputs">
              <h3>Država</h3>
              <input type="text" id='country' value='Srbija' readOnly/>

              <h3>Broj telefona</h3>
              <div className="telephone-area-code">
                <input type="text" value="+381" readOnly/>
              </div>
              <div className="telephone-number">
                <input type="text" id='telephone' value={this.state.telephoneDisplay} onChange={this.handlePhoneNumber} maxLength="20" />
                <p className="error" style={{display: this.state.telephoneError}}>* morate da unesete broj telefona.</p>
              </div>
              <h3>Email</h3>
              <input type="text" id='email' value={this.state.email} onChange={this.handleInput}/>
              <p className="error" style={{display: this.state.emailError}}>* morate da unesete email.</p>
            </div>

            <div className="order-inputs">
              <h3>Napomena</h3>
              <textarea className="edit-order-note" placeholder="Napišite napomenu u za isporuku..." value={this.state.note} onChange={this.handleNoteChange} maxLength="249"></textarea>
            </div>
          </div>

          <div className="shipping-order">
            <div className="order-inputs">
              <h3>Ime i prezime isporuke</h3>
              <input type="text" id='shippingName' value={this.state.shippingName} data-type={'shipping'} onChange={this.setName} />
              <p className="error" style={{display: this.state.dNameError}}>* morate da unesete ime i prezime.</p>

              <div className="cart-street-box">
                  <h3>Adresa isporuke</h3>
                  <Selector onChange={this.setShippingAddress} route='/orders/getStreets' inputValue={this.state.dShippingStreet} forced={true} type="single"/>
                  <p className="error" style={{display: this.state.dStreetError}}>* morate da unesete adresu.</p>
              </div>

              <div className="cart-streetNum-box">
                  <h3>Broj</h3>
                  <input type="text" id='dShippingStreetNumber' value={this.state.dShippingStreetNumber} data-type={'shipping'} onChange={this.setStreetNumber} />
              </div>
            
              {!this.state.townShippingCheckbox &&
                <Fragment>
                  <h3>Grad isporuke</h3>
                  <Selector onChange={this.setShippingTown} route='/orders/getTowns' filters={{street: this.state.dShippingStreet}} inputValue={this.state.dShippingTownLabel} type="single"/>
                </Fragment>
              }

              {this.state.townShippingCheckbox &&
                <Fragment>
                  <h3>Drugo mesto</h3>
                  <Selector onChange={this.setShippingTown} route='/orders/getAllTowns' inputValue={this.state.dShippingTownLabel} type="single"/>
                </Fragment>
              }
              <div className="town-checkbox">
                  <input id='tcb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle', width:'5%'}} value={this.state.townShippingCheckbox} data-type={'shipping'} onChange={this.handleTownCheckbox} />
                  <label htmlFor="tcb" style={{display: 'inline-block', verticalAlign: 'middle'}}> Izaberite drugo mesto</label>
              </div>
              <p className="error" style={{display: this.state.dCityError}}>* morate da odaberete drugo mesto.</p>

            </div>

            <div className="order-inputs">
              <h3>Država isporuke</h3>
              <input type="text" id='country' value='Srbija' readOnly/>

              <h3>Broj telefona isporuke</h3>
              <div className="telephone-area-code">
                <input type="text" value="+381" readOnly/>
              </div>
              <div className="telephone-number">
                <input type="text" id='shippingTelephone' value={this.state.shippingTelephoneDisplay} data-type={'shipping'} onChange={this.handlePhoneNumber} maxLength="20" />
                <p className="error" style={{display: this.state.dTelephoneError}}>* morate da unesete broj telefona.</p>
              </div>
              <h3>Email isporuke</h3>
              <input type="text" id='shippingEmail' value={this.state.shippingEmail} onChange={this.handleInput}/>
              <p className="error" style={{display: this.state.dEmailError}}>* morate da unesete broj email.</p>

            </div>
          </div>

          <div className='order-fixed'>
            <h2>Trenutni podaci</h2>
            {this.state.staticOrder &&
            <table className='cms-table'>
                  <tr>
                    <th>ID</th>
                    <th>Naslov</th>
                    <th>Puna cena</th>
                    <th>Jedinicna Cena</th>
                    <th>Količina</th>
                    <th>Popust</th>
                    <th>Cena</th>
                    
                  </tr>
              {this.state.staticOrder.products.map(item => {
                return(
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td class="align-right" style={{color: '#bbb'}}>{item.full_price} RSD</td>
                    <td class="align-right">{item.unit_price} RSD</td>
                    <td>{item.quantity} </td>
                    <td class="align-right">{item.discount} RSD</td>
                    <td class="align-right"><b>{item.price}</b> RSD</td>
                    

                  </tr>
                )
              })}

                  <tr>
                    <td colspan="5">-</td>
                    <td>Zbir</td>
                    <td class="align-right"><b>{Service.price(this.state.staticOrder.subtotal)}</b></td>
                  </tr>
                  <tr>
                    <td colspan="5">-</td>
                    <td>Isporuka</td>
                    <td class="align-right"><b>{Service.price(this.state.staticOrder.shipping_price)}</b></td>
                  </tr>
                  <tr>
                    <td colspan="5">-</td>
                    <td><b>Total</b></td>
                    <td class="align-right"><b>{Service.price(this.state.staticOrder.total)}</b></td>
                  </tr>
              </table>
              }
          </div>

          <div className="order-actions">
            <h3>Dodaj akcije {this.state.adminAction}</h3>
            <select value={this.state.adminAction} onChange={this.changeAction}>
              <option value={0}>Bez dodate akcije</option>
              {this.state.actions.map(item => {
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
          
          <div className="order-discounts">
            <h3>Popusti</h3>
              {this.state.discounts.map(item => {
                return(
                  <div>
                    <input data-discount-id={item.id} onChange={this.toggleDiscount} type="checkbox" id={`discount-${item.id}`} name={`discount-${item.id}`} checked={this.state.adminDiscounts[item.id]} />
                    <label for={`discount-${item.id}`}>{item.description}</label>
                  </div>
                )
              })}

          </div>
          {this.state.coupons &&
            <div style={{border: '1px solid #ccc', padding: '.5em', marginTop: '.5em'}}>
              <b>dodati kuponi:</b> {this.state.coupons.toString()}
            </div>
          }

          <div style={{marginTop: '1em', marginBottom: '1em'}}>
            <input onChange={this.toggleFreeShipping} type="checkbox" id="free-shipping" name="free-shipping" checked={this.state.freeShipping} />
                    <label for="free-shipping">Besplatna isporuka</label>
          </div>
        
          {/* Products */}
          <div className="edit-products-container">
            <div className="edit-order-products">
            {/* <div> */}
              <h3>Sadržaj porudžbenice</h3>
                  <table className="cms-table">
                      <thead>
                        <tr>
                          <th>Br.</th>
                          <th>Naziv</th>
                          <th>Količina</th>
                          <th>Cena</th>
                          <th>Iznos</th>
                          <th>Popust Umanjenje</th>
                          <th>Akcija</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cart.products && this.state.cart.products.map(function(item, index){
                            return(
                                <tr>
                                    <td>{index + 1}.</td>
                                    <td>{item.title}
                                      {item.appliedDiscounts &&
                                        <div className='order-item-applied-discounts' >{item.appliedDiscounts.map(discount => {
                                          return(
                                            <span>{discount.description}</span>
                                            )
                                        })}</div>
                                      }
                                    </td>  
                                    <td>
                                        <button onClick={this.changeQuantity} data-direction={'remove'} data-order-product-id={item.id}>-</button>
                                          {item.quantity}
                                        <button onClick={this.changeQuantity} data-direction={'add'} data-order-product-id={item.id}>+</button>
                                    </td>
                                    <td>{item.price} RSD</td>
                                    <td>{item.total} RSD</td>
                                    
                                    {this.state.adminDiscountProductId == item.id ? (
                                        <td  >
                                          <input placeholder={item.adminDiscount} autoFocus onChange={this.changeProductDiscount} onKeyPress={this.changeProductDiscount} data-product-id={item.id} value={this.state.adminDiscountProductValue}/>
                                          </td>):(<td className="hover-td" data-product-id={item.id} onClick={this.setAdminDiscountProductId} >{item.adminDiscount ? item.adminDiscount + ' RSD' : "-"}</td>)
                                    }
                                      
                                    <td>
                                      <img style={{cursor: 'pointer'}} type="image" src="/delete-bin.png" onClick={this.removeProduct} width="32" data-order-product-id={item.id} />
                                    </td>
                                </tr>
                        
                              )
                            },this)
                        }
                        {/* {parseFloat(this.state.cart.shippingPrice) > 0 && */}
                          <tr>
                          <td>-.</td>
                          <td colSpan='2'>Poštarina</td>
                          <td>{this.state.cart.shippingPrice} RSD</td>
                          <td>{this.state.cart.shippingPrice} RSD</td>
                          <td colSpan='2'></td>
                        </tr>
                        {/* } */}
                        <tr>
                          <td colSpan='3' rowspan="2" style={{background: "white"}}></td>
                          <td>Zbir</td>
                          <td colSpan='3'>{this.state.cart.subtotal} RSD</td>
                        </tr>
                        <tr>
                          {/* <td colSpan='4'></td> */}
                          <td><b>Za uplatu</b></td>
                          <td colSpan='3'><b>{this.state.cart.total} RSD</b></td>
                        </tr>
                      </tbody>
                </table>
                <div className="edit-search-products">
                
                  <input type='text' value={this.state.search} onKeyDown={this.selectProductArrow} onChange={this.changeSearch}  placeholder="Pretrazi..."/>
                  {this.state.filteredProducts.map(function(item, index){
                  return(
                      <div className={index == this.state.currentIndex ? "product-container selected-product-order" : "product-container"} key={index} >
                                <span>{item.title}</span>
                                <button onClick={()=>{this.addProduct(item.id)}}>Dodaj</button>
                      </div>
                      );   
                  },this)}
                </div>
            </div>

            
            {/* <div className='order-totals'>
              <h3>Total</h3>
                      <p>Ukupno {this.state.cart.subtotal} RSD</p>
                      {this.state.coupons.map(function(item, index){
                              return(
                              <p>Kupon {'"'+item.description+'"'} -{item.subtracted} RSD</p>
                              )
                      },this)}
                      {parseFloat(this.state.cart.shippingPrice) > 0 &&
                          <p>Poštarina {this.state.order.shipping_price} RSD</p>
                      }
                      <p style={{fontSize: '1.2em'}}>Ukupno za uplatu <b>{this.state.cart.total} RSD</b></p>
                      
                </div>*/}
          </div> 
          <div className="align-center" style={{marginBottom: "3em"}}>
            <button style={{fontSize: "1.5em"}} onClick={this.saveOrder} >Snimi promene</button>
          </div>
          
          <div>
          <button onClick={this.prepareErpSend} >Pripremi za ERP</button>
            {this.state.erpPrepared &&
              <button onClick={this.sendOrderToErp} data-order-id={this.props.match.params.id} >&gt;&gt; ERP</button>
            }
          </div>
          
          
      </div>
    );
  }

}

export default Order;
