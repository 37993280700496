import Service from '../components/Service';
import {LS} from '../components/LS';
import duix from 'duix';


//order_administrator, products_administrator
const permissions = {
    ['/news']: ['menage_news'],
    ['/statistics']: ['menage_statistics'],
    ['/chat']: ['manage_chat'],
    ['/orders']: ['manage_orders'],
    ['/products']: ['manage_products'],
    ['/warehouse']: ['manage_warehouses'],
    ['/document']: ['manage_documents'],
    ['/admins']: ['manage_administrators'],
    ['/misc']: ['manage_misc'],
    ['/krcko']: ['manage_krcko'],
    ['/fiscalisation']: ['manage_fiscalisation'],
    ['/statistics']: ['manage_statistics'],
}

export const Auth = {

    isAuthenticated: false,
    userName: false,
    token: false,
    role: null,
    permissions: [],

    authenticate(password, cb) {
        Service.get('/users/login',{userName: this.userName, password: password},(res)=>{
            if(res.success){

        		this.isAuthenticated = true;
                this.token = res.data.token;
                this.id = res.data.id;
                this.userName = res.data.userName;
                this.ted = res.data.ted;
                this.role = res.data.role;
                this.permissions = res.data.permissions;

                // localStorage.setItem('token', res.data.token);
                // localStorage.setItem('id', res.data.id);
                // localStorage.setItem('userName', res.data.userName);
                // localStorage.setItem('ted', res.data.ted);
                // localStorage.setItem('role', res.data.role);

                LS.set({Auth: {
                    token: res.data.token,
                    id: res.data.id,
                    userName: res.data.userName,
                    ted: res.data.ted,
                    role: res.data.role,
                    permissions: res.data.permissions
                }})
                
        		duix.set('loggedIn', true);
        		setTimeout(cb, 100);
        		
        	}else{
        		this.isAuthenticated = false;
        	}
        }) 	
    },

    checkLoginStatus(){

        

        if(LS.data.Auth){
            let ted = LS.data.Auth.ted;
            let now = new Date(); 
            let time = Math.round(now.getTime() / 1000);
            if(time < ted - 3600){

                this.isAuthenticated = true;
                this.token = LS.data.Auth.token;
                this.ted = LS.data.Auth.ted;
                this.id = LS.data.Auth.id;
                this.userName = LS.data.Auth.userName;
                this.role = LS.data.Auth.role;
                this.permissions = LS.data.Auth.permissions;
            }else{
                this.isAuthenticated = false; 
            }
        }else{
            this.isAuthenticated = false;
        }
   },

    logout(cb) {
        Service.get('/users/logout',{userName: this.userName, token: this.token},()=>{
            this.signout(cb);
            });
    },

  
    signout(cb) {
        //this.logout();
        this.isAuthenticated = false
        // localStorage.removeItem('token');
        // localStorage.removeItem('id');
        // localStorage.removeItem('userName');
        // localStorage.removeItem('role');

        LS.set({Auth: null});
        window.location.replace("/login");
        window.location.reload();
        //setTimeout(cb, 100)
    },

    

    

    checkPermission(route){
        // if(route != '/orders'){
        //     return true;
        // }
        if(this.role == 'superadmin') return true;
        let found = false;
        if(permissions[route]){
            //if(permissions[route].includes(this.permisss)){
                permissions[route].map(rp => {
                    this.permissions.map(item => {
                        if(rp == item){
                            found = true;
                        }
                    }, this)
                }, this);

            if(found){
                return true;
            }
            //return true;
            //}
            //return false;
        }else{
            return true;
        }
    }
}