import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import BarcodeReader from 'react-barcode-reader'



class Barcode extends Component {

    constructor(props) {
    super(props);
    this.state = {
        result: 'no data'
    }
  }

  handleScan=(data)=>{
    this.setState({
      result: data,
    })
  }
  handleError=(err)=>{
    console.error(err)
  }

  render() {
    return (
      <div>
          <h1>Barcode</h1>
          <div>
                <p>{this.state.result}</p>
                <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScan}
                    />
                <p>{this.state.result}</p>
            </div>
      </div>
    );
  }

}

export default Barcode;
