import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

const API = process.env.REACT_APP_API;

const messages = {
  success: 'Slike su uspesno dodate.'
};

export default class FileUploadComponent extends Component
{
  constructor(props){
    super(props);
    this.state = {
      image: ''
    }
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }
  onFormSubmit(e){
    e.preventDefault()
    this.fileUpload(this.state.image);
  }
  onChange(e){
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    this.createImage(files[0]);
  }
  createImage(file){
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result
      })
    };
    reader.readAsDataURL(file);
  }


    
    fileUpload(image){

      const formData = new FormData()

      image.forEach((image, i) => {
            formData.append(i, image)
        })
      console.log(this.state.image);

      Service.uploadProductImages(formData);
    //   Service.get('/media/upload',{images: formData},(res)=>{
    //     //   this.updateCategories();
    //   })
    //   fetch(`${API}/media/upload`, {
    //     method: 'post',
    //     headers: {'content-type': 'multipart/form-data'},
    //     data: formData,
    //     body: JSON.stringify({
    //         Auth,
    //       images: formData
    //     })
    //   })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
    }
  
   render()
   {
      return(
        <div>
          <p>{messages[this.props.match.params.status]}</p>
        
        <form action={`${Service.api}/media/upload`} method="post" encType="multipart/form-data">
            <label htmlFor="file">Filename:</label>
            <input type="file" name="userImage[]" id="file" multiple/><br />
            <input type="submit" name="submit" value="Submit" />
        </form>
        </div>
      )
   }
}