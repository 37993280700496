import React, { Component } from 'react';
import Service from '../components/Service';
import ReactExport from 'react-export-excel';
import Select from '../components/Select';
import DateTime from '../components/DateTime';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let stores = [
    {id: 1, name: "Harmonija"},
    {id: 2, name: "Stela"},
    {id: 3, name: "Publikpraktikum"}
]

class Export extends Component {
    constructor(){
        super();
        this.state={
            storeId:3,
            storeName: 'Publikpraktikum',
            excelData:[],
            excelDataPayed: [],
            products:[],
            dateFrom: null,
            payedVisible: false
        }
    }

    store = {
        1: "Harmonija",
        3: "Publikpraktikum"
    }

    componentDidMount(){
        this.getProductsByStoreId();
    }

    getProductsByStoreId=()=>{
        Service.get('/products/export',{
            storeId: this.state.storeId
        },(res)=>{
            this.setState({products: res.data.products},()=>{
                this.getExcelData();
            }) 
        })
    }

    getPayedProducts=()=>{
        Service.get('/products/payed',{
            storeId: this.state.storeId,
            dateFrom: this.state.dateFrom
        },(res)=>{
            if(res.success){
                this.setState({payedProducts: res.products, payedVisible: true},()=>{
                        this.getPayedExcelData();       
                })
            } 
        })
    }

    getPayedExcelData=()=>{
        let productsPayedExcel = [];
        this.state.payedProducts.map((item)=>{
            productsPayedExcel.push({
                id: item.id, 
                sku: item.sku, 
                quantity: item.quantity, 
                title: item.title})
        });

        this.setState({excelDataPayed: productsPayedExcel})
    }

    getExcelData=()=>{
        let productsExcel = [];
        this.state.products.map((item)=>{
            let categories = [];
            item.categories.map(cat => {
                categories.push(cat.name);
            })
            productsExcel.push({
                id: item.id, 
                description: item.description, 
                image: item.image, 
                dimension: item.dimension, 
                title: item.title,
                isbn: item.isbn,
                price: item.price,
                full_price: item.full_price,
                number_of_pages: item.number_of_pages,
                sku: item.sku,
                supplier: item.supplier,
                translator: item.translator,
                weight: item.weight,
                author: item.author,
                binding: item.binding,
                letter: item.letter,
                categories: categories.join(', '),
                is_package: item.is_package
            });
        })

        this.setState({excelData: productsExcel})
    }

    changeStore = (id) => {
        let storeName = '';
        stores.map(item => {
            if(item.id == id){
                storeName = item.name;
            }
        })
        this.setState({storeId: id, storeName: storeName, payedVisible: false },()=> {
            this.getProductsByStoreId();
            this.getPayedProducts();
        })
    }

    render(){
        return (
            <div className="export-container">
                
                <Select placeholder="Odaberi shop..." id='id' value='name' debug='x' options={stores} selected={this.state.storeId} onSelect={(res) => this.changeStore(res)}/>
                <section>
                <h2>Eksport Proizvoda</h2>
                <p>Izvoz liste proizvoda za odabranu prodavnicu sa njihovim cenama, opisom i ostalim parametrima</p>
                <ExcelFile element={<button className="keyword">Export excel</button>} filename={this.state.storeId == 1 ? "Harmonija-knjige-proizvodi" 
                                                                                                    : this.state.storeId == 3 ? "Publik-praktikum-proizvodi" 
                                                                                                    : "Proizvodi"}>
                  <ExcelSheet data={this.state.excelData} name={this.state.storeId == 1 ? "Harmonija-knjige-proizvodi" 
                                                                    : this.state.storeId == 3 ? "Publik-praktikum-proizvodi" 
                                                                    : "Proizvodi"}>
                    <ExcelColumn label="ID" value="id" />
                    <ExcelColumn label="SKU" value="sku" />
                    <ExcelColumn label="ISBN" value="isbn" />
                    <ExcelColumn label="Naslov" value="title" />
                    <ExcelColumn label="Cena" value="price" />
                    <ExcelColumn label="Opis" value="description" />
                    <ExcelColumn label="Dimenzije" value="dimension" />
                    <ExcelColumn label="Broj strana" value="number_of_pages" />
                    <ExcelColumn label="Puna cena" value="full_price" />
                    <ExcelColumn label="Dobavljač" value="supplier" />
                    <ExcelColumn label="Tezina" value="weight" />
                    <ExcelColumn label="Kategorije" value="categories" />
                    <ExcelColumn label="Slika" value="image" />
                    <ExcelColumn label="Prevodilac" value="translator" />
                    <ExcelColumn label="Autor" value="author" />
                    <ExcelColumn label="Povez" value="binding" />
                    <ExcelColumn label="Pismo" value="letter" />
                    <ExcelColumn label="Paket" value="is_package" />

                  </ExcelSheet>
                </ExcelFile>
                </section>
                <section>
                <h2>Eksport plaćenih proizvoda</h2>
                <p>Izvoz liste plaćenih proizvoda za odabranu prodavnicu po datumu</p>
                <p className="tooltip">pre preuzimanja EXCEL fajla potrebno je kliknuti dugme 'Preuzmi podatke' kako bi se proizvodi povkukli iz databaze.</p>
                Datum: <DateTime placeholder="Odaberi datum..." text="" selected={this.state.dateFrom} showTimeSelect={false} onChange={(date)=>{this.setState({dateFrom: date})}}/>
                <button onClick={this.getPayedProducts}>Preuzmi podatke</button>
                {this.state.payedVisible &&
                    <ExcelFile element={<button className="keyword">Export excel</button>} filename={`Plaćeni proizvodi - ${this.state.storeName}`}>
                    <ExcelSheet data={this.state.excelDataPayed} name={`Plaćeni proizvodi - ${this.state.storeName}`}>
                        <ExcelColumn label="ID" value="id" />
                        <ExcelColumn label="SKU" value="sku" />
                        <ExcelColumn label="Količina" value="quantity" />
                        <ExcelColumn label="Naslov" value="title" />
                        
                        

                    </ExcelSheet>
                    </ExcelFile>
                }
                </section>
            </div>
        );
    }
}

export default Export;