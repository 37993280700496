import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';




export default function TicketNotification(){
    const {ticketsNotifications, setTicketsNotifications} = useContext(AuthContext);

    return (
        <span>
        {ticketsNotifications > 0 &&
            <span className="header-tickets-number">{ticketsNotifications}</span>
        }
        </span>
    )
}