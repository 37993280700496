import React, { Component } from 'react';
import Service from '../components/Service';
import {Link} from 'react-router-dom';

class AdminUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            groups: [],
            permissions: [],
            mode: 'default',
            userGroupId: null,
            userId: null
        }
    }

    componentDidMount(){
        Service.get('/users/getAdminUsers', {}, (res)=>{
            if(res.success){
                this.setState({
                    users: res.users,
                    groups: res.groups,
                    permissions: res.permissions
                }, ()=>{
                    console.log('state','vojno', this.state);
                })
            }
        })
    }

    editUser=(event)=>{
        // this.setState({
        //     mode: 'edit_user',
        //     userId: event.target.getAttribute('data-user-id'),
        // })
    }



    render() {
        return (
        <div>
            <h1>Administratori</h1>
            {this.state.mode == 'default' &&
            <div>
                <h3>Administratori</h3>
                <table className="cms-table">
                    <thead>
                        <tr>
                            <td>id</td>
                            <td>korisnicko ime</td>
                            <td>grupa</td>
                            <td></td>
                        </tr>    
                    </thead> 
                    <tbody>
                        {this.state.users.map(user => {
                        return(
                            <tr>
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>
                                    {user.group_name}
                                       
                                </td>
                                <td><Link to={`/admins/user/${user.id}`} ><button data-user-id={user.id}>Edit</button></Link>
                                </td>
                            </tr> 
                            )
                        })}
                    </tbody>
                </table>
            </div>
            }
            {/* {this.state.mode == 'edit_user' &&
                <div>
                {this.state.users.map(user => {
                    if(user.id == this.state.userId){
                        return(
                            <div>
                                <h2>{user.name}</h2>
                                <select>
                                {this.state.groups.map(group => {
                                    return(
                                        <option>{group.name}</option>
                                    )
                                })
                                </select>
                            </div>
                        )
                    }
                })
                }
                </div>
            } */}
            
            </div>
        );
    }
    

}

export default AdminUsers;
