import React, { Component } from 'react';
import Service from '../components/Service';
import ChatView from '../components/ChatView';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            perPage: 50,
            page: 1,
            numOfChats: '',
            previousPage: 0,
            nextPage: 0,
            totalChats: '',
            chats: [],
            selectedChat: '',
            storeId: '3'
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let chatId = event.target.getAttribute('data-chat-id');
        this.setState({ selectedChat: chatId },()=>{
        });
      }

    componentDidMount() {
        if(this.props.match.params.page){
            this.setState({page: this.props.match.params.page},()=>{
                this.getAllChats();
            });
        }else{
            this.getAllChats();
        }
    }

    selectStore=(event)=>{
        this.setState({storeId: event.target.value},()=>{
            this.getAllChats();
        })
    }

    handlePages=()=>{
        this.previousPage = parseInt(this.state.page) - 1;
        this.nextPage = parseInt(this.state.page) + 1;

        let totalPages = Math.ceil(this.state.numOfChats / this.state.perPage);

        if(this.nextPage > totalPages){
            this.nextPage = 1;
        }
        if(this.previousPage <= 0){
            this.previousPage = totalPages;
        }

        this.setState({
            previousPage: this.previousPage,
            nextPage: this.nextPage
        })
    }

    getAllChats(){
        Service.get('/chat/all',{
            page: this.state.page,
            perPage: this.state.perPage,
            storeId: this.state.storeId
        },(res)=>{
            if(res.success){
                this.setState({chats: res.data.chats, numOfChats: res.data.numOfChats},()=>{
                    this.handlePages();
                    setTimeout(()=>{this.getAllChats()}, 6000);
                })
            }
        });
    }



  render(){
    return(
        <div className="chat">
            <select className="chat-selector" value={this.state.storeId} onChange={this.selectStore} >
                <option value={3}>Publik Praktikum</option>
                <option value={1}>Harmonija</option>
            </select>

            <div className="chat-navigation">
                <a href={`/chat/${this.state.previousPage}`} ><button>-</button></a>
                strana {this.state.page} / {Math.ceil(this.state.numOfChats / this.state.perPage)}
                <a href={`/chat/${this.state.nextPage}`} ><button>+</button></a>  
            </div>

            <div className="left">
                <table className="chat-table">
                    <thead>
                        <tr>
                            {this.state.storeId === '3' ? 
                                <th className="left-top-pp" colSpan="4">
                                    <h3>Publik Praktikum</h3>
                                </th>
                            : this.state.storeId === '1' ? 
                                <th className="left-top-hk" colSpan="4">
                                    <h3>Harmonija knjige</h3>
                                </th>
                            : null}
                        </tr>
                    </thead>
                    
                    <tbody className="left-container">
                        {this.state.chats.map(function (item, index) {
                            return(
                                <tr key={index}>
                                    <td className="username">
                                        {item.name ? item.name : item.chat_id}
                                        {item.adminName &&
                                            <span className="chat-admin-name">{item.adminName}</span>
                                        }
                                    </td>
                                    <td className="message"><p>{item.message}</p></td>
                                    <td className="button"><button data-chat-id={item.chat_id} onClick={this.handleChange}>Reply</button></td>
                                    <td className="table-unreplied">
                                        {item.unreplied !== true &&
                                            <span className="unreplied"></span>
                                        }
                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
            <div className="right">
                <ChatView selectedChat={this.state.selectedChat} storeId={this.state.storeId} />
            </div>
        </div>
    )
}
}

export default Chat;