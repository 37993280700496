import React, {useState, useEffect} from 'react';
import Service from '../components/Service';

export default function AdminUser(props){

    const [user, setUser] = useState();
    const [groups, setGroups] = useState([]);

    useEffect(()=>{
        getUser();
        Service.get('/users/getAdminGroups', {}, res => {
            setGroups(res.groups);
        });
    },[]);

    const getUser=()=>{
        Service.get('/users/getAdminUser', {userId: props.match.params.id}, res=>{
            setUser(res.user);
        });
    }

    const changeGroup=(event)=>{
        console.log(event.target.value);
        Service.get('/users/changeUserGroup', {userId: props.match.params.id, groupId: event.target.value}, res=>{
            getUser();
        })
    }

    return(
        <div>
            <h1>test</h1>
            {user &&
                <>
                <h1>{user.name}</h1>
                <select onChange={changeGroup} value={user.group_id}>
                <option value={null} >bez grupe</option>
                    {groups.map(item=>{
                        return(
                            <option value={item.id} >{item.name}</option>
                        )
                    })}
                </select>
                </>
            }
        </div>
    )

}