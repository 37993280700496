import React, { Component } from 'react';
import { Fragment } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import StarRatings from 'react-star-ratings';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Select from '../components/Select';
import ReactPaginate from 'react-paginate';
import {LS} from '../components/LS';

function getStars(num){
    if(!num) {return}
    let res = '';
    for(var i = 0; i<num; i++){
        res += '&#9733;'
    }
    return res;
}
class Warehouse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWarehouse: 0,
            warehouses: [],
            products: [],
            warehouseName: null,
            modalVisible: false,
            action: '',
            selectedProduct: null,
            selectedProductName: '',
            selectedQuantity: '',
            transferWarehouseId: 0,
            transferWarehouseName: '',
            editValue: null,
            editKey: null,
            editProductId: null,
            newWarehouseName: null,
            createNew: false,
            filter: '',
            sortFilter: null,
            sortDirection: true,
            page: 1,
            perPage: LS.data.warehousesPerPage ? LS.data.warehousesPerPage : 20,
            totalPages: 1,
            suppliers: [],
            selectedSupplier: null,
            allProducts: 0

        }
        // this.myRef = [];
    }

    changeSort=(event)=>{
        let s = event.target.getAttribute('data-sort');
        if(s != this.state.sortFilter){
            this.setState({sortFilter: s, page: 1}, ()=>{
                this.getWarehouse();
            })
        }else{
            this.setState({sortDirection: !this.state.sortDirection}, ()=>{
                this.getWarehouse();
            })
        }
    }

    handleNewWarehouseName=(event)=>{
        this.setState({newWarehouseName: event.target.value});
    }

    createNewWarehouse=()=>{

        let whName = this.state.newWarehouseName;
        Service.get('/warehouses/create', {name: whName}, (res)=>{
            if(res.success){
                this.setState({createNew: false, newWarehouseName: null});
                this.getWarehouses();
                alert('Magacin ' + whName + ' je uspesno napravljen');
            }
        })
        
    }

    // setProductStock=(event)=>{
    //     if (event.key === 'Enter') {
    //         //console.log(event.target.getAttribute('data-product-id'), event.target.value);
    //         let val = event.target.value;
    //         Service.get('/products/setStock',{productId: event.target.getAttribute('data-product-id'), stock: val},(res)=>{
    //             if(res.success){

    //                 this.updateProducts();
    //                 this.getSuppliers();
    //             }else{
    //                 alert('greska! '+res.info);
    //             }
    //         })
    //         this.setState({updateProductId: null});
    //     }
        
    // }

    editProductStock = (event) => {
        if(event.key && event.key !== 'Enter'){
            return;
        }
        
        console.log('edit',event.target.value, event.target.getAttribute('data-type'), event.target.getAttribute('data-product-id'));
        Service.get('/products/setStock', {
            productId: event.target.getAttribute('data-product-id'),
            stock: event.target.value.toString() ? event.target.value : 0
        },(res)=>{
            
            if(res.success){
                this.getWarehouse();
            }else{
                alert('greska'+ res.message);
            }
            
        })
        event.target.blur();
    }  

//   <input value={this.state.editValue} onChange={this.editProductValue} onKeyPress={this.applyEditProductValue} autoFocus onBlur={this.cancelEditProduct} />
editProductValue=(event)=>{
    if(event.key && event.key !== 'Enter'){
        return;
    }
    
    console.log('edit',event.target.value, event.target.getAttribute('data-type'), event.target.getAttribute('data-product-id'));
    Service.get('/warehouses/updateWarehouseProduct', {
        type: event.target.getAttribute('data-type'),
        productId: event.target.getAttribute('data-product-id'),
        warehouseId: this.state.selectedWarehouse,
        value: event.target.value.toString() ? event.target.value : null
    },(res)=>{
        
        if(res.success){
            this.getWarehouse();
        }else{
            alert('greska'+ res.message);
        }
        
    })
    event.target.blur();
}  

cancelEdit=(event)=>{
    event.target.value = null;
    // console.log('test ref', this.myRef[event.target.getAttribute('data-product-id')]);
    // this.myRef[event.target.getAttribute('data-product-id')].value = null;
}

  componentDidMount(){
    this.getWarehouses();
    this.getWarehouse();
    this.getSuppliers();
  }

  getSuppliers=()=>{
      Service.get('/warehouses/getSuppliers', {}, (res)=>{
          if(res.success){
              this.setState({suppliers: res.suppliers})
          }
      })
  }

  selectWarehouse=(event)=>{
      let name = event.target.options[event.target.selectedIndex].text;

    this.setState({selectedWarehouse: event.target.value, warehouseName: name, transferWarehouseId: 0}, ()=>{
        console.log(this.state);
        this.getWarehouse();
    })
  }

  getWarehouses = () => {
      Service.get("/warehouses/get", {}, (res)=>{
          if(res.success){
            this.setState({warehouses: res.warehouses});
          }
        
      });
  }

  getWarehouse=()=>{
        Service.get("/warehouses/getProducts", {
                warehouseId: this.state.selectedWarehouse, 
                filter: this.state.filter, 
                sortFilter: this.state.sortFilter,
                sortDirection: this.state.sortDirection,
                page: this.state.page,
                perPage: this.state.perPage,
                supplierFilter: this.state.selectedSupplier,
                allProducts: this.state.allProducts
            }, (res)=>{
            if(res.success){
              this.setState({products: res.products, totalPages: res.totalPages});
            }
        });
  }

    closeModal = ()=>{
        this.setState({modalVisible: false});
    }

    changeQuantity = (event) => {
        this.setState({selectedQuantity: event.target.value});
    }

    getWarehouseName=(warehouseId)=>{
        this.state.warehouses.map(item=>{
            console.log(item, warehouseId);
            if(parseInt(warehouseId) == item.id){
                console.log('IMAGA', item);
                return item.name;
            }
        })
    }

    setFilter=(event)=>{
        this.setState({filter: event.target.value}, ()=>{

            setTimeout(() => {
                this.getWarehouse();
            }, 500);  

            
        });
    }

    applyFilter=(event)=>{
        if(event.key && event.key == 'Enter'){
            this.getWarehouse();
        }
        
    }

    changeRating=(rating, productId)=>{
        console.log(rating, productId);
        Service.get('/warehouses/setProductAdminRating', {rating, productId}, (res)=>{
            this.getWarehouse();
        })
    }

    changePage=(value)=>{
        console.log(value.selected + 1);
        this.setState({page: value.selected + 1}, ()=>{
            this.getWarehouse();
        })
    }

    setWarehouseProduct=(event)=>{
        Service.get('/warehouses/setWarehouseProduct', {productId: event.target.getAttribute('data-product-id'), value: event.target.checked}, (res)=>{
            this.getWarehouse();
        })
        event.preventDefault();
        //event.target.setAttribute("disabled", "disabled");
    }

  

  render() {
    return (
      <div>
          <h1>Publik Praktikum - ROBA</h1>
            {/* {this.state.selectedWarehouse ? (
                <h2>MAGACIN - {this.state.selectedWarehouse}: {this.state.warehouseName}</h2>
            ):(
                <h2>Svi magacini</h2>
            )} */}
            {/* <div>
                <label>Odaberi magacin:</label><br/>
                <select onChange={this.selectWarehouse} >
                    <option value={0} >Zbirno</option>
                    {this.state.warehouses.map(item=>{
                        
                        return(
                            <option value={item.id}>{item.name}</option>
                        )
                    })}
                </select> 
            </div> */}
            <div className={'align-right'}>
                <button onClick={()=>this.setState({createNew: true})}>Novi magacin</button>
                <Link to='/documents'><button>Dokumenti</button></Link>
            </div>
            {this.state.createNew &&
                <div>
                    <hr/>
                    <p>Novi magacin:</p>
                    <input className="input-padding" value={this.state.newWarehouseName} onChange={this.handleNewWarehouseName} placeholder="Unesi ime magacina"/>
                    <button onClick={this.createNewWarehouse}>Sačuvaj</button>
                    <button style={{float: 'right'}} className="button-secondary" onClick={()=>this.setState({createNew: false})}>Odustani</button>
                </div>
            }
            <div style={{fontSize: '.8em'}}>
                <div className="third">
                <input placeholder="pretraga..." type="text" value={this.state.filter} onChange={this.setFilter} onKeyDown={this.applyFilter} />
                </div>
                <div className="third padding-left">
                <Select id="name" value="name" placeholder="brand..." selected={this.state.selectedSupplier} options={this.state.suppliers} onSelect={(res)=>this.setState({selectedSupplier: res, page: 1}, ()=>this.getWarehouse())} />
                </div>
                <div className="third  padding-left">
                    <div className="half">
                    <Select id="id" value="name" placeholder="proizvodjač..." selected={this.state.allProducts} options={[{id: 0, name: 'Publik'}, {id: 1, name: 'Svi proizvodi'}]} onSelect={(res)=>this.setState({allProducts: res, page: 1}, ()=>this.getWarehouse())} />
                    </div>
                    <div className="half align-right">
                        Po stranici: <input style={{display: 'inline-block', width: '5em'}} placeholder="po strani..." type="text" value={this.state.perPage} onChange={(event)=>{this.setState({perPage: parseInt(event.target.value), page: 1}); LS.set({warehousesPerPage: parseInt(event.target.value)})}} onKeyDown={(event)=>{if(event.keyCode == 13){this.getWarehouse(); event.target.blur()}}} />
                    </div>
                </div>
                
            </div>
            {/* <div className="quarter padding-left">
                <Select id="id" value="name" placeholder="sortiranje..." selected={this.state.sortFilter} options={[{id: 'id', name: 'id'}, {id: 'title', name: 'naslov'}, {id: 'calculated_quantity', name: 'količina'}, {id: 'admin_stars', name: 'zvezdice'}]} onSelect={(res)=>this.setState({sortFilter: res}, ()=>this.getWarehouse())} />
            </div>
            <div className="quarter padding-left">
                <Select id="id" value="name" placeholder="pravac..." selected={this.state.sortDirection} options={[{id: 0, name: '∧'}, {id: 1, name: '∨'}]} onSelect={(res)=>this.setState({sortDirection: res}, ()=>this.getWarehouse())} />
            </div> */}
            <div className="mobile-table-containter">
                <table className="warehouse-table">
                    <thead>
                        <tr>
                            <th className="clickable" onClick={this.changeSort} data-sort='id' >id</th>
                            <th className="clickable" onClick={this.changeSort} data-sort='sku'>sku</th>
                            <th className="clickable" onClick={this.changeSort} data-sort='admin_stars' >zvezdice</th>
                            <th className="clickable" onClick={this.changeSort} data-sort='title' >naziv</th>
                            <th className="clickable" onClick={this.changeSort} data-sort='calculated_quantity' >stanje kom.</th>
                            <th >web stanje</th>
                            <th>pozicije</th>
                            {this.state.products[0] &&
                                this.state.products[0].stocks.map(item=>{
                                    return(
                                        <th className="align-center">{item.warehouse_name}</th>
                                    )
                                })
                            }
                            <th className="clickable" onClick={this.changeSort} data-sort='notify_quantity' >Upozori na</th>
                            <th className="clickable" data-sort='notify_quantity_2' >Drugo upozorenje</th>
                            <th>Status produkcije</th>
                            
                            {/* <td>akcije</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.products.map(item=>{ 
                            //if(item.quantity){
                            return(
                                <>
                                <tr style={{background: (item.calculated_quantity <= item.notify_quantity && item.notify_quantity) ? '#eee' : 'none', color: item.is_warehouse_product ? 'black' : 'gray'}} >
                                    <td>{item.id} <input type="checkbox" checked={item.is_warehouse_product} onChange={this.setWarehouseProduct} data-product-id={item.id} /></td>
                                    <td>{item.sku}</td>
                                    <td style={{width: '7em'}} onClick={()=>{this.setState({selectedProduct: item.id})}}>
                                        {/* {for(var i = 0; i < item.admin_stars; i++){
                                            return (&#9733;)
                                        }} */}
                                    {/* {item.admin_stars == 1 &&
                                        <span>&#9733;</span>
                                    } */}
                                    {/* {getStars(item.admin_stars)} */}
                                    
                                    
                                    {this.state.selectedProduct == item.id ?
                                    //  <StarRatings
                                    //     rating={item.admin_stars}
                                    //     starRatedColor="#edc608"
                                    //     changeRating={this.changeRating}
                                    //     numberOfStars={3}
                                    //     name={item.id}
                                    //     starDimension="20px"
                                    //     starSpacing="0px"
                                    // /> 
                                    <Select id='id' value='name' placeholder="zvezdice..." options={[{id: 0, name: '-'}, {id: 1, name: '★'}, {id: 2, name: '★★'}, {id: 3, name: '★★★'}]} selected={item.admin_stars}  onSelect={(res)=>{this.changeRating(res, item.id)}} />
                                    : 
                                    <span dangerouslySetInnerHTML={{ __html: getStars(item.admin_stars) }} />
                                    }
                                    
                                    </td>
                                    <td className={`clickable ${(item.calculated_quantity <= item.notify_quantity && item.notify_quantity) ? 'warehouse-table-quantity-alert' : null}`} onClick={()=>{this.setState({selectedProduct: item.id})}}>{item.title.substring(0,50)} - {item.author}</td>
                                    <td className={`text-right warehouse-quantity align-center ${(item.calculated_quantity <= item.notify_quantity && item.notify_quantity) ? 'warehouse-table-quantity-alert' : null}`} onClick={()=>{this.setState({selectedProduct: item.id})}}>{item.calculated_quantity}</td>
                                    <td onClick={()=>{this.setState({selectedProduct: item.id})}}>
                                        {this.state.selectedProduct == item.id ?
                                            <input data-product-id={item.id} data-type="stock" onKeyPress={this.editProductStock} onBlur={this.cancelEdit} placeholder={item.stock} size="8"/>
                                        :
                                            item.stock
                                        }
                                    </td>
                                    <td>
                                    {item.positions && this.state.selectedProduct == item.id &&
                                        <table className="warehouse-table">
                                            {item.positions.map(position => {
                                                return(
                                                    <tr>
                                                        <td>{position.position_name ? position.position_name : '-'}</td>
                                                        <td>{position.quantity}</td>
                                                        <td>{position.warehouse_name}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                        }
                                    </td>
                                    {item.stocks &&
                                        item.stocks.map(stock=>{
                                            return(
                                                <td className="align-right">{stock.quantity}</td>
                                            )
                                        })
                                    }

                                    <td onClick={()=>{this.setState({selectedProduct: item.id})}}>
                                        {this.state.selectedProduct == item.id ?
                                            <input data-product-id={item.id} data-type="notify" onKeyPress={this.editProductValue} onBlur={this.cancelEdit} placeholder={item.notify_quantity} size="8"/>
                                        :
                                            item.notify_quantity
                                        }
                                    </td>

                                    <td onClick={()=>{this.setState({selectedProduct: item.id})}}>
                                        {this.state.selectedProduct == item.id ?
                                            <input data-product-id={item.id} data-type="notify_2" onKeyPress={this.editProductValue} onBlur={this.cancelEdit} placeholder={item.notify_quantity_2} size="8"/>
                                        :
                                            item.notify_quantity_2
                                        }
                                    </td>
                                    <td onClick={()=>{this.setState({selectedProduct: item.id})}}>
                                        {this.state.selectedProduct == item.id ?
                                            <input data-product-id={item.id} data-type="production_status" onKeyPress={this.editProductValue} onBlur={this.cancelEdit} placeholder={item.production_status} size="8"/>
                                        :
                                            item.production_status
                                        }
                                    </td>

                                    
                                    

                                    

                                
                                </tr>
                               
                                    </>
                                
                            )
                            //}
                        })}
                    </tbody>
                </table>
                {this.state.totalPages > 1 &&
                <ReactPaginate
                        pageCount={this.state.totalPages}
                        pageRangeDisplayed={window.innerWidth > 720 ? 5 : 1}
                        marginPagesDisplayed={window.innerWidth > 720 ? 3 : 1}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.state.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                }
            </div>
        
      </div>
    );
  }

}

export default Warehouse;
