//import React, { Component } from 'react';
import React, { useContext, useState, useEffect } from 'react';
import Service from '../components/Service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from '../components/Select';

const modules = {
    toolbar: [
      [{ 'header': [2, 3, false] }],
      ['bold', 'italic', 'underline','strike'],
      ['link'],
      [{ list: 'ordered' },
        { list: 'bullet' }],
      ['clean']
    ],
  }
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'link', 'list', 'bullet'
  ]

export default function TicketCreate(props){

  const [ticket, setTicket] = useState();

  const [types, setTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState();
  const [orderId, setOrderId] = useState();
  const [user, setUser] = useState();
  const [priority, setPriority] = useState();
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [responsible, setResponsible] = useState();

  useEffect(()=>{
    Service.get('/tickets/getOptions', {}, res=>{
        setPriorities(res.priorities);
        setUsers(res.users);
        setTypes(res.types);
        setStatuses(res.statuses);
    })
    
  }, []);

  const getTicket=()=>{
    Service.get('/tickets/get', {}, (res)=>{
      setTicket(res.tickets);
    });
  }

  const createTicket=()=>{
    Service.get('/tickets/create', {title: title, orderId: orderId, description: description, assignedId: user, priority: priority, type: type, status: status, responsibleId: responsible}, (res)=>{
      setTicket(res.last_inserted_id);
      props.onCreate(res.last_inserted_id);
    });
  }



    return (
        <div className="ticket-create">
            <input placeholder="naslov tiketa..." value={title} onChange={(event)=>setTitle(event.target.value)} />
            <input placeholder="broj porudžbine" value={orderId} onChange={(event)=>setOrderId(event.target.value)} />
            <ReactQuill value={description}
                tabindex="-1"
                className="text-editor"
                placeholder="sadrzaj tiketa..."
                modules={modules}
                formats={formats}
                onChange={(value) => setDescription(value)} 
            />
            <div className="quarter">
                <Select id='id' value='name' placeholder="zadatak za..." options={users}  onSelect={(res)=>{setUser(res)}} />
            </div>
            <div className="quarter padding-horizontal">
                <Select id='id' value='name' placeholder="tip problema..." options={types}  onSelect={(res)=>setType(res)} />
            </div>
            <div className="quarter padding-right">
                <Select id='id' value='name' placeholder="prioritet..." options={priorities} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setPriority(res)}} />
            </div>
            <div className="quarter ">
                <Select id='value' value='name' placeholder="status..." options={statuses} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setStatus(res)}} />
            </div>
            <div className="quarter ">
                <Select id='id' value='name' placeholder="odgovorno lice..." options={users} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setResponsible(res)}} />
            </div>
            
            <div className="align-right">
                <button onClick={createTicket} >Sačuvaj</button>
            </div>
        </div>
        
    );

}
