//localStorage.setItem('_ls_cloudninetravel', JSON.stringify({vojno: 'car'}))
import Service from '../components/Service';

export const LS = {

    name: Service.localStorageName,
    data: {},

    set(obj){
        for(var p in obj){
            this.data[p] = obj[p];
        }
        localStorage.setItem(this.name, JSON.stringify(this.data));
    },

    clear(){
        this.data = {};
        localStorage.removeItem(this.name);
    },

    init(){
        this.data = JSON.parse(localStorage.getItem(this.name)) ? JSON.parse(localStorage.getItem(this.name)) : {};
    },

    

}

LS.init();