import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import {Link} from 'react-router-dom';

const API = process.env.REACT_APP_API;

class Discount extends Component {
  constructor(props) {
    super(props);
    this.state = {
        products: [],
        status: 0,
        allProducts: [],
        filter: 'mape'
    }
  }

  componentDidMount(){
    this.getDiscount();
    //this.getProducts();
  }

  handleInput = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  getDiscount = () => {
    Service.get('/orders/discountProducts',{discountName: this.props.match.params.id},(res)=>{
        if(res.success){
          this.setState({products: res.products, status: res.status});
        }
    });
  }

  removeProduct=()=>{
      alert('Ova funkcija jos nije dostupna!');
  }

    getProducts=()=>{
        Service.get('/products/get', {filter: this.state.filter}, (res)=>{
            if(res.success){
                this.setState({allProducts: res.data.products});
            }
        })
    }

  changeDiscountStatus=()=>{

      var x;
     if (window.confirm("Da li ste sigurni da zelite da promenite status akcije?") == true) {
        let status = 0;
        if(!this.state.status){
            status = 1;
        }
        Service.get('/orders/changeDiscountStatus', {discountName: this.props.match.params.id, status: status},(res)=>{
            if(res.success){
                this.getDiscount();
            }
        })
     } else {
         x = "You pressed Cancel!";
     }
     return x;
      
  }

  toggleProductDiscount = (event) => {
      let productId = event.target.getAttribute('data-product-id');
      //discountName: this.props.match.params.id
      Service.get('/orders/addProductToDiscount', {productId: productId, discountName: this.props.match.params.id},(res)=>{
            if(res.success){
                this.getDiscount();
            }
        })
      console.log(productId);
  }



  render() {
    return (
      <div>
          <h1>{this.props.match.params.id}</h1>

            <div>     
                <button onClick={this.changeDiscountStatus} className={this.state.status ? 'button-active' : '' } >{this.state.status ? 'Aktivan' : 'Neaktivan'}</button>         
                    {/* <div className="discounts-product-search">
                        <ul>
                        {this.state.allProducts.map((product, index)=>{
                                return(
                                    <li>
                                        <img src={product.image}/>
                                        <span>{product.id} - {product.title}</span>
                                        <button data-product-id={product.id} onClick={this.addProductToDiscount}>dodaj proizvod</button>
                                    </li>
                                )
                            })
                        
                        }
                        </ul>
                    </div> */}
                    <table className='cms-table'>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Slika</th>
                            <th>Ime</th>
                            <th>popust</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.products.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.image}</td>
                                    <td>{item.title}</td>
                                    <td><input data-product-id={item.id} type="checkbox" checked={item.inDiscount} onChange={this.toggleProductDiscount}/></td>
                                    {/* <td><button data-product-id={item.id} onClick={this.removeProduct}>Izbaci</button></td> */}
                                </tr>
                                )
                            },this)
                        }
                        </tbody>
                    </table>


          </div>

          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          {/* <h2>Privremeno dugme za brisanje proizvoda:</h2>
          <button onClick={() => this.check('test')}>IZVBRISI PROIZVODE</button> */}
      </div>
    );
  }

}

export default Discount;
