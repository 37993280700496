import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
import OrderData from "../components/OrderData";
import ReactToPrint from 'react-to-print';
import { CSVLink, CSVDownload } from "react-csv";
import {PrintOrders} from "../components/PrintOrders"


const API = process.env.REACT_APP_API;
function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
  }
  
function pad(num, size){
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

function compare( a, b ) {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
}

// let parsePhone =(n)=>{
//   let cleaned = n.replace(/\D/g, '');
//   let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return match[1] + ' ' + match[2] + ' ' + match[3]
//   }else{
//     return cleaned;
//   }
//   // return null
// }

class PrintPreview extends Component {

    constructor(props) {
    super(props);
    this.state={
        order: [{id:1}],
        products: [],
        csvData: [],
        orders: [],
        prepared: false
    }
    //this.printRef = React.createRef();
  }
  componentDidMount(){
    this.updateOrders();
    //this.componentRef.handlePrint();
  }

  componentWillReceiveProps(nextProps){
      this.props = nextProps;
      this.setState({prepared: false})
      //this.updateOrders();
  }

  generateCsvData=()=>{
        let csvData = [
            ["ReferenceID", "SBranchID", "SName", "SAddress", "STownID", "STown", "SCName", "SCPhone", "PuBranchID", "PuName", "PuAddress", "PuTownID", "PuTown", "PuCName", "PuCPhone", "RBranchID", "RName", "RAddress", "RTownID", "RTown", "RCName", "RCPhone", "DlTypeID", "PaymentBy", "PaymentType", "BuyOut", "BuyOutAccount", "BuyOutFor", "Value", "Mass", "ReturnDoc", "Packages", "Note", "Content"]
        ];
        let r = [
            this.state.order.id,
            "UK15494",
            "Publik Praktikum doo",
            "Dobrovoljačka 10/I",
            "11080",
            "Zemun",
            "Kontakt",
            "069/187-24-29",
            "UK15494",
            "Publik Praktikum doo",
            "Dobrovoljačka 10/I",
            "11080",
            "Zemun",
            "Kontakt",
            "069/187-24-29",
            "",
            this.state.order.name,
            this.state.order.billing_address,
            this.state.order.zip_code,
            this.state.order.city,
            this.state.order.name,
            this.state.order.phone,
            2,
            0,
            2,
            (this.state.order.total * 100),
            "160-432229-85",
            0,
            this.getShipmentWeight(),
            0,
            0,
            'TT'+pad(this.state.order.id, 10),
            this.state.order.note,
            "knjige"
        ];
        csvData.push(r);
        this.setState({csvData: csvData},()=>{
            //console.log('CSV',this.state.csvData);
        });
        // console.log("---------")
        // console.log(parsePhone(this.state.order.phone));
        // console.log(this.state.order.phone);
  }

  getShipmentWeight=()=>{
      let r = 0;
      this.state.products.map(function(item){
          r += item.weight * item.quantity;
      })
        return r;
      
  }

  setPrintedOrders = () => {
    console.log('orders', this.state.orders);
  }

    updateOrders=()=>{

      this.setState({orders: []}, ()=>{
        setTimeout(()=>{
          Service.get("/orders/printOrders", {orders: this.props.orders}, (res)=>{
            let o = res.orders;
            o.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            this.setState({orders: o, prepared: true});
          })
        },1000)

      })
      return;
        let o = [];
        
        let count = 0;
        this.props.orders.map(function(item, index){
            Service.get("/orders/info", {orderId: item}, (res)=>{
                    o.push({
                    order:res, 
                    products:res.products,
                    coupons: []//res.order.coupons
                    })
                    o.sort((a,b) => (a.order.id > b.order.id) ? 1 : ((b.order.id > a.order.id) ? -1 : 0));
                    
                    //get the last order
                    count ++;
                    if(count >= this.props.orders.length){
                        this.setState({orders: o}, ()=>{

                            // show print button with latency so all the orders can easily and safely render
                            setTimeout(()=>{
                                this.setState({prepared: true});
                            }, 4000);
                            
                            
                        });
                    }
            },this);
        },this)



    }

  pad = (num, size) => {
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

  render() {
    return (
        <div style={{display: 'inline-block'}}>
          {/* <h1>Porudzbina</h1> */}
          {/* <button><CSVLink data={this.state.csvData} separator={"|"} filename={"dExpress-"+this.state.order.id+".csv"}>dExpress CSV</CSVLink></button> */}
            {this.state.prepared == false &&
                <button onClick={this.updateOrders}>Pripremi</button>
            }
            {this.state.prepared == true &&
            <>
              <button style={{background: "green", color: 'white'}}>
                <ReactToPrint
                    // trigger={() => <a href="#">Štampaj</a>}
                    trigger={() => <span>Štampaj</span>}
                    content={() => this.componentRef}
                />
                </button>
                <button onClick={this.setPrintedOrders} >OBELEŽI ODŠTAMPANO</button>
              </>
            }
            {/* hiding span */}
            <span style={{display: 'none'}}> 
                <span ref={el => (this.componentRef = el)}>
                    {this.state.orders.map(function(item){
                        return (
                            <span>
                                <OrderData order={item} products={item.products} coupons={item.coupons}/>
                                {/* { console.log('pm', item.payment_method) }
                                {item.payment_method === 'cc' &&
                                    <OrderData order={item} products={item.products} coupons={item.coupons}/>

                                } */}
                                
                            </span>

                        )
                    })
                    }

                </span>
            </span>
          
          
        </div>
    );
  }

}
export default PrintPreview;