import React, { Component, Fragment } from 'react';
import Service from '../components/Service';

var Barcode = require('react-barcode');


function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

function pad(num, size){
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

function getDate () {
    var today = new Date();
    var date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear()+' '+today.getHours() + ":" + today.getMinutes();
    return date;
}

function serbianDate(date){
    let d = new Date(date)
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da}-${mo}-${ye}`;
}

function getPages(items){
    let np = Math.ceil(items - 15 / 30)
}

// const format = {
//     x: "1000",
//     y: "1414.28px",
//     yy: "2828.56px"
// }

const format = {
    x: "1000",
    //y: 1414.8561, //1414.8561
    y: 297,
    //y: 1414.2857,
    yy: "2828.56px"
}


//123456|"UKxxxxx"|"Xshop doo"|"Milutina Milankovića 177"|11070|"Beograd (Novi Beorad)"|"Vaša kontakt osoba"|"065/xxxx-xxx"|"UKyyyyy"|"Xshop doo"|"Pere Velimirovića 12/44a"|11090|"Beograd (Rakovica)"|"Vaša kontakt osoba"|"065/xxxx-xxx"||"Vesna Đorđević"|"Kralja Petra I 29/1"|17530|"Surdulica"||"064/420-9793"|2|0|2|450000|325-9500700034685-27|0|0|300|0|"TT0000000001#TT0000000002#"|"Test 1 pošiljka - tekst

class OrderData extends Component {

    constructor (props) {
        super(props);
        this.state = {
            orderDate: '',
            paymentMethod: '-',
             pages: []
            // productsOver: false,
        }
    }

    pages = []
    perPage = 30

    getShipmentWeight=()=>{
        let r = 0;
        this.props.products.map(function(item){
            r += item.weight * item.quantity;
        })
            return r;   
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps !== this.props) {
    //         console.log('updated')
    //       this.forceUpdate();
    //     }
    //   }

    componentDidMount(){
        console.log('orderrrr', this.props.order, this.props.order.id);
        //28261
        if(this.props.order.id == '28203'){
            console.log('paym',this.props.order.payment_method, this.props.order.id);
        }
        //setTimeout(()=>{
            let pm = this.props.order.payment_method == 'pp' ? '2' : '1';
            if(this.props.order.id == '28203'){
                console.log('paym2',this.props.order.payment_method, this.props.order.id);
            }
            let cnt = Math.ceil((this.props.products.length-15) / 30);
            this.setState({pages: cnt});
            for(var i=1; i<=cnt; i++){
                this.pages.push(i);
                
            }
            console.log('oagess', this.pages, cnt, this.props.order)
            this.setState({orderDate: serbianDate(this.props.order.created_at.slice(0, 10)), paymentMethod: pm});
        //},2000);

        // this.countProducts();
    }

    // countProducts=()=>{
    //     this.props.products.map(function(item, index){
    //         if(index >= 15){
    //             this.setState({productsOver: true})
    //         }

    //     },this)
    // }

    getOrderDate=()=>{
        let d = this.props.order.created_at;
        return d;
    }

    getOrderHeight=()=>{
        console.log('done', ((Math.ceil((this.props.products.length-15) / 30) * format.y) + format.y) + 'mm');
        return (((Math.ceil((this.props.products.length-15) / 30) * format.y) + format.y)) + 'mm';
    }

    render() {
        return (
        // <div className="order-data" style={{ height: this.getOrderHeight() }}>   
        <div className="order-data">
            <div className="order-print-page">
                <div className="table-orders">
                    <div className="company-data">
                        <img src="/memorandum-header.jpg" />
                    </div>
                    <div className="orders-left">  
                        <div className="orders-customer">
                            <b>Porudžbenica br:&nbsp; {this.props.order.id}</b><br/>
                            Ime: {this.props.order.name}<br/>
                            Adresa: {this.props.order.billing_address}<br/>
                            Email: {this.props.order.email}<br/>
                            Telefon: {this.props.order.phone}<br/>
                            Cena: {this.props.order.total}<br/>
                            Način plaćanja: {this.props.order.payment_method === "pp" ? 'Pouzećem' : this.props.order.payment_method === "cc" ? 'Kreditna kartica' : null}<br/>
                            Datum: {this.state.orderDate}<br/>
                            <b>Napomena:</b> 
                            <div className='order-note'>
                                {this.props.order.note}
                            </div>
                        </div>
                    </div>
                    <div className="orders-right">
                        <div className="dexpress">
                            <p>D Express doo, Zage Malivuk 1, Beograd</p>
                            <div className="dexpress-sender"><strong>Pošiljalac: </strong>Publik Praktikum, Dobrovoljačka 10/I, 11080 Zemun</div>
                            <div className="dexpress-package">1/1</div>
                            <Barcode width={2} className="barcode" value={this.props.order.code} />
                            <p className="dexpress-reciever-title">Primalac</p>
                            <p className="dexpress-reciever">
                                {this.props.order.shipping_name}<br />
                                {this.props.order.shipping_address}<br />
                                {this.props.order.shipping_zip_code} {this.props.order.shipping_city}<br />
                                {/* {this.props.order.municipality}<br /> */}
                                {this.props.order.shipping_phone}<br />
                            </p>
                            <div>
                                <div className="half dexpress-order">
                                    Referentni broj: {this.props.order.id}
                                    <br />Uslugu plaća: nalogodavac - virman
                                     <br />Povratna dokumentacija: {this.props.order.payment_method == 'pp' ? '2' : '2'}  {/*{this.state.paymentMethod} */}
                                    {/* <br />Otkupnina: {this.props.order.total} RSD */}
                                    <br />Sadržaj: knjige
                                    <br />Masa: {this.getShipmentWeight() / 1000} Kg
                                </div>
                                <div className="half">
                                    Napomena: <br />
                                    {this.props.order.note}
                                </div>
                            </div>
                            <p>Vreme štampe: {getDate()}</p>
                        </div>
                    </div>
                </div>

                <div className="order-products">
                    <h3>Sadržaj porudžbenice:</h3>
                    <table>
                        <thead>
                        <tr>
                            <td>Br.</td>
                            <td>Naziv</td>
                            {/* <td>SKU</td> */}
                            <td>Količina</td>
                            <td>Cena</td>
                            <td>Iznos</td>
                        </tr>
                        </thead>
                        {this.props.products.map(function(item, index){
                            if(index <= 14){
                                return(
                                    // <fragment>
                                    <tbody>
                                        <tr>
                                            <td>{index + 1}.</td>
                                            <td>{item.title}</td>
                                            {/* <td>{item.productData.sku}</td> */}
                                            <td>{item.quantity}</td>
                                            <td>{Service.price(item.unit_price)}</td>
                                            <td>{Service.price(item.price)}</td>
                                        </tr>
                                    </tbody>
                                    // </fragment>
                                    // <div key={index}  >
                                    //      <img src={images(item.productData.slug.toLowerCase())} width="50" />
                                    //     <p>ProductID:&nbsp; <b> {item.product_id}</b>&nbsp; Naslov:&nbsp;<b> {item.productData.title}</b>&nbsp; Sku:&nbsp; <b>{item.productData.sku}</b>&nbsp; Količina:&nbsp; <b> {item.quantity}</b>  </p> 
                                    // </div>
                                    )
                            }  
                        },this)
                        }
                    </table>
                </div>
                {this.pages.length < 1 &&
                    <fragment>
                        <div className='order-totals-print'>
                            <p>Ukupno {Service.price(this.props.order.subtotal)}</p>
                            {this.props.coupons.map(function(item, index){
                                    return(
                                    <p>Kupon {'"'+item.description+'"'} -{item.subtracted} RSD</p>
                                    )
                            },this)}
                            {parseFloat(this.props.order.shipping_price) > 0 &&
                                <p>Poštarina {this.props.order.shipping_price} RSD</p>
                            }
                            <p style={{fontSize: '1.2em'}}>Ukupno za uplatu <b>{this.props.order.total} RSD</b></p>
                            <p>U cenu je uračunat PDV.</p>
                        </div>
                        <div className="order-print-footer">Ovaj dokument štampan iz računara i punovažan je bez potpisa i pečata</div>
                    </fragment>
                            }
                <div className="order-print-id">id: {this.props.order.id}</div>
                <div className="order-print-pagination">strana 1/{this.pages.length + 1}</div>
            </div>

                {this.pages.map((itm, page)=>{
                  
                {console.log('new page', page)}
                //if(this.props.products.length > 15){
                    return(
                        <div className="order-print-page"> 
                            {/* <div className="order-products" style={{position: 'absolute', top: `${format.y * (page + 1)}mm`}} > */}
                            <div className="order-products" >
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Br.</td>
                                            <td>Naziv</td>
                                            <td>Količina</td>
                                            <td>Cena</td>
                                            <td>Iznos</td>
                                        </tr>
                                    </thead>
                                {this.props.products.map(function(item, index){
                                    if(index >= ((page + 1) * 30) - 15 && index < (((page + 1) * 30) - 15) + 30){
                                        return(
                                            <tbody>
                                                <tr>
                                                    <td>{index + 1}.</td>
                                                    <td>{item.title}</td>
                                                    {/* <td>{item.productData.sku}</td> */}
                                                    <td>{item.quantity}</td>
                                                    <td>{Service.price(item.unit_price)}</td>
                                                    <td>{Service.price(item.price)}</td> 
                                                    {/* totalPriceForProduct */}
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                    },this)
                                }
                                </table>
                            </div> 
                            {page >= this.pages.length - 1 &&
                                <fragment>
                                    <div className='order-totals-print'>
                                        {console.log('pl', this.pages.length)}
                                        <p>Ukupno {Service.price(this.props.order.subtotal)}</p>
                                        {this.props.coupons.map(function(item, index){
                                                return(
                                                <p>Kupon {'"'+item.description+'"'} -{item.subtracted} RSD</p>
                                                )
                                        },this)}
                                        {parseFloat(this.props.order.shipping_price) > 0 &&
                                            <p>Poštarina {this.props.order.shipping_price} RSD</p>
                                        }
                                        <p style={{fontSize: '1.2em'}}>Ukupno za uplatu <b>{this.props.order.total} RSD</b></p>
                                        <p>U cenu je uračunat PDV.</p>
                                    </div>
                                    <div className="order-print-footer">Ovaj dokument štampan iz računara i punovažan je bez potpisa i pečata</div>
                                </fragment>
                            }
                            <div className="order-print-id">id: {this.props.order.id}</div>
                            <div className="order-print-pagination">strana {page+2}/{this.pages.length + 1}</div>
                            
                    </div> 
                )}
                
                )}

                {/* <div style={{position:'absolute', top: `290mm`, right:'5mm', fontSize:'.8em'}}>strana 1/{this.pages.length + 1}</div>
                {this.pages.map((itm, page)=>{
                    return(
                    <div style={{position:'absolute', top: `${(format.y * (page + 1) + 290)}mm`, right:'5mm', fontSize:'.8em'}}>strana {page+2}/{this.pages.length + 1}</div>
                )})} */}

                {/* <div style={{position:'absolute', top: `${(format.y  * (this.pages.length) + 285)}mm`, width:'100%', fontSize:'.8em', textAlign: "center", left: '0', fontStyle: 'italic'}}>Ovaj dokument štampan iz računara i punovažan je bez potpisa i pečata</div> */}
                {/* TOTALS */}

                

                {/* <div className='order-totals' style={{position: 'absolute', top: `${(format.y  * (this.pages.length + 1) - 80)}mm`, width: '200mm'}}>
                    {console.log('pl', this.pages.length)}
                    <p>Ukupno {this.props.order.subtotal} RSD</p>
                    {this.props.coupons.map(function(item, index){
                            return(
                            <p>Kupon {'"'+item.description+'"'} -{item.subtracted} RSD</p>
                            )
                    },this)}
                    {parseFloat(this.props.order.shipping_price) > 0 &&
                        <p>Poštarina {this.props.order.shipping_price} RSD</p>
                    }
                    <p style={{fontSize: '1.2em'}}>Ukupno za uplatu <b>{this.props.order.total} RSD</b></p>
                    <p>U cenu je uračunat PDV.</p>
                </div> */}

                {/* <div className="memorandum-footer">
                    <img src="/memorandum-footer.jpg" />
                </div> */}
                
            {/* </div>  */}

            


            {/* {this.props.products.length > 15 &&
                <Fragment>
                    <div style={{position:'absolute', top:'1374px', right:'40px', fontSize:'.8em'}}>strana 1/2</div>
                    <div style={{position:'absolute', top:'2788px', right:'40px', fontSize:'.8em'}}>strana 2/2</div>
                </Fragment>
            }  */}

            {/* {this.props.products.length <= 15 &&
                <div style={{position:'absolute', top:'1374px', right:'30px', fontSize:'.8em'}}>strana 1/1</div>
            }  */}
        </div>
    );
  }

}

export default OrderData;
