import React, { Component } from 'react';
import Service from "../components/Service";
import DateTime from '../components/DateTime';
import Select from '../components/Select';

var dto = new Date();
dto.setHours(0,0,0,0);

var dtf = new Date();
dtf.setMonth(dtf.getMonth() - 1);
dtf.setHours(0,0,0,0);

class Stats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            from: null,
            to: null,
            filter: null,
            dateTo: dto,
            dateFrom: dtf,
            sortDirection: 1,
            sortFilter: 'average',
            totalAverageDiscount: null,
            selectedStore: 0,
            stats: null

        }
    }

    componentDidMount(){
        //this.updateData();
    }

    sortDisplay =(n)=>{
        if(n == this.state.sortFilter){
            if(this.state.sortDirection){
                return '+';
            }
            return '-';
        }
    }


    getStats=()=>{
        this.setState({loading: 1});
        console.log(this.state);
        Service.get(`/stats/getProducts`,{filter: this.state.filter, 
            dateFrom: this.state.dateFrom, 
            dateTo: this.state.dateTo,
            sortDirection: this.state.sortDirection,
            sortFilter: this.state.sortFilter,
            store: this.state.selectedStore
        
        }, (res)=>{
           if(res.success){
               this.setState({products: res.products, totalAverageDiscount: res.average_discount, stats: res.stats});
           }
        })
    }

    setFilter=(event)=>{
        this.setState({filter: event.target.value}, ()=>{

            setTimeout(() => {
                //this.getStats();
            }, 500);  

            
        });
    }

    applyFilter=(event)=>{
        if(event.key && event.key == 'Enter'){
            this.getStats();
        }
        
    }

    handleDateFrom=(date)=>{
        this.setState({dateFrom: date}, ()=>{
                //this.getStats();
        });
        
    }

    handleDateTo=(date)=>{
        this.setState({dateTo: date}, ()=>{
                //this.getStats();
        });
        
    }

    changeFilter=(filter)=>{

        if(this.state.sortFilter == filter){
            this.setState({sortDirection: !this.state.sortDirection}, ()=>this.getStats())
        }else{
            this.setState({sortFilter: filter}, ()=>this.getStats())
        }
    }

    render() {
        return (
            <div>
                <h1>Statistike</h1>
                <div className="quarter padding-right" style={{fontSize: '.8em'}}>
                    <input placeholder="pretraga..." type="text" value={this.state.filter} onChange={this.setFilter} onKeyDown={this.applyFilter} />
                </div>
                <div className="half" style={{fontSize: '.8em'}}>
                od: <DateTime selected={this.state.dateFrom} showTimeSelect={false} onChange={this.handleDateFrom}/>
                &nbsp;do: <DateTime selected={this.state.dateTo} showTimeSelect={false} onChange={this.handleDateTo}/>
                <Select id="id" value="name" placeholder="sajt..." selected={this.state.selectedStore} options={[{id: 0, name: 'svi sajtovi'},{id: 1, name: 'Harmonija'},{id: 2, name: 'Stela'},{id: 3, name: 'Publikpraktikum'}]} onSelect={(res)=>this.setState({selectedStore: res, page: 1})} />
                </div>
                <div className="quarter align-right">
                    <button onClick={this.getStats}>Pretraži</button>
                </div>
                {this.state.totalAverageDiscount &&
                    <div className="align-right">
                        <p>Totalni prosečni rabat: {this.state.totalAverageDiscount}%</p>
                        {this.state.stats &&
                            <div>
                                <h3>iz porudzbina:</h3>
                                <p>Zbir cena isporuke: {this.state.stats.ordersShippingPrice}</p>
                                <p>Zbir cena proizvoda: {this.state.stats.ordersProductsPrice}</p>
                                <p>Zbir cena totala: {this.state.stats.ordersTotalsPrice}</p>
                                <h3>iz proizvoda:</h3>
                                <p>Zbir punih cena: {this.state.stats.totalFullSum}</p>
                                <p style={{color: this.state.stats.totalSum == this.state.stats.ordersProductsPrice ? 'black' : 'red' }}>Zbir cena proizvoda: {this.state.stats.totalSum}</p>
                                <p>Ukupno + isporuka &gt; total: {this.state.stats.totalWithShipping}</p>
                            </div>
                        }
                        
                        
                        
                        
                        <p className="hint">*Rezultat se odnosi na odabrani period i odabrane proizvode</p>
                    </div>
                 }
                <table className="stats">
                    <tbody>
                    <tr>
                        <td>id</td>
                        <td className="title" onClick={()=>this.changeFilter('title')}>Naslov {this.sortDisplay('title')}</td>
                        <td>sku</td>
                        <td onClick={()=>this.changeFilter('average_discount')}>Prosečni rabat {this.sortDisplay('average_discount')}</td>
                        <td className="quantity" onClick={()=>this.changeFilter('quantity')}>Količina {this.sortDisplay('quantity')}</td>
                        <td>Ukupno</td>
                        <td onClick={()=>this.changeFilter('average')}>Prosečno {this.sortDisplay('average')}</td>
                        {/* <td>Najveca Cena</td>
                        <td>Najmanja Cena</td> */}
                        
                    </tr>
                    {this.state.products.map((item, index)=>{
                        return (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>{item.sku}</td>
                            <td><b>{item.average_discount}%</b></td>
                            <td className="quantity">{item.quantity}</td>
                            <td>{item.total}</td>
                            <td>{item.average}</td>
                            {/* <td>{item.max}</td>
                            <td>{item.min}</td> */}
                            
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default Stats;
