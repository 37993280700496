import React, { Component } from 'react';
import Service from "../components/Service";
import * as XLSX from "xlsx";


class Fiscalisation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            response: null,
            loading: false,
            inputType: null,
            singleOrder: null
        }
    }

    componentDidMount(){
        //this.updateData();
    }

    showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
          console.log(text)
          let res = text.split(/\r?\n/);
          let orders = [];
          res.map(item=>{
            if(item){
                let o = item.split('#')
                if(o[12]){
                    let content = o[12].split('jku broj ');
                    if(content[1]){
                        orders.push(content[1].slice(0,12));
                    }
                }
            }
        })
          console.log(orders);
          this.setState({orders: orders, inputType: null});
        //   alert(text)
        };
        reader.readAsText(e.target.files[0])
      }

      showFile2 = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
          console.log(text);
          let res = text.split(/\r?\n/);
          let orders = [];
          res.map(item=>{
            if(item){
                let o = item.split('jku broj ');
                if(o[1] && o[1].slice(0,2) == 'TJ'){
                    orders.push(o[1].slice(0,12));
                }
                
                // if(o[12]){
                //     let content = o[12].split('jku broj ');
                //     if(content[1]){
                //         orders.push(content[1].slice(0,12));
                //     }
                // }
            }
        })
          console.log(orders);
          this.setState({orders: orders, inputType: null});
        //   alert(text)
        };
        reader.readAsText(e.target.files[0])
      }

      onChange = (e) => {
        let [file] = e.target.files;
        let reader = new FileReader();
    
        reader.onload = (evt) => {
            let bstr = evt.target.result;
            let wb = XLSX.read(bstr, { type: "binary" });
            let wsname = wb.SheetNames[0];
            let ws = wb.Sheets[wsname];
            let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          console.log(data);

          // parse csv
            let res = data.split(/\r?\n/);
            let orders = [];
            res.map((item, index)=>{
                if(item && index > 0){
                    let o = item.split(',')
                    if(o[2] == 'Successful' && o[1] == 'Sale'){
                        orders.push(o[4]);

                    }
                }
            })
            console.log('orders', orders);
            this.setState({orders: orders, inputType: 'cards'});
        };
        reader.readAsBinaryString(file);
      };
    
      sendOrders=()=>{
        this.setState({loading: true});
            if(this.state.inputType == 'cards'){
                Service.get('/orders/fiscalisationCards', {orders: this.state.orders}, (res)=>{
                    this.setState({response: res.data, loading: false}, ()=>{
                        console.log('res', this.state.response);
                    });
                })
            }else{
                Service.get('/orders/fiscalisation', {orders: this.state.orders}, (res)=>{
                    this.setState({response: res.data, loading: false}, ()=>{
                        console.log('res', this.state.response);
                    });
                })
            }
      }

      sendSingle=()=>{
          console.log([this.state.singleOrder]);
        Service.get('/orders/fiscalisationCards', {orders: [this.state.singleOrder]}, (res)=>{
            this.setState({response: res.data, loading: false}, ()=>{
                console.log('res', this.state.response);
            });
        })
      }

    render() {
        return (
        <div>
            <h1>Fiskalizacija</h1>
            <label>Dexpress (očekuje .txt fajl od <strong>Marka</strong>, polja su odvojena znakom #):</label>
            <input type="file" onChange={(e) => this.showFile(e)} />
            <label>Dexpress (očekuje .txt fajl od <strong>Blagice</strong>):</label>
            <input type="file" onChange={(e) => this.showFile2(e)} />
            <label>Kartice (očekuje excel fajl):</label>
            <input type="file" onChange={this.onChange} />
            <label>Pojedinačna porudzbina:</label>
            <input type="text" onChange={(event)=>{this.setState({singleOrder: event.target.value})}} value={this.state.singleOrder}/>
            <button onClick={this.sendSingle}>Pošalji odabranu porudžbinu</button>
            
            {this.state.orders.length > 0 &&
                <div>
                    <h3>Info:</h3>
                    <p>Ukupno je pronadjeno {this.state.orders.length} porudzbine za fiskalizaciju</p>
                    <button onClick={this.sendOrders} >Pošalji</button>
                </div>
            }

            {this.state.response &&
                <div>
                    <h3>Info:</h3>
                    <p>Uspesno je poslato {this.state.response.sent.length} porudzbine na fiskalizaciju</p>
                    <p>Prethodno poslato: {this.state.response.fiscalised.length}</p>
                    <p>Greske pri slanju: {this.state.response.errors.length}</p>
                    <p>Preskoceno (neodgovarajuci kod): {this.state.response.omited.length}</p>
                    <p>Nepronadjene: {this.state.response.missing.length}</p>
                </div>
            }
                
        </div>
        );
    }

}

export default Fiscalisation;
