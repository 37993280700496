import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Fragment } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import InfoBox from '../components/InfoBox';
import ReactToPrint from 'react-to-print';
import { CSVReader } from 'react-papaparse';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from '../components/Select';
import DateTime from '../components/DateTime';

function pad(n) {
    return (n < 10) ? ("0" + n) : n;
}

function parseDate(date){
    let res = null;
    if(date){
        let r = new Date(date);

        let month = pad(r.getMonth() + 1);
        let day = pad(r.getDate());
        res = r.getFullYear() + '-' + month + '-' + day + ' ' + pad(r.getHours()) + ':' + pad(r.getMinutes()) + ':' + pad(r.getSeconds());
    }

    return res;

}



const css = `
    @page {
        size: A4;
        margin: 10mm;
    }`;

class Document extends Component {

    constructor(props) {
    super(props);
    this.state = {
        selectedWarehouse: 0,
        warehouseName: '',
        selectedWarehouseFrom: 0,
        warehouseFromName: '',
        warehouses: [],
        products: [],
        modalVisible: false,
        action: '',
        selectedProduct: null,
        selectedProductName: '',
        selectedQuantity: '',
        filter: '',
        documentType: 0,
        currentProduct: null,
        currentTitle: null,
        currentSku: null,
        currentQuantity: null,
        selectedProducts: [],
        editQuantityValue: null,
        updateProductId: null,
        currentIndex: null,
        warehouseFromName: null,
        warehouseToName: null,
        userName: '',
        documentCreatedAt: null,
        documentModifiedAt: null,
        status: 0,
        date: null,
        positions: [],
        currentPosition: null,
        currentOutPosition: null,
        approvedUserId: null,
        client: null,
        note: null,
        users: [],
        issues: [],
        issueId: null
    }
    this.inputFilterRef = React.createRef();
    this.fileInput = React.createRef();
    this.applyButton = React.createRef();
  }

  editQuantity=(event)=>{
      this.setState({editQuantityValue: event.target.value});
  }

  editProductQuantity=(event)=>{
      this.setState({editQuantityValue: null,  updateProductId: event.target.getAttribute('data-product-id')})
  }

  cancelEditQuantity=()=>{
    this.setState({editQuantityValue: null, updateProductId: null});
  }

  applyEditProductQuantity=(event)=>{
    if(event.key && event.key !== 'Enter'){
        return;
    }
    
    if(!this.state.updateProductId || !this.state.editQuantityValue){
        return;
    }
    let p = []; 
    this.state.selectedProducts.map(item=>{
        if(item.product_id == this.state.updateProductId || item.id == this.state.updateProductId){
            item.quantity = this.state.editQuantityValue;
        }
        p.push(item);
    })


    this.setState({selectedProducts: p, editQuantityValue: null, updateProductId: null});
  }

  componentDidMount(){
    this.getWarehouses();
    this.getPositions();
    this.getAdminUsers();
    this.getIssues();
  }

  getAdminUsers=()=>{
      Service.get('/warehouses/getAdminUsers', {}, (res)=>{
          if(res.success){
              this.setState({users: res.users});
          }
      })
  }

  getIssues=()=>{
    Service.get('/warehouses/getDocumentsIssues', {}, (res)=>{
        if(res.success){
            this.setState({issues: res.issues});
        }
    })
}

  selectDocumentType=(event)=>{
    this.setState({documentType: event.target.value})
  }

  selectWarehouse=(event)=>{
      let name = event.target.options[event.target.selectedIndex].text;

    this.setState({selectedWarehouse: event.target.value, warehouseName: name}, ()=>{
        console.log(this.state);

    })
  }
  selectWarehouseFrom=(event)=>{
    let name = event.target.options[event.target.selectedIndex].text;

  this.setState({selectedWarehouseFrom: event.target.value, warehouseFromName: name})
}

    setFilter=(event)=>{
        this.setState({filter: event.target.value,
            currentProduct: null,
            currentIndex: null,
            currentTitle: null,
            currentSku: null,
            currentQuantity: null}, ()=>{
            this.getProducts();
        });
    }

    selectProduct=(event)=>{
        this.setState({currentProduct: event.currentTarget.getAttribute('data-product-id'),
        currentTitle: event.currentTarget.getAttribute('data-product-title'),
        currentSku: event.currentTarget.getAttribute('data-product-sku')
    
    })
    }

    setCurrentQuantity=(event)=>{
        this.setState({currentQuantity: event.target.value})
    }

    getPositionName=(position)=>{
        let pos;
        this.state.positions.map(item => {
            
            if(item.id == position){
                pos = item.name;
                
            }
        })
        return pos;
    };

    addProduct=(event)=>{

        if(event.key && event.key !== 'Enter'){
            
            return;
        }

        // let existing;
        // this.state.selectedProducts.map(item=>{
        //     if(item.id == this.state.currentProduct){
        //         existing = true;
        //         alert('Greska! Ovaj proizvod je vec unet.');
                
        //     }
        // })

        if(!this.state.currentQuantity || this.state.currentQuantity == 0){
            alert('Greska! Niste uneli kolicinu');
            return;
        }

        // if(!this.state.currentPosition || this.state.currentPosition == ''){
        //     alert('Greska! Niste odabrali poziciju proizvoda u magacinu');
        //     return;
        // }

        // if(existing){
        //     this.setState({
        //         currentProduct: null,
        //         currentTitle: null,
        //         currentSku: null,
        //         currentQuantity: null}, ()=>{
        //         //this.getProducts();
        //     });
        //     return;
        // }
        //
        this.setState({selectedProducts: [...this.state.selectedProducts, {
                id: this.state.currentProduct,
                title: this.state.currentTitle,
                sku: this.state.currentSku,
                quantity: this.state.currentQuantity,
                position_id: this.state.currentPosition,
                position_out_id: this.state.currentOutPosition
            }],
            currentProduct: null,
            currentTitle: null,
            currentSku: null,
            currentQuantity: null,
            currentPosition: null,
            currentOutPosition: null,
            filter: ''
        
        }, ()=>{
            this.inputFilterRef.current.focus();
        })
    }

    getPositions=()=>{
        Service.get("/warehouses/getPositions", {}, (res)=>{
            if(res.success){
              this.setState({positions: res.positions}, ()=>{
              });
            }
          
        });
    }

  getWarehouses = () => {
      Service.get("/warehouses/get", {}, (res)=>{
          if(res.success){
            this.setState({warehouses: res.warehouses}, ()=>{
                if(this.props.match.params.id){
                    this.getDocument();
                }
            });
          }
        
      });
  }

    getDocument=()=>{
        Service.get("/warehouses/getDocument", {id: this.props.match.params.id}, (res)=>{
            if(res.success){
                let doc = res.document;
                this.setState({
                    selectedWarehouse: doc.wh_to, 
                    documentType: doc.type, 
                    selectedWarehouseFrom: doc.wh_from,
                    selectedProducts: doc.products,
                    warehouseFromName: doc.wh_from_name,
                    warehouseToName: doc.wh_to_name,
                    userName: doc.user_name,
                    documentCreatedAt: doc.created_at,
                    documentModifiedAt: doc.modified_at,
                    status: doc.status,
                    note: doc.note,
                    approvedUserId: doc.approved_user_id,
                    client: doc.client,
                    issueId: doc.issue_id,
                    // date: new Date(doc.document_date)
                    date: doc.document_date
                })
            }
        })
    }

  getProducts=(filter)=>{
        Service.get("/warehouses/getFilteredProducts", {filter: this.state.filter}, (res)=>{
            if(res.success){
              this.setState({products: res.products});
            }
        });
  }

  getProductsAsync=(filter)=>{
    Service.get("/warehouses/getFilteredProducts", {filter}, (res)=>{
        if(res.success){
          this.setState({products: res.products});
        }
    });
}

    closeModal = ()=>{
        this.setState({modalVisible: false, editQuantityValue: null, updateProductId: null});
    }

    showModal = (event)=>{
        let action = event.target.getAttribute('data-mode');
        let productId = event.target.getAttribute('data-id');
        let productName = event.target.getAttribute('data-name');
        this.setState({modalVisible: true, action: action, selectedQuantity: '', selectedProduct: productId, selectedProductName: productName});
    }

    changeQuantity = (event) => {
        this.setState({selectedQuantity: event.target.value});
    }

    getWarehouseName=(warehouseId)=>{
        this.state.warehouses.map(item=>{
            if(parseInt(warehouseId) == item.id){
                return item.name;
            }
        })
    }

    createDocument=()=>{
        Service.get("/warehouses/createDocument", {
            products: this.state.selectedProducts,
            wh_from: this.state.selectedWarehouseFrom,
            wh_to: this.state.selectedWarehouse,
            type: this.state.documentType,
            approvedUserId: this.state.approvedUserId,
            note: this.state.note,
            client: this.state.client,
            documentId: this.props.match.params.id,
            issueId: this.state.issueId,
            // documentDate: this.state.date ? parseDate(this.state.date) : ''
            documentDate: this.state.date ? this.state.date : ''
        }, (res)=>{
            if(res.success){
                if(res.updated){
                    alert('dokument je uspesno izmenjen');
                    this.props.history.push("/documents");
                }else{
                    alert('dokument je uspesno napravljen');
                    this.props.history.push("/documents");
                }
            }
          
        });
    }

    removeProduct=(event)=>{

        let p = this.state.selectedProducts.filter((item, index) => index != event.target.getAttribute('data-product-index'));
        this.setState({selectedProducts: p});
    }

    selectProductArrow=(event)=>{
        
        let index = this.state.currentIndex;
        if(event.keyCode == 38){
            if(index > 0 && index != null){
                index --;
            }
            if(!index){
                index = 0;
            }
            this.setState({currentIndex: index})
        }
        if(event.keyCode == 40){
            if(index < this.state.products.length && index != null){
                index ++;
            }
            if(!index){
                index = 0;
            }
            this.setState({currentIndex: index})
        }

        if(event.keyCode == 13){

                let product = this.state.products[index];

                if(product){

                    this.setState({currentProduct: product.id,
                    currentTitle: product.title,
                    currentSku: product.sku, 
                    currentIndex: null})
                }

        }

        
        
    }

    approveDocument=()=>{
        Service.get('/warehouses/createDocument', {
            products: this.state.selectedProducts,
            wh_from: this.state.selectedWarehouseFrom,
            wh_to: this.state.selectedWarehouse,
            type: this.state.documentType,
            status: 1,
            documentId: this.props.match.params.id}, (res)=>{
            this.getDocument();
        })
    }

    deleteDocument=()=>{
        if(window.confirm('Da li ste sigurni da zelite da izbrisete dokument?')){
            Service.get('/warehouses/deleteDocument', {documentId: this.props.match.params.id}, (res)=>{
                return(<Redirect to='/documents' />);
            })
        }
    }

    handleReadCSV = (data) => {

        let skus = [];
        let fieldIndex;
        let quantityIndex;

        data.data[0].map((item, index) => {
            if(item.toLowerCase() == 'sku'){
                fieldIndex = index;
            }
            if(item.toLowerCase() == 'stanje'){
                quantityIndex = index;
            }
        })

        if(fieldIndex == null){
            alert('CSV nije validan. Fali kolona SKU');
            return;
        }
        if(quantityIndex == null){
            alert('CSV nije validan. Fali kolona STANJE');
            return;
        }
        
        let quantities = [];
        data.data.map((item, index) => {
            if(index > 0){
                skus.push(item[fieldIndex]);
                quantities.push({sku: item[fieldIndex], quantity: item[quantityIndex]});
            }
        })

        Service.get('/warehouses/getProductsBySku', {products: skus}, (res)=>{
            let products = [];
            res.products.map(product => {
                
                quantities.map(item => {
                    if(item.sku == product.sku){
                        product.importQuantity = item.quantity;
                    }
                })
                if(parseInt(product.importQuantity) > 0){
                    products.push({id: product.id, title: product.title, sku: product.sku, quantity: product.importQuantity});
                }
            });

            this.setState({selectedProducts: products}, ()=>{
            });
        })
        return;


        
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }
    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }

    handleFromDateChange=(date)=>{
        
        this.setState({date: date});
    }

    setCurrentPosition=(value)=>{
        this.setState({currentPosition: value}, ()=>{
            this.applyButton.current.focus();
        });
    }

    setCurrentOutPosition=(value)=>{
        this.setState({currentOutPosition: value}, ()=>{
            this.applyButton.current.focus();
        });
    }

    setApprovedUser=(user)=>{
        this.setState({approvedUserId: user});
    }

  

  render() {
    return (
      <div>
          <h1>MAGACIN</h1>
            {this.props.match.params.id ? (
                <h2>DOKUMENT - {this.props.match.params.id}</h2>
            ) : (
                <h2>NOVI DOKUMENT</h2>
            )}
            {this.state.editQuantityValue} - {this.state.updateProductId}
            <div className="document-top">
                
                {this.props.match.params.id &&
                <button><ReactToPrint
                    // trigger={() => <a href="#">Štampaj</a>}
                    trigger={() => <span>Štampaj</span>}
                    content={() => this.componentRef}
                /></button>
                
                }
                
                {!this.state.status &&
                    <button className="document-save" onClick={this.approveDocument}>Odobri dokument</button>
                }
                <button className="document-save" style={{background: '#990000'}} onClick={this.deleteDocument}>Obriši dokument</button>
                
                <button className="document-save" onClick={this.createDocument}>Sačuvaj</button>
            </div>
            
            <div>
                
                <section className='half'>
                    <label>Tip dokumenta:</label>
                    <Select placeholder="odaberi tip dokumenta..." id='id' value='name' options={[{id: 1, name: 'Ulaz'}, {id: 2, name: 'Prenos'}, {id: 3, name: 'Izlaz'}]} selected={this.state.documentType} onSelect={(res)=>{this.setState({documentType: res})}}/>
                <hr/>
                {this.state.documentType > 1 && this.state.documentType != 0 &&
                    <div className="document-selector">
                        <label>Magacin izlaz &gt;</label>
                        <Select placeholder="izlaz..." id='id' value='name' debug='x' options={this.state.warehouses} selected={this.state.selectedWarehouseFrom} onSelect={(res)=>{this.setState({selectedWarehouseFrom: res})}}/>
                        {/* <select onChange={this.selectWarehouseFrom} value={this.state.selectedWarehouseFrom}>
                            <option value={0} selected="selected" disabled="disabled">odaberi izlazni magacin</option>
                            {this.state.warehouses.map(item=>{
                                
                                return(
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select> */}

                    </div>
                }
                {this.state.documentType < 3 && this.state.documentType != 0 &&
                    <div className="document-selector">
                        <label> &gt; Magacin ulaz</label>
                        <Select placeholder="ulaz..." id='id' value='name' debug='x' options={this.state.warehouses} selected={this.state.selectedWarehouse} onSelect={(res)=>{this.setState({selectedWarehouse: res})}}/>
                            {/* <select onChange={this.selectWarehouse} value={this.state.selectedWarehouse}>
                                <option value={0} selected="selected" disabled="disabled">odaberi ulazni magacin</option>
                                {this.state.warehouses.map(item=>{
                                    
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>  */}

                    </div>
                }
                {!this.state.documentType &&
                    <InfoBox message="Odaberite tip dokumenta" color="#990000"/>
                }
                {/* <DatePicker
                    id="fromDate"
                    selected={this.state.date}
                    onChange={this.handleFromDateChange}
                    //dateFormat={'yyyy-MM-dd'}
                    showYearDropdown={true}
                    autoComplete="off"
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm"
                    
                >
                    <div style={{color: 'red'}}>Obavezno odabrati satnicu zbog umetanja dokumenata!</div>
                </DatePicker> */}
                <div>
                    <label>Datum:</label>
                    <DateTime placeholder="datum" text="Obavezno odabrati satnicu zbog umetanja dokumenata!" selected={this.state.date} showTimeSelect onChange={this.handleFromDateChange}/>
                </div>
                <label>Komentar:</label>
                <textarea className="document-search" placeholder="komentar..." type="text" value={this.state.note} onChange={(event)=>this.setState({note: event.target.value})} />
                </section>
                <div className='half' style={{paddingLeft: '.5em'}}>
                    <section>
                <label>Odobrio:</label>
                <Select placeholder="odobrio..." id='id' value='name' options={this.state.users} selected={this.state.approvedUserId} onSelect={(res)=>{this.setApprovedUser(res); console.log('set', res)}}/>
                <label>Komitent:</label>
                <input className="document-search" placeholder="komitent..." type="text" value={this.state.client} onChange={(event)=>this.setState({client: event.target.value})} />
                
                <label>Komentar:</label>
                <Select placeholder="problem..." id='id' value='name' options={this.state.issues} selected={this.state.issueId} onSelect={(res)=>this.setState({issueId: res})}/>
                </section>
                </div>
            </div>
            <hr />
            {/* <div className="document-search">
            <h3>Pretraga</h3>
            <input placeholder="pretraga..." type="text" value={this.state.filter} onChange={this.setFilter} />
            </div>
            <hr /> */}
            <div className="document-table">
            <h3>Roba</h3>
            {this.state.documentType == 1 &&
                <>
                    <button onClick={this.handleImportOffer}>Ubaci iz CSV-a</button>
                    <CSVReader
                        onFileLoaded={this.handleReadCSV}
                        inputRef={this.fileInput}
                        style={{display: 'none'}}
                        onError={this.handleOnError}
                        />
                </>
                }
            <input ref={this.inputFilterRef} className="document-search" placeholder="pretraga..." type="text" value={this.state.filter} onChange={this.setFilter} onKeyDown={this.selectProductArrow} />
            {/* <Select id='id' value='title' async onFilter={(filter)=>{this.getProductsAsync(filter)}} placeholder="pozicija izlaz..." options={this.state.products} onSelect={(res)=>{this.setState({currentProduct: res})}} /> */}
                <table className="cms-table document-search-table" >
                    
                    {/* <thead>
                        <tr>
                            <td>id</td>
                            <td>sku</td>
                            <td>naziv</td>
                            <td></td>
                        </tr>
                    </thead> */}
                    <tbody>
                        {this.state.products.map((item, index)=>{ 
                            if(this.state.currentProduct){
                                if(item.id == this.state.currentProduct){
                                    return(
                                        <tr data-product-id={item.id} className="document-product table-document-add-details">
                                            <td>{item.id}</td>
                                            <td>{item.sku}</td>
                                            <td>{item.title.substring(0,50)}</td>

                                            <td >
                                                <input type="number" value={this.state.currentQuantity} onChange={this.setCurrentQuantity} autoFocus onKeyDown={this.addProduct}/>
                                                {this.state.documentType > 1 &&
                                                    <Select id='id' value='name' placeholder="pozicija izlaz..." options={this.state.positions} selected={this.state.currentOutPosition}  onSelect={(res)=>{this.setCurrentOutPosition(res)}} />
                                                }
                                                {this.state.documentType < 3 &&
                                                    <Select id='id' value='name' placeholder="pozicija ulaz..." options={this.state.positions} selected={this.state.currentPosition}  onSelect={(res)=>{this.setCurrentPosition(res)}} />
                                                }
                                                <button ref={this.applyButton} onClick={this.addProduct}>Dodaj</button>
                                            </td>
                                        </tr>
                                    )
                                }
                            }else{
                                //if(item.id == this.state.currentProduct){
                                return(
                                    
                                    <tr className={index == this.state.currentIndex ? "document-product table-row-selected" : "document-product"} data-product-id={item.id} data-product-title={item.title.substring(0,50)} data-product-sku={item.sku}   onClick={this.selectProduct}>
                                        <td>{item.id}</td>
                                        <td>{item.sku}</td>
                                        <td>{item.title.substring(0,50)}</td>
                                        <td></td>
                                    </tr>
                                ) 
                                
                            }
                        })}
                    </tbody>
                </table>
                </div>
                <div className="document-table">
                <h3>Proizvodi</h3>
                <table className="cms-table" >
                    
                    <thead>
                        <tr>
                            <td>id</td>
                            <td>sku</td>
                            <td>naziv</td>
                            {this.state.documentType > 1 &&
                                <td>pozicija izlaz</td>
                            }
                            {this.state.documentType < 3 &&
                                <td>pozicija ulaz</td>
                            }
                            <td>količina</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.selectedProducts.map((item, index)=>{ 
                            return(
                                <tr >
                                    <td>{item.id}</td>
                                    <td>{item.sku}</td>
                                    <td>{item.title}</td>
                                    {this.state.documentType > 1 &&
                                        <td>{this.getPositionName(item.position_out_id)}</td>
                                    }
                                    {this.state.documentType < 3 &&
                                        <td>{this.getPositionName(item.position_id)}</td>
                                    }
                                    {this.state.updateProductId == item.id ? (
                                        <input type="number" value={this.state.editQuantityValue} onChange={this.editQuantity} onKeyPress={this.applyEditProductQuantity} autoFocus onBlur={this.cancelEditQuantity} />
                                    ):(
                                        <td data-product-id={item.id} onClick={this.editProductQuantity}>{item.quantity}</td>
                                    )

                                    }
                                    
                                    <td><button data-product-index={index} onClick={this.removeProduct}>Izbaci</button></td>
                                </tr> 
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="popup" style={{display: this.state.modalVisible ? 'block' : 'none'}} >
                <div className="popup-content">
                    <div className="popup-close" onClick={this.closeModal}>X</div>
                    <input type="number" value={this.state.editQuantityValue} onChange={this.editQuantity}/>
                    <button onClick={this.applyEditProductQuantity}>Primeni</button>
                </div>
                
            </div>
            <style>{css}</style>
            <div style={{display: 'none'}}> 
                <div ref={el => (this.componentRef = el)}>
                    <div className="document-print">   

                                {/* <div className="company-data"> */}
                                    <img src="/memorandum-header.jpg" />
                                {/* </div> */}
                                <div style={{width: "98%", margin: 'auto'}}>
                                    <div>
                                       <p>Tip dokumenta: {
                                           this.state.documentType == 1 &&
                                                <span>ulaz</span>
                                           }
                                           {
                                           this.state.documentType == 2 &&
                                                <span>prenos</span>
                                           }
                                           {
                                           this.state.documentType == 3 &&
                                                <span>izlaz</span>
                                           }
                                           
                                    <br/>br: {this.props.match.params.id}
                                    <br/>odgovorno lice: {this.state.userName}
                                    <br/>dokument kreiran: {this.state.documentModifiedAt}
                                    {this.state.documentModifiedAt != this.state.documentModifiedAt &&
                                        <fragment><br/>dokument izmenjen: {this.state.documentModifiedAt}</fragment>
                                     }
                                    </p>
                                    </div>
                                <div className="document-print-half">
                                    {this.state.warehouseFromName &&
                                        <p>Izlaz: <b>{this.state.warehouseFromName}</b></p>
                                    }
                                </div>
                                <div className="document-print-half align-right">
                                    {this.state.warehouseToName &&
                                        <p>Ulaz: <b>{this.state.warehouseToName}</b></p>
                                    }
                                </div>
                                {/* <div className="orders-left">  
                                    <div className="orders-customer">
                                        <b>Porudžbenica</b><br/>
                                        Ime: <br/>
                                    </div>
                                </div>
                                <div className="orders-right">
                                    <p>neki tekst ide ovde</p>
                                </div> */}

                            
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>Br.</td>
                                            <td>SKU</td>
                                            <td>Naziv</td>
                                            <td>izlaz boks</td>
                                            <td>Količina</td>
                                            <td>ulaz boks</td>
                                        </tr>
                                        </thead>
                                        {this.state.selectedProducts.map(function(item, index){
                                                return(
                                                    // <fragment>
                                                    <tbody>
                                                        <tr>
                                                            <td className="align-right">{index + 1}.</td>
                                                            <td>{item.sku}</td>
                                                            <td>{item.title}</td>
                                                            <td className="align-center">{this.getPositionName(item.position_out_id)}</td>
                                                            <td className="align-center"><b>{item.quantity}</b></td>
                                                            <td className="align-center">{this.getPositionName(item.position_id)}</td>
                                                        </tr>
                                                    </tbody>
                                                    
                                                    )

                                        },this)
                                        }
                                    </table>
                                </div>
                            </div>
                 </div>
            </div>
      </div>
    );
  }

}

export default Document;
