import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import {Link} from 'react-router-dom';

const API = process.env.REACT_APP_API;

class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
        discounts: []
    }
  }

  componentDidMount(){
    this.getDiscounts();
  }

  selectStore=(event)=>{
    this.setState({storeId: event.target.value},()=>{
      this.getActions();
    })
  }


  handleInput = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  getDiscounts = () => {
    Service.get('/orders/discounts',{},(res)=>{
        if(res.success){
          this.setState({discounts: res.discounts});
        }
    });
  }



  render() {
    return (
      <div>
          <h1>Popusti</h1>
            <div>              
                    <table className='cms-table'>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Ime</th>
                            <th>Status</th>
                            <th>Prodavnica</th>
                            <th>Db Name</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.discounts.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.description}</td>
                                    <td>{item.status == 0 ? "Neaktivan" 
                                        : item.status == 1 ? "Aktivan" 
                                        : null}</td>
                                    <td>{Service.getStoreName(item.store_id)}</td>
                                    <td>{item.name}</td>
                                    <td><Link to={'/discount/'+item.name} ><button>Pogledaj</button></Link></td>
                                </tr>
                                )
                            },this)
                        }
                        </tbody>
                    </table>


          </div>

          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          {/* <h2>Privremeno dugme za brisanje proizvoda:</h2>
          <button onClick={() => this.check('test')}>IZVBRISI PROIZVODE</button> */}
      </div>
    );
  }

}

export default Discounts;
