import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

const storeNames = {
  [1]: "Harmonija knjige",
  [2]: "Stela knjige",
  [3]: "Publik praktikum"
}

const API = process.env.REACT_APP_API;

class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
        storeId: '3',
        createAction: false,
        actionName: '',
        actions: []
    }
  }

  componentDidMount(){
    this.getActions();
  }


  deleteAllProducts(i){
      alert('BRISANJE PROIZVODA JE ONEMOGUCENO.');
    // Service.get('/products/clear',{},(res)=>{
    //       alert('PROIZVODI OBRISANI!');
    //   })
    
  }

  check(i){
    //alert('vojno je car: ' + i);
    if (window.confirm("Izbrisi sve proizvode, stvarno???")) { 
      this.deleteAllProducts(i);
    }
  }

  selectStore=(event)=>{
    this.setState({storeId: event.target.value},()=>{
      this.getActions();
    })
  }

  isCreatingAction=()=>{
    this.setState({createAction: !this.state.createAction})
  }

  handleInput = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  createAction=()=>{
    Service.get('/actions/create',{
      name: this.state.actionName,
      storeId: this.state.storeId  
    },(res)=>{
        this.setState({createAction: false},()=>{
          this.getActions();
        })
    });
  }

  getActions=()=>{
    Service.get('/actions/getactions',{
      storeId: this.state.storeId  
    },(res)=>{
        if(res.success){
          this.setState({actions: res.data.actions});
        }
    });
  }

  toPageAction=(actionId)=>{
    window.location.href = '/action/'+actionId;
  }

  render() {
    return (
      <div>
          <h1>Akcije i popusti </h1>
          <select className="chat-selector" value={this.state.storeId} onChange={this.selectStore} >
            <option value={3}>Publik Praktikum</option>
            <option value={1}>Harmonija</option>
            <option value={2}>Stela</option>
          </select>

          <button onClick={this.isCreatingAction}>Napravi akciju</button>
          {this.state.createAction === true &&
            <div>
              <label for="actionName">Ime: </label>
              <input type="text" id="actionName" value={this.state.actionName} onChange={this.handleInput} />
              <button onClick={this.createAction}>Potvrdi</button>
            </div>
          }
            <div>
                
                    <table className='cms-table'>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Ime</th>
                            <th>Status</th>
                            <th>Prodavnica</th>
                            <th>Akcija</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.actions.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.status == 0 ? "Neaktivna" 
                                        : item.status == 1 ? "Aktivna" 
                                        : null}</td>
                                        <td>{storeNames[item.store_id]}</td>
                                    <td><button onClick={() =>this.toPageAction(item.id)}>Akcija</button></td>
                                </tr>
                                )
                            },this)
                        }
                        </tbody>
                    </table>


          </div>

          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          {/* <h2>Privremeno dugme za brisanje proizvoda:</h2>
          <button onClick={() => this.check('test')}>IZVBRISI PROIZVODE</button> */}
      </div>
    );
  }

}

export default Actions;
