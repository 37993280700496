//import React, { Component } from 'react';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import renderHTML from 'react-render-html';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from '../components/Select';

const modules = {
  toolbar: [
    [{ 'header': [2, 3, false] }],
    ['bold', 'italic', 'underline','strike'],
    ['link'],
    [{ list: 'ordered' },
      { list: 'bullet' }],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'link', 'list', 'bullet'
]

var interval;
var intervalMessages;

export default function Ticket(props){

  const [messages, setMessages] = useState();
  const [message, setMessage] = useState('');
  const [ticket, setTicket] = useState();
  const [title, setTitle] = useState('');
  const [orderId, setOrderId] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');

  const [types, setTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [user, setUser] = useState(null);
  const [priority, setPriority] = useState();
  const [type, setType] = useState();
  const [status, setStatus] = useState();

  const [responsible, setResponsible] = useState();

  const ref = useRef(null);

  useEffect(()=>{
    Service.get('/tickets/getOptions', {}, res=>{
        setPriorities(res.priorities);
        setUsers(res.users);
        setTypes(res.types);
        setStatuses(res.statuses);
    })
    getTicket();

    

    return () => {
      clearInterval(intervalMessages);
    }
    
  }, []);

  const getMessages=()=>{
    console.log('props', props.ticket, ticket);
    Service.get('/tickets/getTicket', {ticketId: props.ticket}, (res)=>{
      setMessages(res.messages);
    })
    // Service.get('/tickets/getTicket', {ticketId: props.ticket.id, test: ticket ? ticket.id : 0}, (res)=>{
    //   setMessages(res.messages);
    // });
  }

  useEffect(()=>{
    if(ticket){
      console.log('ticket', ticket);
      setTitle(ticket.title);
      setOrderId(ticket.order_id ? ticket.order_id : '');
      setDescription(ticket.description);
      setUser(ticket.assigned_id);
      setType(ticket.type);
      setPriority(ticket.priority);
      setStatus(ticket.status);
      setDate(ticket.created_at);
      setResponsible(ticket.responsible_id);

      clearInterval(intervalMessages);
      intervalMessages = setInterval(()=>{
        console.log('getMessages', props.ticket);
        getMessages();
          
      }, 5000);
    }
  }, [ticket]);

  const getTicket=()=>{
    Service.get('/tickets/getTicket', {ticketId: props.ticket}, (res)=>{
      setMessages(res.messages);
      setTicket(res.ticket);
      console.log(res.ticket);
    });
  };

  useEffect(()=>{
    //setTicket(null);
    getTicket();
  }, [props.ticket]);

  useEffect(()=>{
    if(ref.current){
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      ref.current.scrollTop = 20000;
    }
  }, [messages]);

  

  const sendMessage=(event)=>{
    if(event.key){
      if(event.key == 'Enter' && !event.shiftKey){
        event.preventDefault();
        //console.log(message);
      }else{
        return;
      }
    }
    if(!message){
      return;
    }
    Service.get('/tickets/message', {ticketId: props.ticket, text: message}, res => {
      setMessage('');
      getTicket();
      
    })
    console.log(message);
  }

  // const updateTitle=(event)=>{
  //   if(event.key == 'Enter'){
  //     updateTicket();
  //   }
  // }

  // useEffect(()=>{
  //   if(ticket){
  //     if(type || user || priority || status){
  //       if(type != ticket.type
  //           || user != ticket.user
  //           || priority != ticket.priority
  //           || status != ticket.status
  //         ){
  //           updateTicket();
  //         }
  //     }
  //   }
  // }, [type, user, priority, status]);

  // useEffect(()=>{
  //   if(ticket && title){
  //     if(title != ticket.title){
  //       clearInterval(interval);
  //       interval = setTimeout(()=>{
  //           // console.log('save');
  //           if(ticket){
  //             updateTicket();
  //           }
  //           // if(props.onFilter){
  //           //     props.onFilter(filter);
  //           // }
  //       }, 1000);
  //         // updateTicket();
  //     }
  //   }
  // }, [title]);

  // useEffect(()=>{
  //   if(ticket && status){
  //     if(status != ticket.status){
  //         updateTicket();
  //     }
  //   }
  // }, [status]);

  // useEffect(()=>{
  //   if(ticket){
  //     if(priority != ticket.priority){
  //         updateTicket();
  //     }
  //   }
  // }, [priority]);

  // useEffect(()=>{
  //   if(ticket){
  //     if(type != ticket.type){
  //         updateTicket();
  //     }
  //   }
  // }, [type]);

  // useEffect(()=>{
  //   if(ticket){
  //     if(user != ticket.user){
  //         updateTicket();
  //     }
  //   }
  // }, [user]);

  // useEffect(()=>{
    
  //   if(responsible){
  //     if(responsible != ticket.responsible_id){
  //         updateTicket();
  //     }
  //   }
  // }, [responsible]);

  const handleDescription=(value)=>{

    setDescription(value);
    if(value != description && (value && description)){
      console.log('change', value, description);
    }
    // clearInterval(interval);
    //     interval = setTimeout(()=>{
    //         if(ticket){
    //           updateTicket();
    //         }

    //     }, 1000);

  }


  const deleteTicket=()=>{
    if(window.confirm('Da li ste sigurni da zelite da obrisete tiket?')){
      Service.get('/tickets/delete', {ticketId: props.ticket}, res => {
        props.onUpdate(true);
      })
    }
  }



  const updateTicket=()=>{
    if(window.confirm('Da li ste sigurni da zelite snimite promene?')){
      console.log('update', title, orderId, description, status, type,  user, priority, props.ticket)
      Service.get('/tickets/update', {title: title, description: description, status: status, type: type, assignedId: user, priority, ticketId: props.ticket, responsibleId: responsible, orderId: orderId}, ()=>{
        getTicket();
        props.onUpdate();
      })
    }
  }

// onKeyDown={updateTitle} 

    return (
      <div className="ticket">
        {/* <p>{props.ticket}</p> */}
          <p className="date">{date}</p> 
          <h3><input value={title} onChange={(event)=>setTitle(event.target.value)} data-key={'title'} /></h3>
          <label>Broj porudžbine:</label>
          <input value={orderId} onChange={(event)=>setOrderId(event.target.value)} data-key={'order-id'} />
          <div className="ticket-selectors">
              <div className="quarter">
                  <label>zadatak za:</label>
                  <Select id='id' value='name' placeholder="admin..." options={users} selected={user}  onSelect={(res)=>{setUser(res)}} />
              </div>

              <div className="quarter padding-horizontal">
                <label>tip problema:</label>
                  <Select id='id' value='name' placeholder="tip problema..." options={types} selected={type}  onSelect={(res)=>setType(res)} />
              </div>

              <div className="quarter padding-right">
                <label>prioritet:</label>
                  <Select id='id' value='name' placeholder="prioritet..." options={priorities} selected={priority} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setPriority(res)}} />
              </div>

              <div className="quarter">
              <label>status:</label>
                  <Select id='value' value='name' placeholder="status..." options={statuses} selected={status} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setStatus(res)}} />
              </div>
            </div>
            <div>
            <label>odgovorno lice:</label>
              <Select id='id' value='name' placeholder="odgovorno lice..." options={users} selected={responsible} onFilter={(filter)=>{console.log(filter, 'send filter')}} onSelect={(res)=>{setResponsible(res)}} />
            </div>
          {/* <div className="ticket-description" > */}
            {/* {renderHTML(props.ticket.description)} */}
            <ReactQuill value={description}
                tabindex="-1"
                className="text-editor"
                placeholder="sadrzaj tiketa..."
                modules={modules}
                formats={formats}
                onChange={handleDescription} 
            />
            {/* </div> */}
            <div>
              <button className="button" onClick={updateTicket}>Snimi promene</button> 
            </div>
            <div className="align-right">
              <button className="button-red" onClick={deleteTicket}>Obrisi tiket</button> 
            </div>

          <div className="ticket-messages" >
          {messages &&
            <ul>
            {messages.map((item, index) => {
              return(
                <li key={`ticket-${index}`} className={`ticket-message ${Auth.id == item.user_id ? 'my-message' : ''}`}>
                    <div className='ticket-message-username'>{item.user_name}</div>
                    <div className='ticket-message-text'>{item.text}</div>
                    <div className='ticket-message-date'>{item.modified_at}</div>
                  </li>
              )
              
            })}
            <li id={`ticket-end`} className="message-scroll-dummy" ref={ref}></li>
            </ul>
          
          }
          </div>
          <textarea onKeyDown={sendMessage} rows={3} value={message} onChange={(event)=>setMessage(event.target.value)} placeholder="ukucaj poruku..."/>
          <span className="hint">*pritisni <strong>'Shift + Enter'</strong> za novi red.</span>
          <div className="align-right">
            <button onClick={sendMessage} >Pošalji</button>
          </div>
      </div>
    );

}
