import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import DateSelector from '../components/DateSelector';
import InfoBox from '../components/InfoBox';
import { CSVReader } from 'react-papaparse';
import ProductsFilter from '../components/ProductsFilter';
//import DatePicker from "react-datepicker";

const storeNames = {
    [1]: "Harmonija knjige",
    [2]: "Stela knjige",
    [3]: "Publik praktikum"
  }

class Dates {

    static getYear (date) { return date.split(' ')[0].split('-')[0]} 
    static getMonth (date) { return date.split(' ')[0].split('-')[1]} 
    static getDay (date) { return date.split(' ')[0].split('-')[2]} 
    static getHour (date) { return date.split(' ')[1].split(':')[0]} 
    static getMinute (date) { return date.split(' ')[1].split(':')[1]} 
    static getSecond (date) { return date.split(' ')[1].split(':')[2]} 

}

class Action extends Component {
  constructor(props) {
    super(props);
    this.state = {
        products: [],
        newPrice: props.newPrice,
        newDiscount: props.newDiscount,
        actionName: '',
        status: 0,
        allCategories: [],
        startDate: null,
        endDate: null,
        storeId: null,
        yearFr: 2023,
        importProducts: [],
        active: 0
    }
    this.dateFrom = React.createRef();
    this.dateTo = React.createRef();
    this.fileInput = React.createRef();
  }

    componentDidMount(){

        console.log('day', Dates.getMinute('2021-08-12 23:45:00'))
        this.getAction();
        this.getCategories();
    }

    getCategories=()=>{
        Service.get('/categories/all',{},(res)=>{
            this.setState({allCategories: res.data},()=>{
                this.getActionCategories();
            })
        })
    }

    getActionCategories=()=>{
        Service.get('/actions/getActionCategories',{actionId: this.props.match.params.id},(res)=>{
            //this.setState({selectedCategories: res.data})
            let actionCategories = res.data;
            let cats = this.state.allCategories;
            cats.map(top => {
                top.subcategories.map(cat => {
                    cat.checked = false;
                    actionCategories.map(item => {
                        if(item.category_id == cat.id){
                            cat.checked = true;
                        }
                    })
                })
            })
            this.setState({allCategories: cats})
        })
    }

    addActionCategory=(event)=>{
        console.log(event.target.checked);
        let categoryId = event.target.getAttribute('data-category-id');
        if(event.target.checked){
            
            Service.get('/actions/addActionCategory',{categoryId, actionId: this.props.match.params.id},(res)=>{
                this.getActionCategories();
            })
        }else(
            Service.get('/actions/removeActionCategory',{categoryId, actionId: this.props.match.params.id},(res)=>{
                this.getActionCategories();
            })
        )
    }

    handleSticker=(event)=>{
        let productId = event.target.getAttribute('data-product-id');
        Service.get('/actions/setSticker',{productId, actionId: this.props.match.params.id},(res)=>{
            this.getAction();
        })
    }

    handleFreeShiping=(event)=>{
        let productId = event.target.getAttribute('data-product-id');
        Service.get('/actions/setFreeShipping',{productId, actionId: this.props.match.params.id},(res)=>{
            this.getAction();
        })
    }

    checkCategory=(id)=>{
        console.log(id);
    }

    expandTopCategory = (event) => {
      let e = event.currentTarget.parentNode.getElementsByClassName('categories-cont')[0];
        console.log('h', e.style.height);
      if(e.style.height == 'auto' || !e.style.height){
        e.style.height = '0';
        event.target.classList.add('arrow-closed');
        event.target.classList.remove('arrow-open');
      }else{
        e.style.height = 'auto';
        event.target.classList.add('arrow-open');
        event.target.classList.remove('arrow-closed');
      }
      console.log(e.style.height);
  }


    getAction=()=>{
        Service.get('/actions/getAction',{
            actionId: this.props.match.params.id
          },(res)=>{
              if(res.success){
                  this.setState({
                    products: res.data.products,
                    actionName: res.data.action.name,
                    startDate: res.data.action.date_start,
                    endDate: res.data.action.date_end,
                    status: res.data.action.status,
                    subcategories: res.data.action.subcategories,
                    storeId: res.data.action.store_id,
                    active: res.active
                })
              }
        });
    }

    setNewPrice=(event)=>{
        let newPrice = event.target.value;
        let productId = event.target.getAttribute('data-product-id');

        Service.get('/actions/setNewPrice',{
                newPrice: newPrice,
                discount: '',
                productId: productId,
                actionId: this.props.match.params.id
            },(res)=>{
                if(res.success){
                    this.getAction();
                }
        });
    }

    setNewDiscount=(event)=>{
        let newDiscount = event.target.value;
        let productId = event.target.getAttribute('data-product-id');

        Service.get('/actions/setNewPrice',{
            discount: newDiscount,
            newPrice: '',
            productId: productId,
            actionId: this.props.match.params.id
          },(res)=>{
                if(res.success){
                    this.getAction();
                }
        });
    }

    setActionStatus=()=>{
        if(this.state.status === '1'){
            Service.get('/actions/deactivateAction',{
                actionId: this.props.match.params.id
                },(res)=>{
                this.getAction();
            });
        }else{
            Service.get('/actions/activateAction',{
                actionId: this.props.match.params.id
                },(res)=>{
                this.getAction();
            });
        }
    }

    handleDateFrom=(date)=>{
        console.log(date);
        Service.get('/actions/setStart',{actionId: this.props.match.params.id, dateStart: date}, ()=>{
            this.getAction();
        })
        this.setState({startDate: date});

    }

    handleDateTo=(date)=>{
        console.log(date);
        Service.get('/actions/setEnd',{actionId: this.props.match.params.id, dateEnd: date}, ()=>{
            this.getAction();
        })
        this.setState({endDate: date});
        //this.setState({startDate: e});

    }

    handleAutoActivate = (event) => {
        let status = event.target.getAttribute('data-action-status')
        if(status == 0){
            Service.get('/actions/setStatus',{actionId: this.props.match.params.id, status: 2},()=>{
                this.getAction();
            })
        }
        if(status == 2){
            Service.get('/actions/setStatus',{actionId: this.props.match.params.id, status: 0},()=>{
                this.getAction();
            })
        }
    }

    handleReadCSV = (data) => {
        console.log(data);
        this.setState({importProducts: data.data}, ()=>{
            Service.get('/actions/addProducts', {actionId: this.props.match.params.id, products: this.state.importProducts}, (res)=>{
                console.log('response', res);
                if(res.deniedProducts){
                    this.setState({error: "Proizvodi " + res.deniedProducts.toString() + " nisu ubaceni! Proveriti SKU u prodavnici"});
                }else{
                    this.setState({error: null});
                }
                this.getAction();
            })
        });
        
    }
    handleImportOffer = () => {
        this.fileInput.current.click();
    }
    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }
    addProduct=(product)=>{
        Service.get('/actions/addProduct', {actionId: this.props.match.params.id, productId: product.id}, (res)=>{
            if(res.success){
                this.getAction();
            }
        })
    }

    toggleSubcategories=()=>{
        Service.get("/actions/toggleSubcategories", {actionId: this.props.match.params.id}, (res)=>{
            this.getAction();
        })
    }

    render() {
        return (
        <div>
            <h1>Akcija - {this.state.actionName} - {storeNames[this.state.storeId]}</h1>
            <div>Period trajanje akcije: {this.state.startDate} - {this.state.endDate}</div>
            {/* <div className='date' onClick={}>{this.state.startDate}</div> */}
            {/* <input value={this.state.startDate}/>
            <input value={this.state.endDate}/> */}
            <DateSelector ref={this.dateStart} label="Datum početka" onChange={this.handleDateFrom} date={this.state.startDate} />
            {/* <div className='date'>{this.state.endDate}</div> */}
            <DateSelector ref={this.dateTo} label="Datum kraja" onChange={this.handleDateTo} date={this.state.endDate} />
            {/* <input type="checkbox" data-action-status={this.state.status} onChange={this.handleAutoActivate} checked={this.state.status == '2'}/>Automatsko paljenje akcije<br /> */}
            <InfoBox message={this.state.active == 1 ? 'Akcija je aktivna' : "Akcija je neaktivna"} color={this.state.active == 1 ? "#009900" : "#990000"}/>
            <div>
                <input type="checkbox" checked={this.state.status} onClick={this.setActionStatus}/> Status
                <p className='hint' >Oznacava da li ce akcija biti aktivna na sajtu ako je datum u okvirima pocetka i kraja akcije.</p>
            </div>
            <div>
                <input type="checkbox" checked={this.state.subcategories} onClick={this.toggleSubcategories}/> Potkategorije
                <p className='hint' >Ako je ova opcija ukljucena, za ovu akciju ce biti generisane potkategorije podeljene po popustima na osnovu proizvoda dodatih u akciju.</p>
            </div>
            <section>
                <CSVReader
                onFileLoaded={this.handleReadCSV}
                inputRef={this.fileInput}
                style={{display: 'none'}}
                onError={this.handleOnError}
                />
                <h2>Unos iz CSV fajla</h2>
                <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
                <button onClick={this.handleImportOffer}>Odaberi CSV</button>
                {this.state.error &&
                    <InfoBox message={this.state.error} color="#990000"/>
                }
            </section>
            
            <h2>Pojedinačni unos</h2>
            <ProductsFilter onSelect={this.addProduct} placeholder="Odaberi proizvod..." />

            <p className='hint' >Odaberi proizvod iz baze. Nakon sto ubaciš proizvod mozeš da mu odrediš popust i ostale parametre vezane za akciju.</p>

            <div>
                <div className="admin-actions-table">
                    <h3>Proizvodi</h3>
                    <table className='cms-table'>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Ime</th>
                            <th>Stara cena</th>
                            <th>Nova cena</th>
                            <th>Procenat</th>
                            <th>Stiker</th>
                            <th>Besplatna isporuka</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.products.map(function(item, index){
                            return(
                                    <tr key={index}>
                                        <td>{item.product_id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.old_price}</td>
                                        <td><input type="text" id="newPrice" value={this.state.newPrice} placeholder={item.new_price} data-product-id={item.product_id} onBlur={this.setNewPrice} /></td>
                                        <td><input type="text" id="newDiscount" value={this.state.newDiscount} placeholder={item.new_discount} data-product-id={item.product_id} onBlur={this.setNewDiscount} /></td>
                                        <td><input type="checkbox" data-product-id={item.product_id} onClick={this.handleSticker} checked={parseInt(item.sticker)}></input></td>
                                        <td><input type="checkbox" data-product-id={item.product_id} onClick={this.handleFreeShiping} checked={parseInt(item.free_shipping)}></input></td>
                                    </tr>
                                )
                            },this)
                        }
                        </tbody>
                    </table>
                </div>
                <div className="admin-actions-categories">
                    <h3>Kategorije</h3>
                          <p className="tooltip">klikni na checkbox da dodas ili skines proizvod iz kategorije.</p>
                    <div>
                      {this.state.allCategories.map(function(item){
                            return(
                                
                                <div className='product-edit-categories-cont' >
                                <h4 className="arrow-open" onClick={this.expandTopCategory} >{item.name}</h4>
                                <div className="categories-cont">
                                {item.subcategories.map(function(subcategory){
                                  return(
                                     <p className='product-edit-subcategory'>
                                     <input type="checkbox" name="subcategory" data-category-id={subcategory.id} onClick={this.addActionCategory} checked={subcategory.checked}></input>
                                     {subcategory.name}
                                     </p>
                                        
                                  );
                                },this)
                                }
                                </div>
                                </div>
                                
                            );
                        },this)
                        }
                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default Action;
